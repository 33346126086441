import * as CT_ProcessStatus from "./codetable/CT_ProcessStatus";

/**
 * This method retrieves the sequence index of the first undone step
 * @param {*} listFetchObj The king list fetch object with chaindefinition, steadyflow, etc.
 * @returns sequence index or -1 (if there is no step idenfied as undone)
 */
export function identifySequenceIdOfFirstUndoneStep(listFetchObj) {
     let steps = listFetchObj.chain;
     for (let i = 0; i < steps.length; i++) {
       let step = steps[i];
       if (step.status !== CT_ProcessStatus.CODE.JS_PROCESS_DONE) {
         return step.sequenceindex;
       }
     } 
     return -1;
   }


/**
 * This method retrieves the id of the match process (which can be 'seqIndex:5100 StoredIQ-Import' or 'seqIndex:5000 WatsonNLP matching')
 * @param {*} listFetchObj The king list fetch object with chaindefinition, steadyflow, etc.
 * @returns processid of matching step of null if not found
 */
export function identifyIdOfMatchProcess(listFetchObj) {

  try {
    let steps = listFetchObj.chain;
    for (let i = 0; i < steps.length; i++) {
      let step = steps[i];
  
      //IF WatsnNLP or StoredIQ ...
      if ((step.chainEntry.sequenceIndex === 5000) || (step.chainEntry.sequenceIndex === 5100)) {
        // ... has been done
        if (step.status === CT_ProcessStatus.CODE.JS_PROCESS_DONE) {
          return step.processStatusList[0].processid;
        }
      }
    } 
  
  }
  catch(err) {
    return null;
  }
  return null;


}
