import React, { Component } from "react";

import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
import FadedNotAvailableIcon from "../../../FadedNotAvailableIcon";
import * as environmentService from "./../../../EnvironmentService";

class TenantCaseListUiSelectionBox extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {}

  render() {

    const { row, currentTenant, selectedCase,  selectedTenant} = this.props;

    if (selectedCase === row.caseid && currentTenant === selectedTenant) {
      environmentService.log('', "selected");
      return (
        <BiCheckboxChecked
          size="22"
          onClick={this.handleDeSelectCase.bind(this,row)}
        />
      );
    } else {
      environmentService.log('', "not selected");
      return (
        <BiCheckbox
          size="22"
          onClick={this.handleSelectCase.bind(this, row)}
        />
      );
    }
  }

  //*******************************/
  //* Icon Touch Handler
  //*******************************/
  handleSelectCase(row) {
    environmentService.log('', "TenantCaseListUiSelectionBox.handleSelectCase")
    environmentService.log('', row);
      this.props.functionCaseSelect(row);
  }

  handleDeSelectCase(row) {
    environmentService.log('', "TenantCaseListUiSelectionBox.handleDeSelectCase")
    environmentService.log('', "handleSelectCase")
    this.props.functionCaseDeselect(row);
   }
}

export default TenantCaseListUiSelectionBox;
