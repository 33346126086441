import React, { Component } from "react";
import { InlineLoading} from "carbon-components-react";
import * as environmentService from "./../../EnvironmentService";

import { ContentSwitcher, Switch} from "carbon-components-react";

class AHrefWithPleaseWait extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        touchedUrlDate: null
      };
  }


  async componentDidMount() {
    const intervalId = setInterval(() => {
      this.doReset();
    }, 1000);  
  }

  async doReset() {
    const {touchedUrlDate} = this.state;
    if (touchedUrlDate === null) {
      return;
    }
    let t1 = touchedUrlDate;
    let t2 = new Date().getTime();
    let tdif = t2 - t1;
    if (tdif > 8000) {
      this.setState({ touchedUrlDate: null} );
    }
  }


  render() {

    const {logLinkReport, logFileNameReport, downloadLabel} = this.props;
    const {touchedUrlDate} = this.state;

    let downloadLabelToDisplay = '';
    if ((downloadLabel === null) || (downloadLabel === undefined)) {
      downloadLabelToDisplay = 'Report';
    } else {
      downloadLabelToDisplay = downloadLabel
    }

    if (touchedUrlDate ===  null) {

      return (
        <>
          <a
            href={logLinkReport}
            download ={logFileNameReport}
            rel="noopener"
            aria-label={downloadLabelToDisplay}
            onClick={this.handleClick}
            >
            {downloadLabelToDisplay}
          </a>
        </>
      );
  
    } else {

      return (
        <>
          <InlineLoading description="One moment please ..."/>
        </>
      );
  

    }
  }


  handleClick = (e) => {
    environmentService.log('', 'The link was clicked.');
    this.setState({ touchedUrlDate: new Date().getTime()});
  };

}

export default AHrefWithPleaseWait;
