import * as CT_ADD_DisplayRightType from  "../../commonxV3_001/codetable/CT_ADD_DisplayRightType";

/**
 * Work on Case UI holds a list of chainEntries (which can contain a list of preqsys_process-Entries)
 * This method identifies (based on sequence index) the index of a chainEntry within the list 
 * @param {*} chainEntry to search for
 * @param {*} chainList fo find row in
 * @returns Index or -1 if not found
 */
export function getIndexOfRowInList(chainEntry, chainList) {

  for (let i = 0; i < chainList.length; i++) {
    if (chainList[i].sequenceindex === chainEntry.sequenceindex) {
      return i;      
    }
  }
  return -1;
}


/**
 * Work on Case UI holds a list of chainEntries (which can contain a list of preqsys_process-Entries)
 * This method identifies the previous chainEntry within the list (previous related to chainEntry) 
 * @param {*} chainEntry 
 * @param {*} chainList 
 * @returns previous chainEntry (or null, if there is no previous)
 */
export function getPreviousChainEntry(chainEntry, chainList) {

  let index = getIndexOfRowInList(chainEntry, chainList);
  index = index - 1;
  let result = null;
  if (index >= 0) {
    result = chainList[index];
  }
  return result;
}

/**
 * This method checks, if an entry is in Parameter list displayed on the right side
 * @param {*} selectedChainEntry 
 * @param {*} displayRightList 
 * @returns 
 */
export function isEntryInDisplayRightParameterList(selectedEntry, displayRightType, displayRightList) {

  let indRighDisplayShowsParameters = false;
  if (CT_ADD_DisplayRightType.CODE.MANUALLY_PARAMETER_SEQUENCE === displayRightType) {
    indRighDisplayShowsParameters = true;
  }
  if (CT_ADD_DisplayRightType.CODE.MANUALLY_PARAMETER_ONESTEP === displayRightType) {
    indRighDisplayShowsParameters = true;
  }
  if (indRighDisplayShowsParameters === false) {
    return false;
  } 

  let selectedChainEntry = selectedEntry.chainEntry;
  let entryAlreadyAvailable = false;
  for (let i = 0; i < displayRightList.length; i++) {
    let index1 = displayRightList[i].chainEntry.sequenceIndex;
    let index2 = selectedChainEntry.sequenceIndex; 
    if (index1 === index2) {
      entryAlreadyAvailable = true;
    }
  }
  return entryAlreadyAvailable;
}

