import React, { Component } from "react";
import Dropzone from 'react-dropzone'
import { Button, InlineLoading } from "carbon-components-react";
import * as restFileUploadClient from "./backend/RestFileUploadClient";
import * as serverResponsePojoIdentifier from "./ServerResponsePojoIdentifier";
import * as environmentService from "./../EnvironmentService";


import { ContentSwitcher, Switch} from "carbon-components-react";

class GenericFileUploader extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        acceptedFiles: [],
        uploadDetails: null,
        error:null,
        fileUploading:false
      };
  }

  render() {

    const {fileUploading, acceptedFiles, uploadDetails, error} = this.state;

    let {boxtext, droptext} = this.props;
    let shwoUploadButton = (acceptedFiles.length === 1) && (fileUploading === false)
    return (
      <div className="preQ_SME_fileDropZone">
        <Dropzone onDrop={this.onDrop}>
          {({getRootProps, getInputProps, isDragActive}) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {isDragActive ? droptext : boxtext}
            </div>
          )}
        </Dropzone>
        <br />
        <ul>
            {acceptedFiles.length > 0 && acceptedFiles.map(acceptedFile => (
              <li className="list-group-item list-group-item-success">
              {acceptedFile.name}
              </li>
            ))}
        </ul>                            
        <br/>
        <br/>
        {
          shwoUploadButton === true
          ? <Button kind="tertiary" onClick={this.fileSubmit.bind(this, this.state.acceptedFiles)} size='sm'>Upload</Button>
          : <div></div>
        }
        {
          fileUploading === true
          ? <InlineLoading description="File uploading ..." /> 
          : <></>
        }
        <br/>
        <br/>
        {
          uploadDetails !== null
          ? <div>OK</div>
          : <div></div>
        }

      </div>
    );
  }

  onDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 1) {
      environmentService.log('', acceptedFiles);

      this.setState({
        acceptedFiles:acceptedFiles, uploadDetails:null, error:null
      });
  
    }

  }

  async fileSubmit(acceptedFiles) {
    this.setState({
      fileUploading:true
    });
    let data_FromServer = await restFileUploadClient.submitOneFile(acceptedFiles);
    this.setState({
      fileUploading:false
    });
    let indError = serverResponsePojoIdentifier.isPojoErrorResult(data_FromServer);
    if (indError === true) {
      this.setState({
        uploadDetails:null, error:data_FromServer
      });
    } else {
      let uploadID = data_FromServer.uploadID;
      let data_FromServer2 = await this.props.checkInFunction(uploadID);
      let indError2 = serverResponsePojoIdentifier.isPojoErrorResult(data_FromServer2);
      if (indError2 === true) {
        this.setState({
          uploadDetails:null, error:data_FromServer2
        });
      } else {
        this.setState({
          acceptedFiles: [],
          uploadDetails: null,
          error:null,
        });
        await this.props.finalCheckInSuccessFunction();
      }
    }

  }
}

export default GenericFileUploader;
