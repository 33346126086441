import React, { Component } from "react";

import { ContentSwitcher, Switch} from "carbon-components-react";
import * as PreQV3Constants from "./../../../../commonxV3_001/PreQV3Constants";
import ReactJson from "react-json-view-with-toggle";


class DevelopmentDisplayWorkOnCaseFileCommanderPane extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  render() {

    const {uiParams, devTagLabel, state, files} = this.props;

    let autoUpdateActive = this.getAutoUpdateValue();
    if (autoUpdateActive === true) {
        return (
          <div></div>
        );
    }

    let indShowEmpty = false;
    if (uiParams    === undefined) { indShowEmpty = true; }
    if (uiParams    === null)      { indShowEmpty = true; }
    if (devTagLabel === undefined) { indShowEmpty = true; }
    if (devTagLabel === null)      { indShowEmpty = true; }

    if (indShowEmpty) {
      return (
        <div>&nbsp;</div>
      );
    }

    let visibleDevTagLable = devTagLabel + " INSIGHT";
    if (uiParams.showDevTags  === true) {
      return (
        <div>
          <div className="preQ_SME_label">&nbsp;</div>
          <div className="preQ_SME_developer_tag">{visibleDevTagLable}</div>

          <table width="100%">
              <tr>
                <td with="100%">
                  <div className="preQ_SME_label">state</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="preQ_SME_label">
                    <ReactJson src={state} collapsed={true} enableClipboard={false}/><br/>
                  </div>
                </td>
              </tr>
              <tr>
                <td with="100%">
                  <div className="preQ_SME_label">files</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="preQ_SME_label">
                    <ReactJson src={files} collapsed={true} enableClipboard={false}/><br/>
                  </div>
                </td>
              </tr>
            </table>
        </div>
      );
    }

    return (
      <div>
        <div>&nbsp;</div>
      </div>
    );
  }

  getAutoUpdateValue() {
    
    let value = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_CASE_AUTOUPDATE);
    if (value === "true") {
      return true;
    } else {
      return false;
    }
  }

}

export default DevelopmentDisplayWorkOnCaseFileCommanderPane;
