import React, { Component } from "react";

import * as PreQV3Constants from "./../../commonxV3_001/PreQV3Constants";

import i18next from "i18next";

class WamaDisplayFileUploadID extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  render() {
    let processingID = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_CURRENT_WAMA_FILE_UPLOAD_ID);
    let indProcessIdAvailable = (processingID !== null)
    return (
      <div className="preQ_Display_File_Upload_ID">
          { 
          indProcessIdAvailable
          ? <div><br/>{i18next.t("WamaDisplayFileUploadID.processingID")} {processingID}<br/><br/></div>
          : <div><br/>&nbsp;<br/><br/></div>
          }
          
      </div>
    );
  }
}

export default WamaDisplayFileUploadID;
