import React, { Component } from "react";

import { ContentSwitcher, Switch} from "carbon-components-react";
import Parameter6200Export from "./Parameter6200Export";
import Parameter6200Import from "./Parameter6200Import";

class Parameter6200 extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  render() {

    let {sequenceindex, entry, listFetchObj, getDisplayListEntry, updateUserParameter}  = this.props;

    return (
      <div>
        <Parameter6200Export
          sequenceindex = {sequenceindex}
          entry = {entry}
          listFetchObj = {listFetchObj}          
        />
        <hr/>
        <Parameter6200Import 
          sequenceindex = {sequenceindex}
          entry = {entry}
          listFetchObj = {listFetchObj}          
          getDisplayListEntry = {getDisplayListEntry}
          updateUserParameter = {updateUserParameter}
        />
        <hr/>
      </div>
    );
  }
}

export default Parameter6200;
