import React, { Component } from "react";

import { ContentSwitcher, Switch} from "carbon-components-react";

class DevelopmentTag extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  render() {
    const {uiParams, devTagLabel} = this.props;

    let indShowEmpty = false;
    if (uiParams    === undefined) { indShowEmpty = true; }
    if (uiParams    === null)      { indShowEmpty = true; }
    if (devTagLabel === undefined) { indShowEmpty = true; }
    if (devTagLabel === null)      { indShowEmpty = true; }

    if (indShowEmpty) {
      return (
        <div></div>
      );
    }

    if (uiParams.showDevTags  === true) {
      return (
        <div>
          <div className="preQ_SME_label">&nbsp;</div>
          <div className="preQ_SME_developer_tag">{devTagLabel}</div>
      </div>
      );
    } else {
      return (
        <div></div>
      );
    }

  }
}

export default DevelopmentTag;
