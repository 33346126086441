import React, { Component } from "react";

import {
  Loading, Button, TextInput
} from 'carbon-components-react';
import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
import * as PreQV3Constants from "./../../../../commonxV3_001/PreQV3Constants";
import * as I18nDateConverter from "./../../../../commonxV3_001/i18n/I18nDateConverter";
import AsyncProcessDialogs from "./../../../../commonxV3_001/app/AsyncProcessDialogs";
import * as CT_ADD_AsyncAction from "../../../../commonxV3_001/codetable/CT_ADD_AsyncAction";
import * as RestClient from "./../../../backend/RestClient";
import { v4 as uuidv4 } from 'uuid';


class WorkOnCaseDeployToFinalFolderSubmission extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        selectText : false,
        selectCase : false,
        folderName : "",
        folderNameCase : "",
        lastEnteredFolderName : "",


        /*      
        * ------------------------------------------------------------
        *  Code supporting List FromPreQ:
        * ------------------------------------------------------------
        */ 
        listFromPreQ: [],
        error: null,
        isLoading: true,

        /*      
        * ------------------------------------------------------------
        *  Code supporting asyncProcessDialogsModal =====>
        * ------------------------------------------------------------
        */ 
        asyncProcessDialogsModal: {
          active: false,
          tenant: null,
          caseid: null,
          code: null, //see Codetable CT_ADD_AsyncAction
          name : null, //clear text for asyncAction (retrieved from Codetable CT_ADD_AsyncAction) 
          parameter : null,
        }
        /*      
        * ------------------------------------------------------------
        *  <===== Code supporting asyncProcessDialogsModal
        * ------------------------------------------------------------
        */ 
        
      };
  }

  async componentDidMount() {
    this.updateList();
  }
  
  async updateList() {

    let tenant = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_ORG_UNIT);
    let caseid = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_CASE);

    let listFromPreQ = await RestClient.getFileListTenantCaseFromPreQ(tenant, caseid);


    this.setState(
      {
      listFromPreQ: listFromPreQ,
      error: null,
      isLoading: false,
      }
    ); 
  }


  render() {

    const {selectText, selectCase, folderName, folderNameCase, asyncProcessDialogsModal, listFromPreQ, error, isLoading} = this.state;

    /*      
    * ------------------------------------------------------------
    *  Code supporting asyncProcessDialogsModal =====>
    * ------------------------------------------------------------
    */ 
      if (asyncProcessDialogsModal.active === true) {
        return (
          <div>
            <AsyncProcessDialogs
              asyncProcessDialogsModal = {this.state.asyncProcessDialogsModal}
              functionCloseAsyncModal = {this.closeAsyncModal.bind(this)}
            />
          </div>
        );
      }

    /*      
    * ------------------------------------------------------------
    *  <===== Code supporting asyncProcessDialogsModal
    * ------------------------------------------------------------
    */ 


    if (isLoading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }




    let showActionButton = ((selectText === true) || (selectCase === true)); 
    if (selectText === true) {
      if (folderName === "") {
        showActionButton = false;
      }
    }

    //***************************************************************************
    // Ui Properties Definition starts here
    //*************************************************************************** 

    const ui_Props_TextInput_FolderName = {
      className: 'some-class',
      id: 'FolderName',
      labelText: '',
      placeholder: 'Please Enter (empty is not allowed)',
   };

   let orgUnit = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_ORG_UNIT);
   let caseid = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_CASE);
   let filedownloadId = uuidv4()

   let logLink = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
   + "/RESTServiceFileDownLoad/Tenant/preq/sbv3/service/tenant/frompreq/download/" + orgUnit + "/" + caseid + "/" + filedownloadId
   let downloadFileName = "";
   if (listFromPreQ.submittedFiles.length == 1) {
    downloadFileName = listFromPreQ.submittedFiles[0].key
   }
   if (listFromPreQ.submittedFiles.length > 1) {
    downloadFileName = orgUnit + '_' + caseid + '.zip'
   }



    return (
      <div>
          <br />
          <table width="100%" id ="file_list_table">
            {listFromPreQ.submittedFiles.map((file) => (
            <tr>
              <td>
                <div className="preQ_SME_file_list_text_normal">
                  {file.key}
                </div>
                </td>              
            </tr>
            ))}
          </table>
          <br />
          { (listFromPreQ.submittedFiles.length > 0)
            ? <div>
                Download&nbsp;&nbsp;
                <a
                  href={logLink}
                  download ={downloadFileName}
                  target="_blank" 
                  rel="noopener"
                  aria-label='Download'
                  >
                  {downloadFileName}
                  </a>
              </div>
            : <div></div>
          }
                    
          <br />
          <br />
          <br />
          <br />
          <table width="100%">
              <tr>
                <td style={{width:"8%", verticalAlign:"top", paddingLeft:"5px", paddingTop:"4px", borderBottom:"2px"}}  onClick={this.handleCheckBoxToggled.bind(this, 1)}>
                  <div>
                  Enter Folder Name
                  </div>
                </td>
                <td style={{width:"4%", verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px"}}  onClick={this.handleCheckBoxToggled.bind(this, 1)}>
                  <div>
                  {selectText === true ? ( <BiCheckboxChecked size="18"/> ) : ( <BiCheckbox size="18"/> )}
                  </div>
                </td>
                <td style={{width:"8%", verticalAlign:"top", paddingLeft:"5px", paddingTop:"4px", borderBottom:"2px"}}  onClick={this.handleCheckBoxToggled.bind(this, 2)}>
                  <div>
                  OrgUnit/Case-Folder
                  </div>
                </td>
                <td style={{width:"8%", verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px"}}  onClick={this.handleCheckBoxToggled.bind(this, 2)}>
                  <div>
                  {selectCase === true ? ( <BiCheckboxChecked size="18"/> ) : ( <BiCheckbox size="18"/> )}
                  </div>
                </td>
                <td>
                  <div>&nbsp;</div>
                </td>
              </tr>
              <tr>
                <td colSpan={5}>
                  &nbsp;                  
                </td>
              </tr>
              <tr>
                <td colSpan={4} style={{width:"2%", verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px"}}>
                  {selectText === true 
                    ? ( 
                      <div>
                        <div className="preQ_SME_label">
                        Please enter Folder Name (There MUST be a string entered using thes characters only 'A-Z, 'a-z', '0..9')
                        </div>
                        <TextInput
                          {...ui_Props_TextInput_FolderName} 
                          value={this.state.folderName}
                          onChange={this.handleChange.bind(this)} 
                        />
                      </div>
                      ) 
                    : ( 
                      <div></div>
                      )
                    }

                  {selectCase === true 
                    ? ( 
                      <div>
                        <div className="preQ_SME_label">
                        Folder named
                        </div>
                        <TextInput
                          {...ui_Props_TextInput_FolderName} 
                          readOnly = {true}
                          value={this.state.folderNameCase}
                        />
                      </div>
                      ) 
                    : (
                      <div></div>
                      )
                    }

                </td>
                <td style={{verticalAlign:"top", paddingLeft:"5px", paddingTop:"16px", borderBottom:"2px"}}>
                {(showActionButton === false) 
                ? (
                    <div></div>
                  ) 
                : (
                  <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button kind="tertiary"  size='sm' onClick={this.move_fromPreQ_fromIbm.bind(this)}> copy to /fromibm</Button>
                  </div>
                )
                } 
                </td>
              </tr>
              <tr>
                <td>
                <br />
                </td>
              </tr>

          </table>


      </div>
    );
  }

  //*******************************/
  //* Icon Touch Handler
  //*******************************/
  handleCheckBoxToggled(id) {
    let currentFolderName = this.state.lastEnteredFolderName;
    if (currentFolderName === "") {
      currentFolderName = this.createFolderTimeStamp(); 
    }
    if (id === 1) {
      this.setState({
        selectText : true,
        selectCase : false,
        folderName : currentFolderName,
        folderNameCase : "",
      });
    }
    if (id === 2) {

      let currentOrgUnit  = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_ORG_UNIT)
      let currentCase     = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_CASE)
  
      this.setState({
        selectText : false,
        selectCase : true,
        folderName : "",
        folderNameCase : currentOrgUnit + "_" + currentCase,
      });
    }
  }

  //*******************************/
  //* Text Field change Handler
  //*******************************/
  handleChange(event) {
    let fieldName = event.target.id;
    let fieldVal = event.target.value;
    if (fieldName === "FolderName") {
      this.setState({
        folderName : fieldVal,
        lastEnteredFolderName : fieldVal,
      });
    }
  }


  createFolderTimeStamp() {
    let date = new Date();
      
    let strDD = I18nDateConverter.getStringDay(date.getDate());
    let strMM = I18nDateConverter.getStringMonth(date.getMonth());
    let strYYYY = date.getFullYear().toString();;
    let strHH = I18nDateConverter.getStringHour(date.getHours());
    let strMm = I18nDateConverter.getStringMinute(date.getMinutes());
    let strSec= I18nDateConverter.getStringSecond(date.getSeconds());
    let result =  "D" + strYYYY+ strMM + strDD + "T" + strHH + strMm + strSec;
    return result;
  
  
  }



/*      
 * ------------------------------------------------------------
 *  Code supporting asyncProcessDialogsModal =====>
 * ------------------------------------------------------------
 */ 
async openAsyncModal(asyncAction, tenant, caseid, asyncParameter) {
  //  alert(asyncAction + " : [" + tenant + "] / [" + caseid + "]");
  
    let asyncActionName = CT_ADD_AsyncAction.getTextForCode(asyncAction);
    this.setState({ asyncProcessDialogsModal: {...this.state.asyncProcessDialogsModal, 
      active: true,
      tenant: tenant,
      caseid: caseid,
      code: asyncAction, //see Codetable CT_ADD_AsyncAction
      name : asyncActionName,
      parameter : asyncParameter,
      } 
    }); 
  
  }
  
  async closeAsyncModal() {
  
    //Specifics for this Component.PRE: a tenant delete must be finalized with a sync call to clean up preqsys_process
    // ... none

    //Standard Code supporting asyncProcessDialogsModal =====>
    this.setState({ asyncProcessDialogsModal: {...this.state.asyncProcessDialogsModal, 
      active: false,
      tenant: null,
      caseid: null,
      code: null,
      name : null,
      parameter : null,
      } 
    });
    //<===== Standard Code supporting asyncProcessDialogsModal 
  
  
    //Specifics for this Component.POST: Update List based on performed changes
    this.updateList()

  
  }
  
  /*      
   * ------------------------------------------------------------
   *  <===== Code supporting asyncProcessDialogsModal
   * ------------------------------------------------------------
   */ 


  async move_fromPreQ_fromIbm() {

    const {selectText, selectCase, folderName, folderNameCase} = this.state;  
    let sParamValue = "";
    if (selectText === true) {
      sParamValue = folderName;
    }
    if (selectCase === true) {
      sParamValue = "$case";
    }
    let sParam = '{"folderstructure": "' + sParamValue + '"}';


    let tenant = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_ORG_UNIT);
    let caseid = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_CASE);
    let asyncAction = CT_ADD_AsyncAction.CODE.JS_MOVE_FILES_SRC_FROMPREQ_DEST_FROMIBM;
    this.openAsyncModal(asyncAction, tenant, caseid, sParam);
  }

}

export default WorkOnCaseDeployToFinalFolderSubmission;
