import React, { Component } from "react";

import { ContentSwitcher, Switch} from "carbon-components-react";

class CommonXProcessFindingsListUi extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  render() {
    let processFindings = this.props.processFindings;
    let autoUpdateTimeStamp = this.props.autoUpdateTimeStamp;
    if (autoUpdateTimeStamp === null) {
      autoUpdateTimeStamp = "";
    }
    if (autoUpdateTimeStamp === undefined) {
      autoUpdateTimeStamp = "";
    }
    if (autoUpdateTimeStamp !== "") {
      autoUpdateTimeStamp = "Last Update: " + autoUpdateTimeStamp;
    }
//    processFindings[0].details = '--- Start ---';
    return (
      <div>
        <table width="100%">
          <tr>
            <td colspan="2" className="backGroundColor_process_PROCESS_ITEM"><h6>Findings</h6></td>
            <td colspan="1" className="backGroundColor_process_PROCESS_ITEM">{autoUpdateTimeStamp}<br/>&nbsp;</td>
          </tr>


          {processFindings.map((processFinding) => (
            <tr>
              <td width="20%">{processFinding.created_txt}</td>
              <td width="10%">{processFinding.type}</td>
              <td width="70%">{processFinding.details}</td>
            </tr>
          ))}

        </table>         
      </div>
    );
  }
}

export default CommonXProcessFindingsListUi;
