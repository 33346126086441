import React, { Component } from "react";
import { FormItem, FileUploaderDropContainer, Checkbox,  Button} from "@carbon/react";
import { ScanAlt, Close } from '@carbon/react/icons';

import * as PreQV3Constants from "./../../../modules/commonxV3_001/PreQV3Constants"
import WamaDisplayErrorWrapper from './WamaDisplayErrorWrapper';
import * as CT_ErrorCodes from "./../../commonxV3_001/codetable/CT_ErrorCodes";
import * as ErrorCodeBuilder from "./../../commonxV3_001/backend/ErrorCodeBuilder";
import * as environmentService from "./../../EnvironmentService";

import i18next from "i18next";


class WamaInput extends Component {
  constructor(props) {
    super(props);
    this.resetState();
  }


  resetState() {

           // Create the state
           this.state = {
            acceptedFiles: [],
            displayConfirmed: false, 
          };
    
  }
  


  render() {

    let jsonUiParams = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_UI_PARAMETERS);
    let uiParams = JSON.parse(jsonUiParams);

    //wamaInputAllowedExtensions:".txt,.csv,.html"
 
    const {acceptedFiles, displayConfirmed} = this.state;

    let startButtonDisabled = (!displayConfirmed || acceptedFiles.length === 0 || this.props.error !== null);

    let pseudoActive = (uiParams.sequenceIncludesPPPseudoGen.toString().toUpperCase() == "TRUE")

    return (
    <div className="grid-setup margin-t-96">
      <div className="bg-inverse width-608 margin-b-40">
      <WamaDisplayErrorWrapper
        errorMessageVisible = {this.props.errorMessageVisible}
        error = {this.props.error}
        setError = {this.props.setError}
        />
        <div className="padding-16">



          <h3 className="margin-b-16">{i18next.t("app.title-short")}</h3>
          <p className="margin-b-16">
            {i18next.t("WamaInput.text.subheading")}
            {pseudoActive?" " + i18next.t("WamaInput.text.subheadingPseudoPart"):""}
          </p>
          <div>
            <FormItem>
              <p className="cds--label-description">{i18next.t("WamaInput.fileupload.prefix")} {uiParams.wamaMaxUploadableFileSizeInKb} {i18next.t("WamaInput.fileupload.postfix")}</p>
              <FileUploaderDropContainer
                innerRef={{
                  current: '[Circular]'
                }}
                labelText={i18next.t("WamaInput.fileupload.upload")}
                justify-content="center"
                multiple
                name=""
                onAddFiles={(e) => {
                  let type = e.type;
                  let files = null;
                  if (type === 'drop') {
                    files = e.dataTransfer.files;
                  } else {
                    files = e.target.files;
                  }
                  environmentService.log('', files);
                  this.onDrop(files);
				        }}
                onChange={function noRefCheck(){}}
                role=""
              />
              <div className="cds--file-container cds--file-container--drop"/>
            </FormItem>
            <br/>

            <td width="80%">
              {acceptedFiles.length > 0 
              ? <div>
                  <table className="preQ_file_upload_list_table">
                    <tr>
                      <td>&nbsp;{acceptedFiles[0].name}&nbsp;</td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td onClick={this.removeFiles.bind(this)} ><Close size="12"/>&nbsp;</td>
                    </tr>
                  </table>
                </div>
              : <div>
                  &nbsp;<br/>
                </div>
              }
            </td>

            <br/>
            <br/>
            
             <Checkbox onChange={()=>{
              environmentService.log('', '');
              this.toggleDisplayConfirmed();
              }} 
              labelText={i18next.t("WamaInput.checkbox.text", {lng: "de"})}
            	id="checkbox-label-1"
            	/>
          </div>
        </div>


        <div className="cds--modal-footer cds--btn-set">
              <Button 
                renderIcon={ScanAlt} 
                iconDescription="Start anonymizing"
                disabled={startButtonDisabled}
                onClick={this.fileSubmit.bind(this, this.state.acceptedFiles)}
              >
                {i18next.t("WamaInput.button.text")}
              </Button>
            </div>

      </div>
    </div>

    );
  }

  async toggleDisplayConfirmed() {
    const {displayConfirmed} = this.state;
    let newValue = true;
    if (displayConfirmed) {
      newValue = false;
    }
    this.setState({displayConfirmed : newValue});
  }


  async fileSubmit(acceptedFiles) {
    sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_LOCALE_ACTIVE, "NO");
    this.props.doStartProgress(acceptedFiles);
  }

  async removeFiles() {
    this.setState({
      acceptedFiles: [],
    });
  }
  

  onDrop = (acceptedFiles) => {
      
    let jsonUiParams = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_UI_PARAMETERS);
    let uiParams = JSON.parse(jsonUiParams);
    let allowedExtensions = uiParams.wamaInputAllowedExtensionList;
      for (let index = 0; index < acceptedFiles.length; index++) {
        const fileCandidate = acceptedFiles[index];

        //check, if an allowed file-extension is detected ...
        let indAllowedFileType = true; //changed to 'true' to allow all file types
        for (let j= 0;j < allowedExtensions.length;j++) {
          const allowedExtension = allowedExtensions[j];
          let s1 = fileCandidate.name.toLowerCase()
          let s2 = allowedExtension.toLowerCase();
          if (s1.endsWith(s2)) {
            indAllowedFileType = true;
          }
        }

        //... if not: raise an error
        if (indAllowedFileType === false) {
          let errorCode = CT_ErrorCodes.CODE.JS_WAMA_UNALLOWED_FILETYPE;
          let errorText = CT_ErrorCodes.getTextForCode(errorCode);
          let errorTextPrefix = i18next.t("WamaInput.error.prefix") +  fileCandidate.name ;
          let errorTextPostfix = i18next.t("WamaInput.error.extension") + uiParams.wamaInputAllowedExtensions;
          //it is possible to adjus errorText based on UI demands here
          let error = ErrorCodeBuilder.displayableErrorObject(errorCode, errorTextPrefix + errorTextPostfix);
          this.props.setError(error);
          return;
        }


        //check, if an unallowed file-size is detected ...
        let indAllowedFileSize = true;
        let fileCandidateSize = fileCandidate.size;
        let allowedSize = uiParams.wamaMaxUploadableFileSize;
        if (fileCandidateSize > allowedSize) {
          indAllowedFileSize = false;
        }

        //... if not: raise an error
        if (indAllowedFileSize === false) {
          let errorCode = CT_ErrorCodes.CODE.JS_WAMA_UNALLOWED_FILESIZE;
          let errorText = CT_ErrorCodes.getTextForCode(errorCode);
          let errorTextPrefix = i18next.t("WamaInput.error.prefix") +  fileCandidate.name;
          let errorTextPostfix = i18next.t("WamaInput.error.filesize") + uiParams.wamaMaxUploadableFileSizeInKb.toString() + " kb";
          //it is possible to adjus errorText based on UI demands here
          let error = ErrorCodeBuilder.displayableErrorObject(errorCode, errorTextPrefix + errorTextPostfix);
          this.props.setError(error);
          return;
        }
      }

      this.setState({
        acceptedFiles:acceptedFiles
      });
      this.props.setError(null);
  

  }

}

export default WamaInput;
