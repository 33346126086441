import * as CT_Language from "./../codetable/CT_Language";

export function getFileTimeStamp(long) {
  let date = new Date(long);
  let uiLanguage = CT_Language.CODE.JS_EN; //will be traken from session.storage in a later version
  if (uiLanguage === CT_Language.CODE.JS_EN) {
    
    let strDD = getStringDay(date.getDate())
    let strMM = getStringMonth(date.getMonth())
    let strYYYY = date.getFullYear().toString();
    let strHH = getStringHour(date.getHours())
    let strMm = getStringMinute(date.getMinutes())
    let result =  strDD + "." + strMM + "." + strYYYY + " " + strHH+":"+strMm;
    return result;

  }
}

export function getStringMonth(nMM) {
  nMM = nMM + 1;
  let str = nMM.toString();
  if (nMM < 10) {
    str = "0" + str;
  }
  return str;
}

export function getStringDay(nDD) {
  let str = nDD.toString();
  if (nDD < 10) {
    str = "0" + str;
  }
  return str;
}

export function getStringHour(nHour) {
  let str = nHour.toString();
  if (nHour < 10) {
    str = "0" + str;
  }
  return str;
}

export function getStringMinute(nMinute) {
  let str = nMinute.toString();
  if (nMinute < 10) {
    str = "0" + str;
  }
  return str;
}


export function getStringSecond(nSec) {
  let str = nSec.toString();
  if (nSec < 10) {
    str = "0" + str;
  }
  return str;
}
