import * as PreQV3Constants from "./../PreQV3Constants";

export const CODE = {
  UNDEFINED: "",
  JS_RAISING: "RAISING",
  JS_TO_BE_PROCESSED: "REGISTERED",
  JS_IN_PROCESS: "RUNNING",
  JS_PROCESS_DONE: "DONE",
  JS_PROCESS_CORRUPTED: "CORRUPTED",
  JS_NOTHING_HAPPENED: "NOTHING_HAPPENED",
}


export function getTextForCode(code) {

  let i18nSessionVar = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED);
  if ((i18nSessionVar === null) || (i18nSessionVar === undefined)) {
    i18nSessionVar = "en";
  }

  if (i18nSessionVar === "de") {

    switch (code) {
      case "RAISING": return "wird gerade angelegt";
      case "REGISTERED": return "zur Verarbeitung durch Prozess anstehend";
      case "RUNNING": return "Verarbeitung durch Prozess l�uft";
      case "DONE": return "Verarbeitung durch Prozess abgeschlossen";
      case "CORRUPTED": return "Verarbeitung durch Prozess mit Fehler abgeschlossen";
      case "NOTHING_HAPPENED": return "soweit noch nichts passiert";
      default:
        return code;
    }
  }

  switch (code) {
    case "RAISING": return "currently raising";
    case "REGISTERED": return "registered for processing";
    case "RUNNING": return "processing is running";
    case "DONE": return "processing is done";
    case "CORRUPTED": return "processing failed (corrupted)";
    case "NOTHING_HAPPENED": return "nothing happened here so far";
  
    default:
      return "";
  }
}  
  
export function getHintForCode(code) {

  let i18nSessionVar = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED);
  if ((i18nSessionVar === null) || (i18nSessionVar === undefined)) {
    i18nSessionVar = "en";
  }

  if (i18nSessionVar === "de") {

    switch (code) {
      case "RAISING": return "";
      case "REGISTERED": return "";
      case "RUNNING": return "";
      case "DONE": return "";
      case "CORRUPTED": return "";
      case "NOTHING_HAPPENED": return "";
      default:
        return code;
    }
  }

  switch (code) {
    case "RAISING": return "";
    case "REGISTERED": return "";
    case "RUNNING": return "";
    case "DONE": return "";
    case "CORRUPTED": return "";
    case "NOTHING_HAPPENED": return "";
  
    default:
      return "";
  }
  
}

