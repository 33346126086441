import React, { Component } from "react";

import { ContentSwitcher, Switch} from "carbon-components-react";
import * as environmentService from "./../../modules/EnvironmentService";
import * as PreQV3Constants from "./../../modules/commonxV3_001/PreQV3Constants";

class TransactionKeyHandler extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        //startDate : new Date()
      };
  }

  async componentDidMount() {
    const intervalId = setInterval(() => {
      this.doTransactionKey();
    }, 5000);  
  }

  async doTransactionKey() {

    environmentService.log('','doTransactionKey()');

    let sUser = PreQV3Constants.getLoggedInUser();
    let sTransactionKey = PreQV3Constants.getTransactionKey();
    let dTransactionKeyTimeStamp = PreQV3Constants.getTransactionKeyTimeStamp();
    let period = this.props.uiParams.transactionKeyRefreshPeriodInMinutes;

    //do nothing if there is nothing to do
    if (sUser === '') {
      return;
    }
    if (sTransactionKey === '') {
      return;
    }
    if (dTransactionKeyTimeStamp === null) {
      return;
    } 

    let dCurrentdate = new Date();
    let cTime = dCurrentdate.getTime();
    let rTime = Date.parse(dTransactionKeyTimeStamp);
    let difference = cTime - rTime;
    let maxDifference = this.props.uiParams.transactionKeyRefreshPeriodInMinutes * 60 * 1000;
    if (difference > maxDifference) {

      let responseData = await environmentService.updateTransactionKey(sUser, sTransactionKey);
      environmentService.log('',responseData);
      if (responseData === null) {
        return;
      }
      const obj = JSON.parse(responseData);
      PreQV3Constants.setTransactionKey(obj.transactionKey);
      environmentService.log('',obj);
    }

  }

  render() {
    return (
      <div>
        <>
        </>
      </div>
    );
  }
}

export default TransactionKeyHandler;
