import React, { Component } from "react";

import { IoEnterOutline, IoPricetagOutline,  IoPartlySunnyOutline, IoCheckmarkDone, IoBandage, IoPulseOutline } from "react-icons/io5";
import { IconContext } from "react-icons";
import * as CT_ProcessStatus from  ".//../../../commonxV3_001/codetable/CT_ProcessStatus";
import * as EnvironmentService from "./../../../EnvironmentService";



class WorkOnProcessItemListUiStatusIcon extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {}

  render() {

    EnvironmentService.log(this.props);
    const { processStatus} = this.props.row.status;
   // const { indUiStartable} = this.props.row.indUiStartable;

    let selectSwitch = this.props.row.status;
    // selectSwitch = CT_ProcessStatus.CODE.JS_PROCESS_CORRUPTED; //only for testing

    switch(selectSwitch) {
      case CT_ProcessStatus.CODE.JS_IN_PROCESS:
        return (
          <div>
            <IconContext.Provider value={{className:"preQ_SME_icon_process_status_JS_IN_PROCESS"}}>
              <IoPulseOutline size="18"/>
            </IconContext.Provider>
          </div>
        );

      case CT_ProcessStatus.CODE.JS_PROCESS_CORRUPTED:
        return (
          <div>
            <IconContext.Provider value={{className:"preQ_SME_icon_process_status_JS_PROCESS_CORRUPTED"}}>
              <IoBandage size="18"/>
            </IconContext.Provider>
          </div>
        );

      case CT_ProcessStatus.CODE.JS_PROCESS_DONE: 
        return (
          <div>
            <IconContext.Provider value={{className:"preQ_SME_icon_process_status_JS_PROCESS_DONE"}}>
              <IoCheckmarkDone size="18"/>
            </IconContext.Provider>
          </div>
        );

      case CT_ProcessStatus.CODE.JS_RAISING:
        return (
          <div>
            <IconContext.Provider value={{className:"preQ_SME_icon_process_status_JS_RAISING"}}>
              <IoPartlySunnyOutline size="18"/>
            </IconContext.Provider>
          </div>
        );

      case CT_ProcessStatus.CODE.JS_TO_BE_PROCESSED:
        return (
          <div>
            <IconContext.Provider value={{className:"preQ_SME_icon_process_status_JS_TO_BE_PROCESSED"}}>
              <IoPricetagOutline size="18"/>
            </IconContext.Provider>
          </div>
        );

      case CT_ProcessStatus.CODE.UNDEFINED:
        if (true) {
          return (
            <div>
              <IconContext.Provider value={{className:"preQ_SME_icon_process_status_UNDEFINED"}}>
                <IoEnterOutline size="18"/>
              </IconContext.Provider>
            </div>
          );
  
        } else {
          return (
            <div>
              &nbsp;
            </div>
          );
  
        }


      default:  
        return (
          <div>
            <IconContext.Provider value={{className:"preQ_SME_icon_process_status_DEFAULT"}}>
              <IoEnterOutline size="18"/>
            </IconContext.Provider>
          </div>
        );

    }

   

  }

  //*******************************/
  //* Icon Touch Handler
  //*******************************/
  handleSelectCase(row) {
      EnvironmentService.log("TenantCaseListUiSelectionBox.handleSelectCase")
      EnvironmentService.log(row);
      this.props.functionCaseSelect(row);
  }

  handleDeSelectCase(row) {
      EnvironmentService.log("TenantCaseListUiSelectionBox.handleDeSelectCase")
      EnvironmentService.log("handleSelectCase")
      this.props.functionCaseDeselect(row);
   }
}

export default WorkOnProcessItemListUiStatusIcon;
