import React, { Component } from "react";

import { ContentSwitcher, Switch} from "carbon-components-react";

class Status300Details extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  render() {

    const { converterAmounts } = this.props;

    let indDetailsAvailable = true;
    if (converterAmounts === null)       { indDetailsAvailable = false;}
    if (converterAmounts === undefined)  { indDetailsAvailable = false;}
    if (indDetailsAvailable === false) {
      return (
        <div>
          &nbsp;
        </div>
      );
    }

    let list = converterAmounts.list;
    return (
        <div>
          <table width="100%">
            <tr>
              <td colspan="1" className="backGroundColor_process_PROCESS_ITEM"><h6>ConversionResult</h6></td>
              <td colspan="1" className="backGroundColor_process_PROCESS_ITEM"><h6>Files</h6></td>
            </tr>

            {list.map((entry) => (
              <tr>
                <td width="40%">{entry.conversionResult}</td>
                <td width="60%">{entry.amountOfFiles}</td>
              </tr>
            ))}
          </table>
          <br/>&nbsp;    
        </div>

//          <h6>SQL-Queries:</h6>
//          SQL1 = {sql1}<br/>
//          SQL2 = {sql2}<br/>
//          &nbsp;
    );
  }
}

export default Status300Details;
