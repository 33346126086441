import React, { Component } from "react";

import * as PreQV3Constants from "./../../../../commonxV3_001/PreQV3Constants";
import { BiCheckbox, BiCheckboxChecked, BiRightArrow, BiLeftArrow, BiTrash, BiFolder, BiFileBlank } from "react-icons/bi";
import { IoIosFolder, IoIosDocument } from "react-icons/io";
import { IconContext } from "react-icons";
import * as I18nDateConverter from "./../../../../commonxV3_001/i18n/I18nDateConverter";
import DevelopmentDisplayWorkOnCaseFileCommanderPane from "./DevelopmentDisplayWorkOnCaseFileCommanderPane";
import GenericFileUploader from "./../../../../commonxV3_001/GenericFileUploader";
import * as RestClient from "./../../../backend/RestClient";
import * as environmentService from "./../../../../EnvironmentService";

import { ContentSwitcher, Switch} from "carbon-components-react";

class WorkOnCaseFileCommanderPane extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        selected: null, //objects
        selectedFiles:null, //only filenames
      };
  }

  render() {

    let tenant = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_ORG_UNIT);
    let caseid = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_CASE);

    let selection = this.getSelection();

    const {level, files} = this.props;

    files.sort((a, b) => (a.uiSortString > b.uiSortString) ? 1 : -1)

    if (files.length > -1) {
      return (
        <div>
          <table  width="100%">
              <tr>
                <td width = "20%" style={{fontWeight:"bold",backgroundColor: "#e3e3e3"}}>
                { level === 'tenant'
                  ? <div>&nbsp;&nbsp;/toIbm</div>
                  : <div>&nbsp;&nbsp;/toPreQ</div >
                }
                </td>
                <td width = "20%" style={{fontWeight:"normal",backgroundColor: "#f3f3f3"}} onClick={this.selectAll.bind(this)}   >
                <IconContext.Provider value={{className:"preQ_SME_file_list_icon_context_selected"}}>
                <BiCheckboxChecked
                  size="24"
                  onClick={this.selectAll.bind(this)}
                />
                </IconContext.Provider>
                  Select All
               </td>
               <td width = "20%" style={{fontWeight:"normal",backgroundColor: "#f3f3f3"}} onClick={this.deSelectAll.bind(this)}>
                 <IconContext.Provider value={{className:"preQ_SME_file_list_icon_context_deselected"}}>
                        <BiCheckbox
                          size="24"
                          onClick={this.deSelectAll.bind(this)}
                          />
                </IconContext.Provider>
                  Deselect All
               </td>
               <td width = "20%" style={{fontWeight:"normal",backgroundColor: "#f3f3f3"}}>
               { level === 'tenant'
                  ? <div onClick={this.doMove.bind(this)}>
                    <IconContext.Provider value={{className:"preQ_SME_file_list_icon_context_action"}}>
                            <BiRightArrow
                              size="24"
                              />
                    </IconContext.Provider>
                    Move to /toPreQ
                </div>
                  : <div onClick={this.doMove.bind(this)}>
                    <IconContext.Provider value={{className:"preQ_SME_file_list_icon_context_action"}}>
                            <BiLeftArrow
                              size="24"
                              />
                    </IconContext.Provider>
                    Move to /toIbm
                  </div >
                }
               </td>
                <td width = "20%" style={{fontWeight:"normal",backgroundColor: "#f3f3f3"}}>
                  <div>
                    </div >              
                </td>
              </tr>
              <tr>
              <td width = "20%" style={{fontWeight:"bold",backgroundColor: "#e3e3e3"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
               </td>
               <td width = "20%" style={{fontWeight:"bold",backgroundColor: "#f3f3f3"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
               </td>
               <td width = "20%" style={{fontWeight:"bold",backgroundColor: "#f3f3f3"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
               </td>
               <td width = "20%" style={{fontWeight:"bold",backgroundColor: "#f3f3f3"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
               </td>
               <td width = "20%" style={{fontWeight:"bold",backgroundColor: "#f3f3f3"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
               </td>
              </tr>
            </table>

            <table id ="preQ_SME_file_list_table">
            <tr>
              <th width="8%"></th>
              <th width="8%"></th>
              {(files.length > 0)
              ? <th width="60%">File</th>
              : <th width="60%">No files</th>
              }
              {(files.length > 0)
              ? <th width="20%">created</th>
              : <th width="20%">&nbsp;</th>
              }              
            </tr>
            
              {files.map((file) => (
                <tr>

                  <td>
                        {
                          (file.indDirectory === true) 
                          ? <div>
                              <IconContext.Provider value={{className:"preQ_SME_file_list_icon_directory"}}>
                              <IoIosFolder
                                size="24"
                              />
                              </IconContext.Provider>
                          </div>
                          : <div>
                              <IconContext.Provider value={{className:"preQ_SME_file_list_icon_file"}}>
                              <IoIosDocument
                                size="24"
                              />
                              </IconContext.Provider>
                          </div>
                        }
                  </td>


                  <td>
                    

                    { this.isSelected(file)
                     ?<div>
                      <IconContext.Provider value={{className:"preQ_SME_file_list_icon_context_selected"}}>
                        <BiCheckboxChecked
                          size="24"
                          onClick={this.toggleFileSelection.bind(this, file)}
                        />
                        </IconContext.Provider>
                     </div> 
                    : <div>
                      <IconContext.Provider value={{className:"preQ_SME_file_list_icon_context_deselected"}}>
                        <BiCheckbox
                          size="24"
                          onClick={this.toggleFileSelection.bind(this, file)}
                        />
                        </IconContext.Provider>
                      </div>
                    }


                  </td>


                  <td>
                    { this.isSelected(file)
                    ? <div className="preQ_SME_file_list_text_selected" onClick={this.toggleFileSelection.bind(this, file)}>
                        {file.key}
                      </div>
                    : <div className="preQ_SME_file_list_text_deselected" onClick={this.toggleFileSelection.bind(this, file)}>
                        {file.key}
                      </div>
                  }

                  </td>

                  <td>
                      <div className="preQ_SME_file_list_attr">
                        &nbsp;&nbsp;{this.getFileCreatedAsClearText(file.created)}&nbsp;&nbsp;
                      </div>
                  </td>

                </tr>
              ))}
            </table>
            <br/>
            { level === 'tenant'
                  ? <div>&nbsp;&nbsp;<GenericFileUploader
                      droptext = "..." 
                      boxtext = "Click me or drag ONE file to be transfered to /toibm"
                      checkInFunction = {this.checkInTo_toIbm.bind(this)} 
                      finalCheckInSuccessFunction = {this.updateUI.bind(this)}
                      /></div>
                  : <div>&nbsp;&nbsp;</div >
            }

            <DevelopmentDisplayWorkOnCaseFileCommanderPane
              uiParams = {this.props.uiParams}
              devTagLabel={this.constructor.name}
              state = {this.state}
              files = {files}
             />
        </div>
      );
    } else {
      return(
        <div>
          <br/>
          No Files<br/>
          <DevelopmentDisplayWorkOnCaseFileCommanderPane
              uiParams = {this.props.uiParams}
              devTagLabel={this.constructor.name}
              state = {this.state}
              files = {files}
             />
        </div>
      );
    }
  }

  getFileCreatedAsClearText(time) {
    let s = I18nDateConverter.getFileTimeStamp(time);
    return s;
  }


  /**
   * Get selection (only fileNames)
   */

  getFileSelection() {
    let selection = [];
    if (this.state.selectedFiles !== null) {
      selection = this.state.selectedFiles;
    }
    return selection;
  }

  /**
   * Get selection (complete objects)
   */
  getSelection() {
    let selection = [];
    if (this.state.selected !== null) {
      selection = this.state.selected;
    }
    return selection;
  }

  toggleFileSelection(file) {
    let selection = this.getSelection();
    let selectionFiles = this.getFileSelection();

    let indSelected = this.isSelected(file);
    if (indSelected === true) {
      let index = selection.indexOf(file);
      selection.splice(index, 1); 
      selectionFiles.splice(index, 1); 
    } else {
      selection.push(file);
      selectionFiles.push(file.key);
    }
    this.setState(
      {
        selected: selection,
        selectedFiles:selectionFiles, //only filenames
      }
    );
  }

  selectAll() {
    const {files} = this.props;
    let selection = [];
    let selectionFiles = [];

    files.forEach(function (value, i) {
      selection.push(value);
      selectionFiles.push(value.key);
    });

    this.setState(
      {
        selected: selection, //objects
        selectedFiles:selectionFiles, //only filenames
      }
    );
  }

  deSelectAll() {
    this.setState(
      {
        selected: null, //objects
        selectedFiles:null, //only filenames
      }
    );
  }

  isSelected(file) {

    let selection = this.getSelection();
    if (selection.includes(file)) {
      return true;
    }
    return false;
  }

  async doMove() {
    await this.props.moveFunction(this.state.selectedFiles);
    this.deSelectAll();
  }

  async updateUI() {
    await this.props.updateBothListsFunction();
  }

  async checkInTo_toIbm(uploadedID) {
    let data_FromServer = await RestClient.checkInFileUploadTo_toIbm(uploadedID);
    return data_FromServer;
  }
}

export default WorkOnCaseFileCommanderPane;
