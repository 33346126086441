import React, { Component } from "react";

import { Theme, Button, Select, SelectItem } from '@carbon/react';
import AllPreqXceptions from "./AllPreqXceptions";
import * as PreQV3Constants from "./../../commonxV3_001/PreQV3Constants";

class AdminUi extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        selectedUI : null
      };
  }


  render() {

    let preqKey = PreQV3Constants.getPreqKey();
    let indXA = preqKey.startsWith('xa')

    if (indXA) {

      return (
        <Theme theme="g90">
          <h3>Admin Ui</h3>
          &nbsp;<br/>
          <table width="100%">
                <tr>
                  <td width="2%">
                    &nbsp;                
                  </td>
                  <td width="8%">

                    <table>
                      <tr>
                        <td>
                          Excpetions
                        </td>
                      </tr>
                    </table>

                  </td>
                  <td width="90%" valign="top">
                    <AllPreqXceptions />
                  </td>
                </tr>
          </table>

        </Theme>

      );
    }


    return (
      <Theme theme="g90">
      <h3>Administrative Information: Excpetions</h3>
      &nbsp;<br/>
      <table width="100%">
            <tr>
              <td width="1%">
                &nbsp;                
              </td>
              <td width="1%">
              &nbsp;                
              </td>
              <td width="98%" valign="top">
                <AllPreqXceptions />
              </td>
            </tr>
      </table>

    </Theme>
);


  }


  


}

export default AdminUi;


