/**
 * RestClient retrieves ModuleBaseUrls as Array of identifier => baseUrl
 * This method retreives a baseUrl as f (identifier)
 * @param {*} moduleUrls 
 * @param {*} identifier - e.g.: 'ContentTypes'
 * @returns 
 */
export function getModuleBaseUrlByIdentifier(moduleUrls, identifier) {

  let moduleBaseUrl = "";
  moduleUrls.forEach(moduleUrl => {
    if (moduleUrl.identifier === identifier) {
      moduleBaseUrl = moduleUrl.baseUrl; 
    }
   });

  return moduleBaseUrl;
}



