import * as PreQV3Constants from "./../PreQV3Constants";

export const CODE = {
  UNDEFINED: "",
  JS_SINGLE_STEP: "SINGLE_STEP",
  JS_SEQUENCING: "SEQUENCING",
}


export function getTextForCode(code) {

  let i18nSessionVar = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED);
  if ((i18nSessionVar === null) || (i18nSessionVar === undefined)) {
    i18nSessionVar = "en";
  }

  if (i18nSessionVar === "de") {

    switch (code) {
      case "SINGLE_STEP": return "Einzelschrittverarbietung";
      case "SEQUENCING": return "Sequencierung";
      default:
        return code;
    }
  }

  switch (code) {
    case "SINGLE_STEP": return "Single Step Processing";
    case "SEQUENCING": return "Sequencing";
  
    default:
      return "";
  }
}  
  
export function getHintForCode(code) {

  let i18nSessionVar = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED);
  if ((i18nSessionVar === null) || (i18nSessionVar === undefined)) {
    i18nSessionVar = "en";
  }

  if (i18nSessionVar === "de") {

    switch (code) {
      case "SINGLE_STEP": return "";
      case "SEQUENCING": return "";
      default:
        return code;
    }
  }

  switch (code) {
    case "SINGLE_STEP": return "";
    case "SEQUENCING": return "";
  
    default:
      return "";
  }
  
}

