import * as environmentService from "./../../EnvironmentService";
import * as CT_ADD_ModuleNames from "./../../commonxV3_001/codetable/CT_ADD_ModuleNames";
import * as PreQV3Constants from "./../../commonxV3_001/PreQV3Constants";
import * as Facade from "./../../Facade";

const HttpCodes = {
  OK : 200,
  BAD_REQUEST : 400,
  INTERNAL_SERVER_ERROR : 500,

}


export async function triggerWama(uploadedid) {
  let module = CT_ADD_ModuleNames.CODE.JS_MODULE_TENANT;
  let endPoint = '/preq~sbv3~service~wama~process~upload~' + uploadedid; 
  let checkInUrl = await Facade.REST_CALL_UR_FOR_ENDPOINT(module, endPoint);

  let response_FromServer = await fetch(checkInUrl, {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain',
      'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
      'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
    },
      body : null,
   })

  let httpStatus = HttpCodes.OK;
  if (!response_FromServer.ok) {
    httpStatus = response_FromServer.status;
  }

  if (httpStatus === HttpCodes.OK) {
      let data_FromServer = await response_FromServer.json();
      environmentService.log("data_FromServer", data_FromServer);
      return data_FromServer;
  }  

  if (httpStatus === HttpCodes.BAD_REQUEST) {
      let data_FromServer = await response_FromServer.json();
      environmentService.log("data_FromServer", data_FromServer);
      return data_FromServer;
  }

  if (httpStatus === HttpCodes.INTERNAL_SERVER_ERROR) {
      let data_FromServer = await response_FromServer.json();
      environmentService.log("data_FromServer", data_FromServer);
      return data_FromServer;
  }

  let errorJson = PreQV3Constants.CONSTANTS.ERROR_UNKNOWN;
  let error = JSON.parse(errorJson); 
  return error;

}
