import React, { Component } from "react";

import JSONViewer from 'react-json-viewer';
import DevelopmentTag from "./../../../common//DevelopmentTag";

class WorkOnCaseUiDisplayRightResultParameterUi extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  render() {
    let row = this.props.row;
    let parameter = row.parameter;
    let listFetchObj = this.props.listFetchObj;

    let displayParams = true;
    if (parameter === null) {
      displayParams = false;
    }

    if (parameter === 'undefined') {
      displayParams = false;
    }

    if (parameter === "") {
      displayParams = false;
    }

    if (displayParams) {
      return (
        <div>
          <DevelopmentTag uiParams = {this.props.uiParams} devTagLabel = {this.constructor.name}/> 
          <table width="100%">
            <tr>
              <td width="100%" className="backGroundColor_process_PROCESS_ITEM">Parameter</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>
                <JSONViewer
                  json={JSON.parse(parameter)}
                />
              </td>
            </tr>
          </table>         
        </div>
      );
  
    } else {
      return (
        <div>
          <DevelopmentTag uiParams = {this.props.uiParams} devTagLabel = {this.constructor.name}/> 
          <table width="100%">
            <tr>
              <td width="100%" className="backGroundColor_process_PROCESS_ITEM">Parameter</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>-</td>
            </tr>
          </table>         
        </div>
      );

    }

  }
}

export default WorkOnCaseUiDisplayRightResultParameterUi;
