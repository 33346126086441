import React, { Component } from "react";

import { BiPowerOff } from "react-icons/bi";
import { IconContext } from "react-icons";
import * as CT_ProcessingStepsBusinessNames from "./../../../commonxV3_001/codetable/CT_ProcessingStepsBusinessNames";
import * as CT_ProcessStatus from "./../../../commonxV3_001/codetable/CT_ProcessStatus";
import * as PreQV3Constants from "./../../../commonxV3_001/PreQV3Constants";

import DevelopmentTag from "./../../../common//DevelopmentTag";
import { NumberInputSkeleton } from "carbon-components-react";

class WorkOnCaseUiDisplayRightResultHeaderUi extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  render() {
    let autoUpdateActive = PreQV3Constants.getAutoUpdateValue();
    let row = this.props.row;
    let chainEntry = this.props.chainEntry;
    let headName = CT_ProcessingStepsBusinessNames.getTextForCode("C" + chainEntry.sequenceIndex.toString());
    let logLink = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
                + "/RESTServiceFileDownLoad/Tenant/preq/sbv3/service/analysis/support/processlog/"
                + this.props.row.processid;
    let logFileName = this.props.row.processid + ".log";

    let displayLoglink = false;
    if (this.props.row.status == CT_ProcessStatus.CODE.JS_PROCESS_DONE) {
      displayLoglink = true;
    }            
    if (this.props.row.status == CT_ProcessStatus.CODE.JS_PROCESS_CORRUPTED) {
      displayLoglink = true;
    }      

    return (
      <div>
       <DevelopmentTag uiParams = {this.props.uiParams} devTagLabel = {this.constructor.name}/> 
       <table width="100%">
        <tr>
          <td width="3%">
            {
              autoUpdateActive
              ?
              <div>
                &nbsp;
              </div>
              :
              <div  onClick={this.handleReset.bind(this)}>
              <IconContext.Provider value={{className:"preQ_SME_icon_process_status_JS_PROCESS_CORRUPTED"}}>
                  <BiPowerOff size="22"/>
              </IconContext.Provider>
              </div>
  
            }
          </td>
          <td width="97%">
              <h6>{headName}</h6>
          </td>
        </tr>
       </table>

        <br/>
        <table width="100%">
          <tr>
            <td width="40%" className="backGroundColor_process_PROCESS_ITEM">ProcessID</td>
            <td width="1%" className="backGroundColor_process_PROCESS_ITEM"></td>
            <td width="25%" className="backGroundColor_process_PROCESS_ITEM">started at</td>
            <td width="1%" className="backGroundColor_process_PROCESS_ITEM"></td>
            <td width="25%" className="backGroundColor_process_PROCESS_ITEM">Status</td>
            <td width="1%" className="backGroundColor_process_PROCESS_ITEM"></td>
            <td width="7%" className="backGroundColor_process_PROCESS_ITEM"></td>
          </tr>
          <tr>
            <td>{this.props.row.processid}</td>
            <td></td>
            <td>{this.props.row.added_txt}</td>
            <td></td>
            <td>{this.props.row.status}</td>
            <td></td>
            <td>
              
              { displayLoglink === true 
              ? <div>
                    <a
                    href={logLink}
                    download ={logFileName}
                    rel="noopener"
                    aria-label='.log'
                    >
                    .log
                    </a>
                </div>
              : <div></div>
              }
               
               </td>
          </tr>
        </table>         
      </div>
    );
  }

  handleReset() {
    this.props.resetDisplayRightSettings(null);
  }
}

export default WorkOnCaseUiDisplayRightResultHeaderUi;
