import React, { Component } from "react";
import { Button, } from "carbon-components-react";
import DevelopmentTag from "./../../../common//DevelopmentTag";
import * as CT_ADD_DisplayRightType from  "../../../commonxV3_001/codetable/CT_ADD_DisplayRightType";
import * as PreQV3Constants from "./../../../commonxV3_001/PreQV3Constants";
import * as RestClient from "./../../backend/RestClient";


class WorkOnCaseUiDisplayRightParameterUiStartProcessButton extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }


  render() {
    let showStartBatchButton = this.props.showStartBatchButton;
    let displayRightType = this.props.displayRightType;

    if (showStartBatchButton === true) {

      let buttonName = "Start ???";
      if (displayRightType === CT_ADD_DisplayRightType.CODE.MANUALLY_PARAMETER_ONESTEP) {
        buttonName = "Start Single Step";
      }
      if (displayRightType === CT_ADD_DisplayRightType.CODE.MANUALLY_PARAMETER_SEQUENCE) {
        buttonName = "Start Step Sequence";
      }
  
      return (
        <div>
          <DevelopmentTag uiParams = {this.props.uiParams} devTagLabel = {this.constructor.name}/>
          <br/>
          <Button kind="tertiary" onClick={this.triggerBatch.bind(this)}>{buttonName}</Button>
        </div>
      );
    }

    return (
      <div>
        &nbsp;
      </div>
    );

  }

  async triggerBatch() {

    let tenant = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_ORG_UNIT);
    let caseid = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_CASE);
    let lstToPrq = await RestClient.getFileListTenantCaseToPreQ(tenant, caseid);
    let listToPreQ = lstToPrq.submittedFiles;
    if (listToPreQ.length < 1) {
      return;
    }
    this.props.handleModalOpen();
  }

}

export default WorkOnCaseUiDisplayRightParameterUiStartProcessButton;
