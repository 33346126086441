export const CODE = {
  UNDEFINED : "",
  JS_DELETE_TENANT : "DELETE_TENANT",
  JS_DELETE_CASE : "DELETE_CASE",
  JS_RESET_CASE : "RESET_CASE",
  JS_MOVE_FILES_SRC_TOIBM_DEST_TOPREQ : "MOVE_FILES_SRC_TOIBM_DEST_TOPREQ",
  JS_MOVE_FILES_SRC_TOOREQ_DEST_TOIBM : "MOVE_FILES_SRC_TOOREQ_DEST_TOIBM",
  JS_MOVE_FILES_SRC_FROMPREQ_DEST_FROMIBM : "MOVE_FILES_SRC_FROMPREQ_DEST_FROMIBM",
  JS_CREATE_REVIEW_FILE_EXPORT : "CREATE_REVIEW_FILE_EXPORT",
  JS_CHECK_REVIEW_FILE_IMPORT : "CHECK_REVIEW_FILE_IMPORT",
}


export function getTextForCode(code) {
  switch (code) {

  case "DELETE_TENANT": return "Delete OrgUnit";
  case "DELETE_CASE": return "Delete Case";
  case "RESET_CASE": return "Reset Case";
  case "MOVE_FILES_SRC_TOIBM_DEST_TOPREQ": return "Move Input to Case-Level  '/toPreQ'"
  case "MOVE_FILES_SRC_TOOREQ_DEST_TOIBM": return "Move Input to Tenant-Level  '/toibm'"
  case "MOVE_FILES_SRC_FROMPREQ_DEST_FROMIBM" : return "Move Results to Tenant-Level  '/fromibm'";
  case "MOVE_FILES_SRC_FROMPREQ_DEST_FROMIBM" : return "Move Results to Tenant-Level  '/fromibm'";
  case "CREATE_REVIEW_FILE_EXPORT" : return "Create Review File Export"
  case "CHECK_REVIEW_FILE_IMPORT" : return "Submit & Check Review File"
    default:
      return "";
  }
}

