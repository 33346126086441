import React from 'react';
import { useState } from 'react';
import i18next from "i18next"

import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderNavigation,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  ComposedModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UnorderedList,
  ListItem, Accordion,
  Loading,
  AccordionItem,
  HeaderMenu,
  TextInput,
  Button
} from '@carbon/react';

import { User, Help, Information, Logout } from '@carbon/react/icons';
import { Link } from 'react-router-dom';

import * as PreQV3Constants from "./../../modules/commonxV3_001/PreQV3Constants";
import * as CT_UiModus from "./../../modules/commonxV3_001/codetable/CT_UiModus";
import TransactionKeyHandler from "./TransactionKeyHandler";

function switchLang(lang) {
  i18next.changeLanguage(lang);
  sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED, lang);
  PreQV3Constants.resetSessionVariablesToBaseSet(false, false);
  window.location.replace('/')
}


 async function reset() {
    PreQV3Constants.resetSessionVariablesToBaseSet(true, true);
    window.location.replace('/');
 }

function getToolTipText(tool) {
  if (tool === "Help") {
    if (sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED) == "en") {
      return "Help";
    } else if (sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED) == "de") {
      return "Hilfe";
    } else {
      return "Help";
    }
  } else if (tool === "Info") {
    if (sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED) == "en") {
      return "Info";
    } else if (sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED) == "de") {
      return "Info";
    } else {
      return "Info";
    }
  } else if (tool === "User") {
    if (sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED) == "en") {
      return "Logout";
    } else if (sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED) == "de") {
      return "Abmelden";
    } else {
      return "Logout";
    }    
  } else {
    return "Tool " + tool + " undefined";
  }
}

const ApplicationHeader = (props) => {

  const [helpModalOpen, setHelpModalOpen] = useState(false)
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [userModelOpen, setUserModalOpen] = useState(false)

  const [localeModalOpen, setLocaleModalOpen] = useState()
  const [localeSenslessOpen, setLocaleSenslessOpen] = useState(false)
  const [localeNotPossibleOpen, setLocaleNotPossibleOpen] = useState(false)

  let localeActive = true;
  let pseudoActive = false;

  const [modalInfoWAMAOpen, setModalInfoWAMAOpen] = useState(false)
  const [modalAboutWAMAOpen, setModalAboutWAMAOpen] = useState(false)

  let uiParams = props.uiParams;
  if (uiParams === null) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  let indSMEModus = false;
  let indWAMAModus = false;
  if (uiParams.uiModus === CT_UiModus.CODE.JS_UI_MODUS_SME) {
    indSMEModus = true;
  }
  if (uiParams.uiModus === CT_UiModus.CODE.JS_UI_MODUS_WAMA) {
    indWAMAModus = true;
  }

  let indShowBtn = false;
  let indShowElementsForLoggedInUser = false;
  let loggedInUser = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_LOGGED_IN_USER_NAME);
  let loggedInUserIsAdmin = false;
  if (loggedInUser !== null) {
    indShowBtn = true;
    indShowElementsForLoggedInUser = true;
    let preQKey = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_PREQ_KEY);
    if (preQKey.startsWith("xa")) {
      loggedInUserIsAdmin = true;
    }
  }

  let i18nSessionVar = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED);
  if ((i18nSessionVar === null) || (i18nSessionVar === undefined)) {
    i18nSessionVar = "en";
    sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED, i18nSessionVar);
  }


  if (sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_LOCALE_ACTIVE) === "NO") {
    localeActive = false
  } else {
    localeActive = true
  }

  if (uiParams.sequenceIncludesPPPseudoGen.toString().toUpperCase() == "TRUE") {
    pseudoActive = true
  } else {
    pseudoActive = false
  }  

  return <HeaderContainer
    render={({ isSideNavExpanded, onClickSideNavExpand }) => (
      <Header aria-label="PreQ">
        <SkipToContent />
        {/*
        <HeaderMenuButton
          aria-label="Open menu"
          onClick={onClickSideNavExpand}
          isActive={isSideNavExpanded}
        />
        */}

        <HeaderName prefix="IBM">
          PreQ Service
        </HeaderName>
        <HeaderNavigation aria-label="Header Navigation 1">

          {/*      
        <HeaderMenuItem element={Link} to="/">
            {sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_LOCALE_ACTIVE)}
        </HeaderMenuItem>
   
        <HeaderMenuItem element={Link} to="/drop">
            Drag&Drop
        </HeaderMenuItem>
        <HeaderMenuItem element={Link} to="/modal">
            Modals
        </HeaderMenuItem>
        <HeaderMenuItem element={Link} to="/process">
            Process Bar
        </HeaderMenuItem>
        <HeaderMenuItem element={Link} to="/process-warning">
            Process Bar Warning
        </HeaderMenuItem>
*/}
        </HeaderNavigation>
        <HeaderGlobalBar>
          <HeaderNavigation aria-label="Carbon Tutorial">

            {localeActive
              ?

              <HeaderMenu aria-label="EN" menuLinkName={sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED).toUpperCase()} >
                {/* ---------------------------------------------------------------------------
ACHTUNG

Wenn eine neue Sprache hinzugef�gt werden soll, dann m�ssen zus�tzlich zu einem
neuen HeaderMenuItem auch die beiden Modalen Overlays 'localeModalOpen' und
'localeSenslessOpen' um die Sprachen erweitert werden, da diese beiden Overlays
nicht mit der automatisierten Lokalisierung funktionieren.
Weiterhin mu� die Funktion 'getToolTipText()' oben im Code erweitert werden.
--------------------------------------------------------------------------- */}
{/* Die u.a. Funktion �ffnet eine modale Abfrage
    Gem�� TSSPREQ-258 wurde diese Abfrage ersetzt durch
    
    switchLang("de");

    und die Sprache wird ohne Abfrage gewechselt.

    setLocaleModalOpen("de");

    Dies gilt f�r die weiteren Sprachen analog
*/}
                <HeaderMenuItem
                  onClick={() => {
                    {
                      sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_LOCALE_ACTIVE) === "NO"
                      ?
                      setLocaleNotPossibleOpen(true)
                      :
                      i18next.language === "de"
                        ?
                        setLocaleSenslessOpen(true)
                        :
                        switchLang("de");
                    }
                  }
                  }
                >
                  DE
                </HeaderMenuItem>

                <HeaderMenuItem
                  onClick={() => {
                    {
                      sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_LOCALE_ACTIVE) === "NO"
                      ?
                      setLocaleNotPossibleOpen(true)
                      :
                      i18next.language === "en"
                        ?
                        setLocaleSenslessOpen(true)
                        :
                        switchLang("en");
                    }
                  }
                  }
                >
                  EN
                </HeaderMenuItem>

              </HeaderMenu>
              :
              <></>
            }
          </HeaderNavigation>

          {indShowBtn
            ? <>
              <HeaderGlobalAction aria-label={getToolTipText("Help")} tooltipAlignment="start">
                <Help
                  size={20}
                  onClick={() =>
                    setHelpModalOpen(true)
                  }
                />
              </HeaderGlobalAction>
            </>
            : <></>
          }

          <ComposedModal open={helpModalOpen} onClose={() => setHelpModalOpen(false)}>
            <ModalHeader title={i18next.t("app.title") + " - " + i18next.t("ApplicationHeader.ModalHelp.heading.post")} />
            <ModalBody >
              <div className="padding-l-16">
                {
                  indSMEModus
                    ?
                    <div>
                      <p className="margin-b-16">
                        Please find here some frequently asked questions. If you can't find an answer, feel free to reach out to <a href="mailto:PreQ.Service@de.ibm.com">PreQ.Service@de.ibm.com</a>
                        <Accordion align="start">
                          <AccordionItem title="I found an error / bug, how can I report it?">
                            Please send an email to <a href="mailto:PreQ.Service@de.ibm.com">PreQ.Service@de.ibm.com</a> with a short description of the issue. If possible, please add a screenshot of the error.
                          </AccordionItem>
                          <AccordionItem title="Who can I contact for more information on this service?">
                            This service is provided by the DACH TLS team. You can reach out to us via our <a href="https://w3.ibm.com/w3publisher/avp/tls-preq-offering" target="_blank">w3 publisher</a> page (IBM internal) or send an email to <a href="mailto:PreQ.Service@de.ibm.com">PreQ.Service@de.ibm.com</a>. You can also find our <a href="https://ibm.box.com/s/hjrprlkew0v8utqbjxbszsre1wyq4caa" target="_blank">OnePager</a>.
                          </AccordionItem>
                        </Accordion>
                      </p>
                    </div>
                    :
                    <div>
                      <p className="margin-b-16">
                        {i18next.t("ApplicationHeader.ModalHelp.text.subheading")} <a href="mailto:PreQ.Service@de.ibm.com">PreQ.Service@de.ibm.com</a>
                      </p>
                      <Accordion align="start">
                        <AccordionItem title={i18next.t("ApplicationHeader.ModalHelp.Accordion1.heading")}>
                          {i18next.t("ApplicationHeader.ModalHelp.Accordion1.text1")} {uiParams.wamaMaxUploadableFileSizeInKb.toString()} {i18next.t("ApplicationHeader.ModalHelp.Accordion1.text2")}
                          &nbsp;{i18next.t("ApplicationHeader.ModalHelp.Accordion1.text3")}&nbsp;
                          {i18next.t("ApplicationHeader.ModalHelp.Accordion1.text4")} <a href="https://ibm.box.com/v/PreQPOCTestfiles" target="_blank">{i18next.t("ApplicationHeader.ModalHelp.Accordion1.1stLink")}</a>. {i18next.t("ApplicationHeader.ModalHelp.Accordion1.text5")}
                        </AccordionItem>
                        <AccordionItem title={pseudoActive?i18next.t("ApplicationHeader.ModalHelp.Accordion2.headingPseudo"):i18next.t("ApplicationHeader.ModalHelp.Accordion2.heading")}>
                          {i18next.t("ApplicationHeader.ModalHelp.Accordion2.text1")} <a href="https://pages.github.ibm.com/ai-foundation/watson-nlp-documentation/type_systems.html#nlu-version-2-entity-type-system" target="_blank">{i18next.t("ApplicationHeader.ModalHelp.Accordion2.1stLink")}</a> {i18next.t("ApplicationHeader.ModalHelp.Accordion2.text2")}
                        </AccordionItem>
                        <AccordionItem title={i18next.t("ApplicationHeader.ModalHelp.Accordion3.heading")}>
                          {i18next.t("ApplicationHeader.ModalHelp.Accordion3.text1")} <a href="mailto:PreQ.Service@de.ibm.com">PreQ.Service@de.ibm.com</a> {i18next.t("ApplicationHeader.ModalHelp.Accordion3.text2")}
                        </AccordionItem>
                        <AccordionItem title={i18next.t("ApplicationHeader.ModalHelp.Accordion4.heading")}>
                          {i18next.t("ApplicationHeader.ModalHelp.Accordion4.text1")} <a href="https://w3.ibm.com/w3publisher/avp/tls-preq-offering" target="_blank">{i18next.t("ApplicationHeader.ModalHelp.Accordion4.1stLink")}</a> {i18next.t("ApplicationHeader.ModalHelp.Accordion4.text2")} <a href="mailto:PreQ.Service@de.ibm.com">PreQ.Service@de.ibm.com</a>. {i18next.t("ApplicationHeader.ModalHelp.Accordion4.text3")} <a href="https://ibm.box.com/s/hjrprlkew0v8utqbjxbszsre1wyq4caa" target="_blank">OnePager</a>.
                        </AccordionItem>
                      </Accordion>
                    </div>
                }
              </div>
            </ModalBody>
          </ComposedModal>

          {indShowBtn
            ? <>
              <HeaderGlobalAction aria-label={getToolTipText("Info")} tooltipAlignment="start">
                <Information
                  size={20}
                  onClick={() =>
                    setInfoModalOpen(true)
                  }
                />
              </HeaderGlobalAction>
            </>
            : <></>}

          <ComposedModal open={infoModalOpen} onClose={() => setInfoModalOpen(false)}>
            <ModalHeader title={i18next.t("app.title") + " - " + i18next.t("ApplicationHeader.ModalInfo.heading.post")} />
            <ModalBody >
              <div className="padding-l-16">
                {
                  indSMEModus
                    ? <UnorderedList>
                      <ListItem>PreQ Mission: PreQ is securing your privacy (Info SME)</ListItem>
                      <ListItem>Version: 1.0</ListItem>
                      <ListItem>Genutzte OSS Pakete</ListItem>
                      <ListItem>Link auf OnePager (IBM W3 Intranet)</ListItem>
                      <ListItem>Download Test data file</ListItem>
                    </UnorderedList>
                    :
                    <div>
                      <p className="margin-b-16">
                        {i18next.t("ApplicationHeader.ModalInfo.text.subheading1")}
                        <br></br>
                        {i18next.t("ApplicationHeader.ModalInfo.text.subheading2")}
                      </p>
                      <hr size="1"></hr>
                      {i18next.t("ApplicationHeader.ModalInfo.topics.oneText")} <a href="https://ibm.box.com/s/hjrprlkew0v8utqbjxbszsre1wyq4caa" target="_blank">PreQ OnePager</a><br></br>
                      <a href="https://ibm.box.com/v/PreQPOCTestfiles" target="_blank">{i18next.t("ApplicationHeader.ModalInfo.topics.two.1stLink")}</a> {i18next.t("ApplicationHeader.ModalInfo.topics.two.text1")} <a href="https://ibm.box.com/v/PreQPOCTestfiles" target="_blank">{i18next.t("ApplicationHeader.ModalInfo.topics.two.2ndLink")}</a> {i18next.t("ApplicationHeader.ModalInfo.topics.two.text2")}<br></br>
                      {i18next.t("ApplicationHeader.ModalInfo.topics.three.text1")} <a href="https://www.ibm.com/de-de/privacy?lnk=flg-priv-dede" target="_blank">{i18next.t("ApplicationHeader.ModalInfo.topics.three.1stLink")}</a> {i18next.t("ApplicationHeader.ModalInfo.topics.three.text2")} <a href="https://www.ibm.com/de-de/about?lnk=flg" target="_blank">{i18next.t("ApplicationHeader.ModalInfo.topics.three.2ndLink")}</a>
                    </div>
                }
              </div>
            </ModalBody>
          </ComposedModal>

          {
            indShowElementsForLoggedInUser
              ? <>
                <HeaderGlobalAction aria-label={getToolTipText("User")} tooltipAlignment="start">
                  <User
                    size={20}
                    onClick={() =>
                      setUserModalOpen(true)
                    }
                  />
                </HeaderGlobalAction>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
              </>
              : <></>
          }

          <ComposedModal open={userModelOpen} onClose={() => setUserModalOpen(false)}>
            <ModalHeader title={i18next.t("app.title") + " - " + i18next.t("ApplicationHeader.ModalUser.heading.post")} />
            <ModalBody >
              <div className="padding-l-16">
                {
                  <div>
                    <p className="margin-b-16">
                      {i18next.t("ApplicationHeader.ModalUser.account.label1")}
                      <>&nbsp;&nbsp;:&nbsp;&nbsp;</>
                      {sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_LOGGED_IN_USER_NAME)}
                    </p>
                    <hr size="1"></hr>
                  </div>
                }
              </div>
            </ModalBody>
            <ModalFooter>
            <Button
                        renderIcon={Logout}
                        iconDescription="Logout"
                        onClick={() =>
                          reset()
                        }
                      >
                        {i18next.t("ApplicationHeader.ModalUser.buttonLogout")}
            </Button>
            <TransactionKeyHandler
              uiParams = {uiParams} />
            </ModalFooter>    
          </ComposedModal>


          <ComposedModal size="small" open={localeModalOpen} onClose={() => setLocaleModalOpen(false)}>
            <ModalHeader />
            <ModalBody>
              {localeModalOpen === "en"
                ?
                <p>
                  You have requested to switch the language to English.
                  <br></br>
                  This will reload the page and all performed inputs will be removed.
                  <br></br><br></br>
                  Do you want to continue?
                </p>
                :
                localeModalOpen === "de"
                  ?
                  <p>
                    Sie m�chten die Sprache nach Deutsch wechseln.
                    <br></br>
                    Dies wird die Seite neu laden und alle bisherigen Eingaben gehen verloren.
                    <br></br><br></br>
                    M�chten Sie fortfahren?
                  </p>
                  :
                  i18next.t("ApplicationHeader.ModalLocale.errorText")
              }
            </ModalBody>
            <ModalFooter>
              <Button
                kind="secondary"
                onClick={() => { setLocaleModalOpen(false); }}>
                {localeModalOpen === "en"
                  ?
                  "Cancel"
                  :
                  localeModalOpen === "de"
                    ?
                    "Abbrechen"
                    :
                    "Error"
                }
              </Button>
              <Button
                kind="primary"
                onClick={() => {
                  switchLang(localeModalOpen);
                  setLocaleModalOpen(false);
                }}>
                {localeModalOpen === "en"
                  ?
                  "OK"
                  :
                  localeModalOpen === "de"
                    ?
                    "Weiter"
                    :
                    "Error"
                }
              </Button>
            </ModalFooter>
          </ComposedModal>

          <ComposedModal size="xs" open={localeSenslessOpen} onClose={() => setLocaleSenslessOpen(false)}>
            <ModalHeader />
            <ModalBody>
              <p>
                {i18next.t("ApplicationHeader.ModalSensless.text")}
              </p>
            </ModalBody>
            <ModalFooter>
              <Button
                kind="primary"
                onClick={() => { setLocaleSenslessOpen(false); }}>
                {i18next.t("ApplicationHeader.ModalSensless.okButton")}
              </Button>
            </ModalFooter>
          </ComposedModal>

          <ComposedModal size="small" open={localeNotPossibleOpen} onClose={() => setLocaleNotPossibleOpen(false)}>
            <ModalHeader />
            <ModalBody>
              <p>
                {i18next.t("ApplicationHeader.ModalNotPossible.text")}
              </p>
            </ModalBody>
            <ModalFooter>
              <Button
                kind="primary"
                onClick={() => { setLocaleNotPossibleOpen(false); }}>
                {i18next.t("ApplicationHeader.ModalNotPossible.okButton")}
              </Button>
            </ModalFooter>
          </ComposedModal>

        </HeaderGlobalBar>
      </Header>
    )}
  />
};

export default ApplicationHeader;
