import * as Facade from "./Facade"
import * as CT_ADD_ModuleNames from "./commonxV3_001/codetable/CT_ADD_ModuleNames";
import * as VersionInfo from "./VersionInfo";
import * as PreQV3Constants from "./commonxV3_001/PreQV3Constants"

/**
 * This function identifies the URL to access Tenant Module
 * @returns
 */
export async function getTenantBaseUrl() {

    let restURL = await Facade.REST_CALL_UR_FOR_MODULE('Tenant');
    return restURL;
}

/**
 * This method identifies the URL to a Module
 * @param {*} module (e.g. 'Archives')
 * @returns 
 */
export async function getModuleBaseUrl(module) {

    let restURL = await Facade.REST_CALL_UR_FOR_MODULE(module);
    return restURL;
}

export async function getUiParams() {

  let restURL = await Facade.REST_CALL_UR_FOR_ENDPOINT('UI', '/params');
  let response_FromServer = await fetch(restURL);
  if (!response_FromServer.ok) {
    return "";
  }
  let data_FromServer = await response_FromServer.json();

  let result = data_FromServer.params;

  for (var i = 0; i < result.moduleInfos.length; i++) {
    let module = result.moduleInfos[i];
    if (module.identifier === CT_ADD_ModuleNames.CODE.JS_MODULE_REACT) {
      let v = await VersionInfo.getVersion();
      module.version = v;
      let releaseVersion = await VersionInfo.getReleaseVersion();
      module.releaseVersion = releaseVersion;
      let releaseName = await VersionInfo.getReleaseName();
      module.releaseName = releaseName;
      let appName = await VersionInfo.getAppName();
      module.appName = appName;
    }
  } 

  return result;

}

export async function updateTransactionKey(sessionUser, currentTransactionKey) {
  if (sessionUser === '') {
    return null;
  }
  if (currentTransactionKey === '') {
    return null;
  }
  let updateUrl = await Facade.REST_TRANSACTION_KEY_UPDATE_URL();
  
  let objUser= {
    sessionUser: sessionUser,
    currentTransactionKey:currentTransactionKey
  }
  let callParameter = JSON.stringify(objUser);
  let response_FromServer = await fetch(updateUrl, {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8',
      'PreQ-Header-User': sessionUser,
      'PreQ-Header-TransactionKey' : currentTransactionKey,
    },
      body : callParameter,
   })
   if (response_FromServer.ok) {
    let data_FromServer = await response_FromServer.text()
    return data_FromServer;
   }
   return null;
}

/**
 * This method performs a login tu ReactUI (only ui ... no security on RRST-Calls etc.)
 * @param {*} user 
 * @param {*} password 
 * @return Object holding PreQKey and TransactionKey, when login was successfully or null (on no success)
 */
export async function login(user, password) {

  let loginUrl = await Facade.REST_CALL_UR_FOR_ENDPOINT('UI', '/login');
  let objCredentials= {
    user: user,
    password: btoa(password),
  }
  let callParameter = JSON.stringify(objCredentials);

  let response_FromServer = await fetch(loginUrl, {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8'
    },
      body : callParameter,
   })
   if (response_FromServer.ok) {
    let data_FromServer = await response_FromServer.text()
    return data_FromServer;
   }
   return null;
}

/**
 * This functions performs the logging for developers error analysis
 * @param {*} label
 * @param {*} object
 */
export function log(label, object) {
  let bLog = true;
  let uiPramsFromSession = PreQV3Constants.getUiParamsFromSession();
  if (uiPramsFromSession !== null) {
    let devMode = uiPramsFromSession.developmentModus;
    if (devMode === false) {
      bLog = false;
    }
  }

  if (bLog) {
    console.log(label);
    console.log(object);
  }
}


