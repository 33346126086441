/**
* Please do not remove or edit this file ... it is created/overwritten by PreQ's Versionizing Tool
*/
export async function getVersion() {
    let version = '3.3.0-x3.3.3';
    return version;
}
export async function getReleaseName() {
    let version = 'Stan';
    return version;
}
export async function getReleaseVersion() {
    let version = '3.3.2-RC01';
    return version;
}

export async function getAppName() {
    let version = await getReleaseName() + "_" + await getReleaseVersion();
    return version;
}

export function getUserManagementToolVersion() {
    let version = '3.3.0';
    return version;
}

export function getWatsonNlpVersion() {
    let version = 'v1.4.3-4.1.0_ubi8_py39';
    return version;
}

export function getCommonXVersion() {
    let version = '3.3.3';
    return version;
}

export function getBuildID() {
    let version = 'Build_itcoavp150.itc.ibm.com_root_202402212215';
    return version;
}

export function getBuildVersion() {
    let version = '3.3.2';
    return version;
}
