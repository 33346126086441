import React, { Component } from "react";

import {
  Button,
  Modal,
  TextInput, 
  Loading,
  Table,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableBody,
  TableCell,
  TableToolbar,
  TableToolbarContent,
} from "carbon-components-react";
import * as restClient from "./../../backend/RestClient";
import TenantCaseListUiSelectionBox from "./TenantCaseListUiSelectionBox";
import * as pojoIdentifier from "../../../commonxV3_001/ServerResponsePojoIdentifier";
import ServerSyncCallResultDisplayUi from "../../../commonxV3_001/ServerSyncCallResultDisplayUi";
import DevelopmentTag from "./../../../common//DevelopmentTag";
import * as CT_ADD_AsyncAction from "../../../commonxV3_001/codetable/CT_ADD_AsyncAction";
import * as PreQV3Constants from "./../../../commonxV3_001/PreQV3Constants";
import AsyncProcessDialogs from "./../../../commonxV3_001/app/AsyncProcessDialogs";
import * as environmentService from "./../../../EnvironmentService";


class TenantCaseListUi extends Component {
  constructor() {
    super();
    this.resetState();

  }


  resetState() {

    // Create the state
    this.state = {
      list: [],
      error: null,
      isLoading: true,

      headers: ["Case(s)", "Case Directory", ""],

      showListUi : true,

      modalShowUi : false,
      modalDialogError: null,
      modalForm: {
        caseid : null,
      },

/*      
 * ------------------------------------------------------------
 *  Code supporting asyncProcessDialogsModal =====>
 * ------------------------------------------------------------
 */ 
      asyncProcessDialogsModal: {
        active: false,
        tenant: null,
        caseid: null,
        code: null, //see Codetable CT_ADD_AsyncAction
        name : null, //clear text for asyncAction (retrieved from Codetable CT_ADD_AsyncAction)
        parameter : null, 
      }
/*      
 * ------------------------------------------------------------
 *  <===== Code supporting asyncProcessDialogsModal
 * ------------------------------------------------------------
 */ 


    };    
  }



  async componentDidMount() {
    this.showUpdatedList();
  }



  
  async showUpdatedList() {
    this.setState({ isLoading: true });

    let currentTenant = this.props.row.name;

    //let listFetchObj = null;
    let listFetchObj = await restClient.getTenantCaseList(currentTenant);

    //Update State
    this.setState({ isLoading: false, list: listFetchObj, error: null, showListUi : true, modalShowUi : false, modalDialogError: null,  modalForm: {...this.state.modalForm, caseid: null} });
  }

  handleAdd(event) {
    environmentService.log('', 'handleAdd')
    //Update State
    this.setState({ showListUi : false, modalShowUi : true });
  }

  /**
   * This method reacts on changes from Modal Dialog Fields
   * @param {*} event 
   */
   handleModalFormChange(event) {
    let fieldName = event.target.id;
    let fieldVal = event.target.value;
    environmentService.log('', fieldName + " : " + fieldVal);
    this.setState({modalForm: {...this.state.modalForm, [fieldName]: fieldVal}});
  }

  /**
   * This method reacts on [Save] or [Cancel] the modal Dialog 
   * @param {*} event 
   */
   async handleModalClose(event) {
    if (event === null) {
      this.showUpdatedList();
    } else {
      //try to save and handle error
      let caseid = this.state.modalForm.caseid;
      let indSave = ( caseid!== null && caseid !== "" && caseid !== "undefined")

      if (indSave) {

        let data_FromServer = await restClient.createTenantCase(this.props.row.name, caseid);
        let isError = pojoIdentifier.isPojoErrorResult(data_FromServer);
        if (isError) {
          this.setState({ modalDialogError: data_FromServer });
        } else {
          this.showUpdatedList();
        }
        
      } else {
        this.setState({ modalDialogError: null });
      }
    }
  }


  render() {
    const { list, isLoading, error, showListUi, modalDialogError, modalShowUi, asyncProcessDialogsModal  } = this.state;

    let currentTenant = this.props.row.name; //from enveloping accordion
    let selectedTenant = this.props.uiGetCurrentTenant(); //from Data01Page.js
    let selectedCase = this.props.uiGetCurrentCase();  //from Data01Page.js

    if (showListUi && isLoading && asyncProcessDialogsModal.active === false) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    if (showListUi && list !== null && asyncProcessDialogsModal.active === false) {
      list.sort((a, b) => (a.caseid > b.caseid) ? 1 : -1)

      return (
        <div>
          <DevelopmentTag uiParams = {this.props.uiParams} devTagLabel = {this.constructor.name}/>
          <br/>
          <table width = "100%" >
            <tr style={{backgroundColor:"#f0f0f0", height:"3px"}}>
              <td width = "2%">
                <div></div>
              </td>
              <td width = "6%">
              <div></div>
              </td>
              <td width = "30%"  style={{verticalAlign:"top", align:"left", paddingLeft:"2px", paddingTop:"6px", paddingBottom:"6px", fontWeight:"bold"}}>
              <div></div>
              </td>
              <td width = "30%"  style={{verticalAlign:"top", align:"left", paddingLeft:"2px", paddingTop:"6px", paddingBottom:"6px", fontWeight:"bold"}}>
              <div></div>
              </td>
              <td width = "17%"  style={{verticalAlign:"top", align:"left", paddingLeft:"2px", paddingTop:"6px", paddingBottom:"6px", fontWeight:"bold"}}>
              <div></div>
              </td>
              <td width = "17%"  style={{verticalAlign:"top", align:"left", paddingLeft:"2px", paddingTop:"6px", paddingBottom:"6px", fontWeight:"bold"}}>
              <div></div>
              </td>
            </tr>
            <tr style={{backgroundColor:"#ffffff", height:"2px"}}>
              <td style={{backgroundColor:"#f0f0f0"}}>
                &nbsp;
              </td>
              <td colSpan={4}>
                &nbsp;
              </td>
              <td style={{verticalAlign:"top", align:"center", paddingTop:"5px", paddingBottom:"5px"}}>
                <Button kind="tertiary" onClick={this.handleAdd.bind(this)} size='sm'>add Case</Button>
              </td>
            </tr>

            <tr style={{backgroundColor:"#e0e0e0"}}>
              <td style={{backgroundColor:"#f0f0f0"}}>
                &nbsp;
              </td>
              <td>
                &nbsp;
              </td>
              <td style={{verticalAlign:"top", align:"left", paddingLeft:"2px", paddingTop:"6px", paddingBottom:"6px", fontWeight:"bold"}}>
                Case
              </td>
              <td style={{verticalAlign:"top", align:"left", paddingLeft:"2px", paddingTop:"6px", paddingBottom:"6px", fontWeight:"bold"}}>
                CaseDirectory
              </td>
              <td style={{verticalAlign:"top", align:"center", paddingLeft:"2px", paddingTop:"6px", paddingBottom:"6px", fontWeight:"bold"}}>
                work on
              </td>
              <td>&nbsp;</td>
            </tr>
            {this.state.list.map((row) => (
                <tr style={{backgroundColor:"#ffffff" , height:"60px"}}>
                  <td style={{backgroundColor:"#f0f0f0"}}>

                  </td>
                  <td style={{borderBottom:"2px solid #f0f0f0"}}>
                    <div>
                    <br/>&nbsp;&nbsp;&nbsp;<img src="/preq_images/PreqWashDevice.jpg" with="33px" height="33px" vertical-align="top"/>
                    </div>
                  </td>
                  <td style={{verticalAlign:"middle", borderBottom:"2px solid #f0f0f0"}}>
                    <div>
                      {row.caseid}
                    </div>
                  </td>
                  <td style={{verticalAlign:"middle", borderBottom:"2px solid #f0f0f0"}}>
                    <div>
                      {row.caseDirectory}
                    </div>
                  </td>
                  <td style={{verticalAlign:"middle", borderBottom:"2px solid #f0f0f0"}}>
                    &nbsp;&nbsp;<TenantCaseListUiSelectionBox
                        row = {row}
                        currentTenant = {currentTenant}
                        selectedCase = {selectedCase}
                        selectedTenant ={selectedTenant} 
                        functionCaseSelect = {this.handleSelectCase.bind(this, row)}
                        functionCaseDeselect = {this.handleDeSelectCase.bind(this, row)}
                      />
                  </td>
                  <td style={{verticalAlign:"middle", borderBottom:"2px solid #f0f0f0"}}>
                    {((currentTenant === selectedTenant) && (row.caseid === selectedCase)) ? (
                      <div>&nbsp;</div>
                      ) : (
                        <Button kind="tertiary" onClick={this.openAsyncModal.bind(this, CT_ADD_AsyncAction.CODE.JS_DELETE_CASE, currentTenant, row.caseid, "")} size='sm'>delete Case</Button>
                        )}
                  </td>
                </tr>
            ))}
          </table>
          <table width = "100%" >
            <tr style={{backgroundColor:"#f0f0f0", height:"3px"}}>
                <td width = "2%">
                  <div></div>
                </td>
                <td width = "6%">
                <div></div>
                </td>
                <td width = "30%"  style={{verticalAlign:"top", align:"left", paddingLeft:"2px", paddingTop:"6px", paddingBottom:"6px", fontWeight:"bold"}}>
                <div></div>
                </td>
                <td width = "30%"  style={{verticalAlign:"top", align:"left", paddingLeft:"2px", paddingTop:"6px", paddingBottom:"6px", fontWeight:"bold"}}>
                <div></div>
                </td>
                <td width = "17%"  style={{verticalAlign:"top", align:"left", paddingLeft:"2px", paddingTop:"6px", paddingBottom:"6px", fontWeight:"bold"}}>
                <div></div>
                </td>
                <td width = "17%"  style={{verticalAlign:"top", align:"left", paddingLeft:"2px", paddingTop:"6px", paddingBottom:"6px", fontWeight:"bold"}}>
                <div> 
                  {(currentTenant === selectedTenant) ? (
                    <div>&nbsp;</div>
                    ) : (
                    <Button kind="primary" onClick={this.openAsyncModal.bind(this, CT_ADD_AsyncAction.CODE.JS_DELETE_TENANT, currentTenant, "", "" )} size='sm'>delete OrgUnit</Button>
                  )}
                </div>
                </td>
              </tr>
            </table>    
          


        </div>
      );
    }

    if (modalShowUi  && asyncProcessDialogsModal.active === false) {
      return (
        <div>
          <Modal
          modalHeading="Create"
          open
          primaryButtonText="Save"
          secondaryButtonText="Cancel"
          onRequestSubmit={e => this.handleModalClose("")}
          onSecondarySubmit={e => this.handleModalClose(null)}
          >
          <TextInput id="caseid" labelText="Case ID:" onChange={this.handleModalFormChange.bind(this)}/>
          <ServerSyncCallResultDisplayUi 
            resultToDisplay = {modalDialogError}
          />
          </Modal> 
        </div>
      );
    }

/*      
 * ------------------------------------------------------------
 *  Code supporting asyncProcessDialogsModal =====>
 * ------------------------------------------------------------
 */ 
    if (asyncProcessDialogsModal.active === true) {
      return (
        <div>
          <AsyncProcessDialogs
            asyncProcessDialogsModal = {this.state.asyncProcessDialogsModal}
            functionCloseAsyncModal = {this.closeAsyncModal.bind(this)}
           />
        </div>
      );
    }
    
/*      
 * ------------------------------------------------------------
 *  <===== Code supporting asyncProcessDialogsModal
 * ------------------------------------------------------------
 */ 


    return (
      <div>
        <p>Error raised</p>
      </div>
    );
  }

/*      
 * ------------------------------------------------------------
 *  Code supporting asyncProcessDialogsModal =====>
 * ------------------------------------------------------------
 */ 
async openAsyncModal(asyncAction, tenant, caseid, asyncParameter) {
//  alert(asyncAction + " : [" + tenant + "] / [" + caseid + "]");

  let asyncActionName = CT_ADD_AsyncAction.getTextForCode(asyncAction);
  this.setState({ asyncProcessDialogsModal: {...this.state.asyncProcessDialogsModal, 
    active: true,
    tenant: tenant,
    caseid: caseid,
    code: asyncAction, //see Codetable CT_ADD_AsyncAction
    name : asyncActionName,
    parameter : asyncParameter, 
    } 
  }); 

}

async closeAsyncModal() {

  //Specifics for this Component.PRE: a tenant delete must be finalized with a sync call to clean up preqsys_process
  let currentAction = this.state.asyncProcessDialogsModal.code;
  if (CT_ADD_AsyncAction.CODE.JS_DELETE_TENANT === currentAction) {
    await restClient.deleteTenantFinally(this.state.asyncProcessDialogsModal.tenant);
  }  


  //Standard Code supporting asyncProcessDialogsModal =====>
  this.setState({ asyncProcessDialogsModal: {...this.state.asyncProcessDialogsModal, 
    active: false,
    tenant: null,
    caseid: null,
    code: null,
    name : null,
	parameter : null, 

    } 
  });
  //<===== Standard Code supporting asyncProcessDialogsModal 


  //Specifics for this Component.POST: Update of Master Component
  this.props.uiShowUpdatedList();

}

/*      
 * ------------------------------------------------------------
 *  <===== Code supporting asyncProcessDialogsModal
 * ------------------------------------------------------------
 */ 

  handleSelectCase(row) {
    environmentService.log('', 'TenantCaseListUi.handleSelectCase')
    environmentService.log('', row)
    this.props.uiCaseChange(row.caseid);
    this.props.uiTenantChange(this.props.row.name); //tenant name from accordion
    sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_ORG_UNIT, this.props.row.name);
    sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_CASE, row.caseid);
  }

  handleDeSelectCase(row) {
    environmentService.log('', 'TenantCaseListUi.handleDeSelectCase')
    environmentService.log('', row)
    this.props.uiCaseChange("");
    this.props.uiTenantChange(""); 
    sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_ORG_UNIT, null);
    sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_CASE, null);

  }

}

export default TenantCaseListUi;
