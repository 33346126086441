export const CODE = {
  UNDEFINED : "",
  JS_PHASE1_CONFIRM : "CONFIRM",
  JS_PHASE2_RUN : "RUN",
  JS_PHASE3_RESULT : "RESULT",
}


export function getTextForCode(code) {
  switch (code) {

  case "CONFIRM": return "Confirm to perform Action";
  case "RUN": return "Running Action";
  case "RESULT": return "Action Result";
  
    default:
      return "";
  }
}

