import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'
import XHR from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector'


i18n
    .use(Backend)
    .use(XHR)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        lng: 'en',
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })
export default i18n