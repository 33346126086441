import React, { Component } from "react";

import * as ExceptionsRetrievalClient from "./../backend/ExceptionsRetrievalClient";
import * as HealthStatusRetrievalClient from "./../../commonxV3_001/backend/HealthStatusRetrievalClient";
import AllPreqXceptionsEntry from "./AllPreqXceptionsEntry";
import { Button } from '@carbon/react';
import { IconContext } from "react-icons";
import { BsExclamationDiamondFill, BsExclamationTriangleFill } from "react-icons/bs";


class AllPreqXceptions extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        exceptions : null,
        amount : '100',
        cutoff : '-1',
        timestamp : null
      };
  }


  async componentDidMount() {
    this.doCheckForPreQExceptions();
//    const intervalId = setInterval(() => {
//      this.doCheckForPreQExceptions();
//    }, 5000);  
  }

  /**
   * This method checks each second, if there are PreQExceptions available within Session Store
   */
  async doCheckForPreQExceptions() {
    let healthResult = await HealthStatusRetrievalClient.getHealthStatus();
    let otherResult = await ExceptionsRetrievalClient.getExceptions(this.state.amount);
    let toAddExceptions = otherResult.preQExceptions;

    let result = healthResult;
    for (let index = 0; index < toAddExceptions.length; index++) {
      result.preQExceptions.push(toAddExceptions[index]);
    }
    var now = new Date();
    this.setState({ exceptions : result, timestamp : now });
  }

  async refresh() {
    await this.doCheckForPreQExceptions();
  }

  async deleteByTimeStampAndRefresh(timestamp) {
    let type = "entry";
    let id = timestamp;
    await ExceptionsRetrievalClient.deleteExceptions(type, id);
    await this.doCheckForPreQExceptions();
  }

  async deleteByErrorCodeAndRefresh(errorCode) {
    let type = "category_errorcode";
    let id = errorCode;
    await ExceptionsRetrievalClient.deleteExceptions(type, id);
    await this.doCheckForPreQExceptions();
  }
    
  async decreaseAmount() {

  }

  render() {

    const { exceptions } = this.state;
    let className = 'preQ_PreQException_background_wama';


    if ((exceptions !== null) && (exceptions.preQExceptions.length > 0)) {

      return (
        <div className={className}>
          <p>
            <Button kind="ghost" onClick={this.refresh.bind(this)}>refresh</Button>
          </p>  
        <br />
              <table width = "100%" >
                {exceptions.preQExceptions.map((preQException) => (
                  <AllPreqXceptionsEntry
                    preQException = {preQException}
                    deleteByTimeStampAndRefresh = {this.deleteByTimeStampAndRefresh.bind(this)}
                    deleteByErrorCodeAndRefresh = {this.deleteByErrorCodeAndRefresh.bind(this)}
                  />
                ))}
    
              </table>
              <br />  
        </div>
      );

    } else {

      return (
        <div className={className}>
          <p>
            <p>
              <Button kind="ghost" onClick={this.refresh.bind(this)}>refresh</Button>
            </p>  
            <br/>
            &nbsp;&nbsp;&nbsp;No Entries
            <br/>
            &nbsp;
          </p>
        </div>
      );

    }

  }
}

export default AllPreqXceptions;
