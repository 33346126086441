import React, { Component } from "react";

import DevelopmentTag from "./../../common/DevelopmentTag";
import ReactJson from "react-json-view-with-toggle";
import { produce } from 'immer'
import {
  Grid, Row, Column, TextInput
} from 'carbon-components-react';
import { BiReset, BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
import { IconContext } from "react-icons";


class Parameter100 extends Component {
  constructor(props) {
    super(props);
  }



  render() {


    //***************************************************************************
    // Ui Properties Definition starts here
    //*************************************************************************** 

    const ui_Props_TextInput_Permissions = {
      className: 'some-class',
      id: 'Permissions',
      labelText: '',
      placeholder: 'Please Enter or leave empty',
   };

   const ui_Props_TextInput_Owner = {
    className: 'some-class',
    id: 'Owner',
    labelText: '',
    placeholder: 'Please Enter or leave empty',
  };


    //***************************************************************************
    // render Ui
    //*************************************************************************** 


    let {sequenceindex}  = this.props;

    if (sequenceindex === 100) {
      let entry = this.props.getDisplayListEntry(100);
      let objParams = JSON.parse(entry.selectedUiParameters);
      return (
        <div>
          <DevelopmentTag uiParams = {this.props.uiParams} devTagLabel = {this.constructor.name}/>
          <div className="preQ_SME_label"><br /></div>

          <table  width="100%">
            <tr>
              <td width="3%">
                <div onClick={this.handleResetParameters.bind(this, 100)}>
                <IconContext.Provider value={{className:"preQ_SME_icon_process_status_JS_PROCESS_DONE"}}>
                    <BiReset size="22"/>
                </IconContext.Provider>
                 </div>
              </td>
              <td width="97%">
                &nbsp;
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  &nbsp;
                 </div>
              </td>
              <td>
              {this.props.uiParams.showDevTags ? (
                <div className="preQ_SME_label">
                  entry.selectedUiParameters<br/><br/>
                  <ReactJson 
                    src={JSON.parse(entry.selectedUiParameters)} 
                    collapsed={false} 
                    enableClipboard={false}
                    name = {false}
                    />
                </div>
              ) : (
                <div>&nbsp;</div>
              )}
              </td>
            </tr>
          </table>

          <Grid>
              <Row>
                <Column>
                  <div className="preQ_SME_label">
                  Remove MacOSArtifacts
                  </div>
                </Column>
                <Column>
                  <div onClick={this.handleCheckBoxToggled.bind(this, 1)}>
                  {objParams.removeMacOSArtifacts ? ( <BiCheckboxChecked size="20"/> ) : ( <BiCheckbox size="20"/> )}
                  </div>
                </Column>
                <Column>
                  <div className="preQ_SME_label">
                  Remove SymbolicLinks
                  </div>
                </Column>
                <Column>
                  <div onClick={this.handleCheckBoxToggled.bind(this, 2)}>
                  {objParams.removeSymbolicLinks ? ( <BiCheckboxChecked size="20"/> ) : ( <BiCheckbox size="20"/> )}
                  </div>
                </Column>
              </Row>
              <Row>
                <Column>
                    <div className="preQ_SME_label">
                    Permissions to stamp in
                    </div>
                    <TextInput
                      value={objParams.stampInPermissions} 
                      onChange={this.handleChange.bind(this)} 
                      {...ui_Props_TextInput_Permissions} 
                   />
                </Column>
              </Row>
              <Row>
                <Column>
                    <div className="preQ_SME_label">
                    Owner to stamp in
                    </div>
                    <TextInput
                      value={objParams.stampInOwner} 
                      onChange={this.handleChange.bind(this)} 
                      {...ui_Props_TextInput_Owner} 
                   />
                </Column>
              </Row>

          </Grid>

          <div className="preQ_SME_label">
          <br /><hr />
          </div>
        </div>
      );
    }

    return (
      <div></div>
    );
  
  }


  //*******************************/
  //* Icon Touch Handler
  //*******************************/
  handleCheckBoxToggled(id) {
    let {sequenceindex}  = this.props;
    let entry = this.props.getDisplayListEntry(100);

    let objParams = JSON.parse(entry.selectedUiParameters);
    let newParam = null;

    if (id === 1) {
      newParam = produce(objParams, draft => {
        draft.removeMacOSArtifacts = objParams.removeMacOSArtifacts ? false : true;
      })
    }
    if (id === 2) {
      newParam = produce(objParams, draft => {
        draft.removeSymbolicLinks = objParams.removeSymbolicLinks ? false : true;
      })
    }

    let sParam = JSON.stringify(newParam);
    this.props.updateUserParameter(sequenceindex, sParam);
  }

  //*******************************/
  //* Text Field change Handler
  //*******************************/
  handleChange(event) {

    let entry = this.props.getDisplayListEntry(100);
    let fieldName = event.target.id;
    let fieldVal = event.target.value;

    let {sequenceindex}  = this.props;
    let objParams = JSON.parse(entry.selectedUiParameters);
    let newParam = null;

    if (fieldName === "Permissions") {
      newParam = produce(objParams, draft => {
        draft.stampInPermissions = fieldVal;
      })
    }

    if (fieldName === "Owner") {
      newParam = produce(objParams, draft => {
        draft.stampInOwner  = fieldVal;
      })
    }

    let sParam = JSON.stringify(newParam);
    this.props.updateUserParameter(sequenceindex, sParam);

  }    

  //*******************************/
  //* Parameter Reset Handler
  //*******************************/
  handleResetParameters(sequenceIndex) {
    this.props.resetUserParameter(sequenceIndex);
  }

}

export default Parameter100;
