import React, { Component } from "react";
import { Button, FormGroup, Stack, TextInput, PasswordInput, Form } from "@carbon/react";
import { ArrowRight } from '@carbon/react/icons';
import i18next from "i18next"
import * as PreQV3Constants from "./../../modules/commonxV3_001/PreQV3Constants";


// Gneral Focus Hook
const utilizeFocus = () => {
	const ref = React.createRef()
	const setFocus = () => {ref.current &&  ref.current.focus()}

	return {setFocus, ref} 
}

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.inputFocus = utilizeFocus()
       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  componentDidMount(){
    this.inputFocus.setFocus()
  }

  handleKeyPress(e) {
    if (e.charCode === 13) { // Enter ??
      this.handleLogInEnter();
    }
  }

  render() {

    const {modalForm} = this.props;

    let indWarn = modalForm.loginFailed;


    return (
      <div className="login-position background-img fullscreen">
      <h1 className="padding-08">{i18next.t("app.title-short")}</h1>
      <p>{i18next.t("app.subtitle")}</p>
                 {this.props.indSMEModus 
                  ?<div><br/>&nbsp;&nbsp;&nbsp;- SME Modus -<br/><br/></div>
                  :<div></div>}
      <Form>
        <FormGroup
            legendId="form-group-1"
            style={{
                maxWidth: '400px'
            }}
        >
            {indWarn
            ?
            <Stack gap={7}>
                <TextInput
                id="user"
                labelText={i18next.t("LoginForm.TextInput.labelText")}
                placeholder={i18next.t("LoginForm.TextInput.placeholder")}
                onChange={this.handleLogInFormChange.bind(this)}
                onKeyPress={(e) => this.handleKeyPress(e)}
                warn={true}
                />
                <PasswordInput
                id="password"
                labelText={i18next.t("LoginForm.PasswordInput.labelText")}
                placeholder={i18next.t("LoginForm.PasswordInput.placeholder")}
                onChange={this.handleLogInFormChange.bind(this)}
                onKeyPress={(e) => this.handleKeyPress(e)}
                warn={true}
                warnText = {i18next.t("LoginForm.warn.warnText")}
                />
                <Button ref={this.inputFocus.ref} onClick={this.handleLogInEnter.bind(this)} className="full-width"renderIcon={ArrowRight} iconDescription="Add" >Login</Button>
            </Stack>
            :
            <Stack gap={7}>
                <TextInput
                id="user"
                labelText={i18next.t("LoginForm.TextInput.labelText")}
                placeholder={i18next.t("LoginForm.TextInput.placeholder")}
                onChange={this.handleLogInFormChange.bind(this)}
                onKeyPress={(e) => this.handleKeyPress(e)}

                />
                
                <PasswordInput
                id="password"
                labelText={i18next.t("LoginForm.PasswordInput.labelText")}
                placeholder={i18next.t("LoginForm.PasswordInput.placeholder")}
                onChange={this.handleLogInFormChange.bind(this)}
                onKeyPress={(e) => this.handleKeyPress(e)}

                />
                <Button ref={this.inputFocus.ref} onClick={this.handleLogInEnter.bind(this)} className="full-width"renderIcon={ArrowRight} iconDescription="Add" >{i18next.t("LoginForm.button.text")}</Button>
            </Stack>
            }

        </FormGroup>
      </Form>
  </div>
  );
  }
  
  
  /**
   * This method reacts on changes from Modal Dialog Fields
   * @param {*} event 
   */
    async handleLogInFormChange(event) {
      await this.props.handleLogInFormChange(event);
    }
  
    async handleLogInEnter(event) {
	  await this.props.handleLogInEnter(event);
	}

}



export default LoginForm;
