import React, { Component } from "react";

import { FormItem, FileUploaderDropContainer, Button, InlineLoading} from "@carbon/react";
import {
  Grid, Row, Column
} from 'carbon-components-react';
import { BiReset, BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
import * as restFileUploadClient from "./../../commonxV3_001/backend/RestFileUploadClient"
import * as serverResponsePojoIdentifier from "./../../commonxV3_001/ServerResponsePojoIdentifier"

import * as CT_ADD_AsyncAction from "./../../commonxV3_001/codetable/CT_ADD_AsyncAction";
import * as PreQV3Constants from "./../../commonxV3_001/PreQV3Constants";
import AsyncProcessDialogs from "./../../commonxV3_001/app/AsyncProcessDialogs";
import { Checkbox } from "carbon-components-react";
import * as environmentService from "./../../EnvironmentService";



class Parameter6200Import extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        droppedFiles: [],       //list of dropped file(s) 
        addResult: null,        //result of dropped file(s) check
        showFileActions: false, //whe this flag is set, further file handling actions are visualized

        loadingVisible: false,
        error:null,
        errorMessageVisible:false,
        fileCheckError: null,
        fileCheckMessageVisible: false,

        validatedUpload : null,
        processStepDoImportSelected : false,
        asyncProcessId : null,
        /* 
        * ------------------------------------------------------------
        * Code supporting asyncProcessDialogsModal =====>
        * ------------------------------------------------------------
        */ 
        asyncProcessDialogsModal: {
          active: false,
          tenant: null,
          caseid: null,
          code: null, //see Codetable CT_ADD_AsyncAction
          name : null, //clear text for asyncAction (retrieved from Codetable CT_ADD_AsyncAction) 
          parameter : null,
        },
        /* 
        * ------------------------------------------------------------
        * <===== Code supporting asyncProcessDialogsModal
        * ------------------------------------------------------------
        */ 

      };
  }




  render() {

    const {droppedFiles, addResult, showFileActions, loadingVisible, asyncProcessDialogsModal, fileCheckError, fileCheckMessageVisible, validatedUpload, processStepDoImportSelected, asyncProcessId} = this.state;
    let showFiles = (droppedFiles.length === 1);

    let showReviewSuccess = false;
    if ((fileCheckMessageVisible === true) && (fileCheckError === null)) {
      showReviewSuccess = true;
    }
    let showReviewFalure= false;
    if ((fileCheckMessageVisible === true) && (fileCheckError !== null)) {
      showReviewFalure = true;
    }

    let isUploadValidated = (validatedUpload !== null);
    let isProcessStepDoImportSelected = (processStepDoImportSelected === true);
    let isAsyncProcessIdAvialable = (asyncProcessId !== null);

    let logLink = null;
    let logFileName = null;
    if (isAsyncProcessIdAvialable) {
      logLink = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
      + "/RESTServiceFileDownLoad/Tenant/preq/sbv3/service/analysis/support/processlog/"
      + asyncProcessId;
      logFileName = asyncProcessId + ".log";
    }


    /* 
    * ------------------------------------------------------------
    * Code supporting asyncProcessDialogsModal =====>
    * ------------------------------------------------------------
    */ 
    if (asyncProcessDialogsModal.active === true) {
      return (
      <div>
        <AsyncProcessDialogs
          asyncProcessDialogsModal = {this.state.asyncProcessDialogsModal}
          functionCloseAsyncModal = {this.closeAsyncModal.bind(this)}           //when closed in 1st screen
          callBackHandleSuccess = {this.handleSucessDataFromServer.bind(this)}  //when process has done with success
          callBackHandleFailure = {this.handleFailureDataFromServer.bind(this)} //when process has failed
        />
      </div>
      );
    }/* 
    * ------------------------------------------------------------
    * <===== Code supporting asyncProcessDialogsModal
    * ------------------------------------------------------------
    */ 

    if (loadingVisible) {
      return (
        <div>
          <h6>Upload Review Result </h6> 
          <br/>
          <p>Uploading</p>
          <InlineLoading/>
        </div>
      );    
    }

    return (
      <div>
        <h6>Upload Review Result </h6> 
        <br/>
        <table width = "100%">
          <tr>
            <td width = "18%">
            <FormItem>
            <FileUploaderDropContainer
                innerRef={{
                  current: '[Circular]'
                }}
                labelText='1 .csv (or .zip)'
                justify-content="center"
                multiple
                name=""
                onAddFiles={(e) => {
                  let type = e.type;
                  let files = null;
                  if (type === 'drop') {
                    files = e.dataTransfer.files;
                  } else {
                    files = e.target.files;
                  }
                  this.onDrop(files);
				        }}
                onChange={function noRefCheck(){}}
                role=""
            />              
            </FormItem>
            </td>
            <td width = "82%">

            {showFiles === true
            ? <div className="preQ_SME_label">&nbsp;&nbsp;&nbsp;{droppedFiles[0].name}</div>
            : <></>
            }

            {addResult !== null
            ? <>
                &nbsp;<br/>
                <h6>&nbsp;&nbsp;{addResult}</h6>
              </>
            : <></>}

            </td>
          </tr>
        </table>

        <table width = "100%">
          <tr>
            <td width = "20%">
              &nbsp;
            </td>
            <td width = "80%">

            {showFileActions === true
            ?<>
                <br/>
                <h6>Next Step:</h6>
                <br/>
                <Button kind="tertiary" onClick={this.fileSubmit.bind(this, this.state.droppedFiles)}>
                    Submit Review File and Perform first Plausibility Check
                </Button>
            </>
            :<></> 
            }

            {showReviewSuccess === true
            ?<> 
              &nbsp;<br />&nbsp;<br />
              <h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;First plausibility check: OK</h6>
            </>
            :<></> 
            }

            {showReviewFalure === true
            ?<> 
              &nbsp;<br />&nbsp;<br />
              <h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;First plausibility check: FAILED</h6>
              {isAsyncProcessIdAvialable
               ? <>
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a
                    href={logLink}
                    download ={logFileName}
                    rel="noopener"
                    aria-label='.log'
                    >
                    .log
                    </a>
                  <br />
                 </>
               : <></>
              }
            </>
            :<></> 
            }



            </td>
          </tr>
        </table>


        {isUploadValidated
        ? <>
        <hr/> 
          <table width = "100%">
            <tr>
            <td width = "20%">
              &nbsp;
            </td>
            <td width = "80%">
            <Grid>
            <Row>
              <Column>
                &nbsp;<br/>
                Update Matches
                &nbsp;<br/>
              </Column>
              <Column>
                &nbsp;<br/>
                <div onClick={this.handleChangeOfStepParm6200.bind(this)}>
                  { isProcessStepDoImportSelected 
                    ? <BiCheckboxChecked size="20"/>
                    : <BiCheckbox size="20"/>
                  }
                </div>
              </Column>
            </Row>
          </Grid>
            </td>
            </tr>
          </table>
        </>
        : <></>}    

      </div>
    );
    
  }


  async handleChangeOfStepParm6200() {
    let {sequenceindex}  = this.props;
    let {validatedUpload, processStepDoImportSelected} = this.state;

    let newValue = true;
    if (processStepDoImportSelected === true) {
      newValue = false;
    }
    this.setState(
      {
        processStepDoImportSelected: newValue,
      }
    )

    let sParam = validatedUpload;
    this.props.updateUserParameter(sequenceindex, sParam);
    environmentService.log('', '');
  }


  onDrop = (droppedFiles) => {

    if (droppedFiles.length !== 1) {
      this.setState({
        droppedFiles: [],
        addResult:'!!! Only one file is allowed !!!',
        showFileActions: false
      });
      return;
    }


    let indAllowedFileType = false; //changed to 'true' to allow all file types
    let allowedExtensions = [".csv", ".zip"];
    for (let index = 0; index < droppedFiles.length; index++) {

      const fileCandidate = droppedFiles[index];

      //check, if an allowed file-extension is detected ...
      for (let j= 0;j < allowedExtensions.length;j++) {
        const allowedExtension = allowedExtensions[j];
        let s1 = fileCandidate.name.toLowerCase()
        let s2 = allowedExtension.toLowerCase();
        environmentService.log('', s1);
        environmentService.log('', s2);
        if (s1.endsWith(s2)) {
          indAllowedFileType = true;
        }
      }

      //... if not: raise an error
      if (indAllowedFileType === false) {
        this.setState({
          droppedFiles: [],
          addResult:'!!! Invalid File Type !!!',
          showFileActions: false,
          fileCheckError: null,
          fileCheckMessageVisible: false,
          validatedUpload: null,
          asyncProcessId : null,
        });
        return;
        }

      this.setState({
        droppedFiles:droppedFiles,
        addResult:'Selection = OK',
        showFileActions: true,
        fileCheckError: null,
        fileCheckMessageVisible: false,
        validatedUpload: null,
        asyncProcessId : null,
      });
      
        
    }
  }  



  /**
   * Button "Check Review Result"
   * @param {*} droppedFiles 
   * @returns 
   */
  async fileSubmit(droppedFiles) {
    this.setState({
      loadingVisible: true,
    });

    let data_FromServerFileUpload = await restFileUploadClient.submitOneFile(droppedFiles);
    let indErrorFileUpload = serverResponsePojoIdentifier.isPojoErrorResult(data_FromServerFileUpload);
    if (indErrorFileUpload === true) {
      this.setState({
        loadingVisible: false,
        error:data_FromServerFileUpload,
        errorMessageVisible:true,
        fileCheckError: null,
        fileCheckMessageVisible: false,
      });
      return;
    } else {
      let uploadID = data_FromServerFileUpload.uploadID;
      this.checkReviewResult(uploadID);

    }

  }

  async checkReviewResult(uploadID) {
    let tenant = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_ORG_UNIT);
    let caseid = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_CASE);
    let asyncAction = CT_ADD_AsyncAction.CODE. JS_CHECK_REVIEW_FILE_IMPORT;
    let sParam = '{"uploadid": "' + uploadID + '"}';
    this.openAsyncModal(asyncAction, tenant, caseid, sParam);
  }

  async handleSucessDataFromServer(data_FromServer_CheckReview) {

    let asyncProcessId = null;
    try {
      asyncProcessId = data_FromServer_CheckReview.status.processid;
    } catch (error) {
      asyncProcessId = null;
    }

    const {asyncProcessDialogsModal} = this.state;
    this.setState({
        loadingVisible: false,
        error:null,
        errorMessageVisible:false,
        fileCheckError: null,
        fileCheckMessageVisible: true,
        validatedUpload : asyncProcessDialogsModal.parameter,
        asyncProcessId : asyncProcessId,
    });

  } 
  async handleFailureDataFromServer(failure_FromServer_CheckReview) {

    let asyncProcessId = null;
    try {
      asyncProcessId = asyncProcessId = failure_FromServer_CheckReview.status.processid;
    } catch (error) {
      asyncProcessId = null;
    }

    this.setState({
      loadingVisible: false,
      error:null,
      errorMessageVisible:false,
      fileCheckError: failure_FromServer_CheckReview,
      fileCheckMessageVisible: true,
      validatedUpload : null,
      asyncProcessId : asyncProcessId,
    });
  } 

  /* 
  * ------------------------------------------------------------
  * Code supporting asyncProcessDialogsModal =====>
  * ------------------------------------------------------------
  */ 
  async openAsyncModal(asyncAction, tenant, caseid, asyncParameter) {

    let asyncActionName = CT_ADD_AsyncAction.getTextForCode(asyncAction);
    this.setState({ asyncProcessDialogsModal: {...this.state.asyncProcessDialogsModal, 
      active: true,
      tenant: tenant,
      caseid: caseid,
      code: asyncAction, //see Codetable CT_ADD_AsyncAction
      name : asyncActionName,
      parameter : asyncParameter,
      } 
    }); 
  }
  async closeAsyncModal() {
    //Specifics for this Component.PRE: a tenant delete must be finalized with a sync call to clean up preqsys_process
    // ... none
    //Standard Code supporting asyncProcessDialogsModal =====>
    this.setState({ asyncProcessDialogsModal: {...this.state.asyncProcessDialogsModal, 
      active: false,
      tenant: null,
      caseid: null,
      code: null,
      name : null,
      parameter : null,
      },
      loadingVisible: false,
    });

    //<===== Standard Code supporting asyncProcessDialogsModal 
    //Specifics for this Component.POST: Update List based on performed changes
    // ... none
  }
  /* 
  * ------------------------------------------------------------
  * <===== Code supporting asyncProcessDialogsModal
  * ------------------------------------------------------------
  */ 


}


export default Parameter6200Import;
