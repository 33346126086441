import React, { Component } from "react";
import { Button, InlineLoading } from "carbon-components-react";
import Dropzone from 'react-dropzone'
import * as Facade from "./../../Facade";
import DevelopmentTag from "./../../common/DevelopmentTag";
import ReactJson from "react-json-view-with-toggle";
import * as environmentService from "./../../EnvironmentService";
import * as restFileUploadClient from "./../../commonxV3_001/backend/RestFileUploadClient"
import * as serverResponsePojoIdentifier from "./../../commonxV3_001/ServerResponsePojoIdentifier"
import * as AnonyizersRestClient from "./../../anonymizersV3_001/backend/AnonyizersRestClient";
import * as PreQV3Constants from "./../../commonxV3_001/PreQV3Constants";
import Parameter5100FileInfo from "./Parameter5100FileInfo";

const HttpCodes = {
  OK : 200,
  BAD_REQUEST : 400,
  INTERNAL_SERVER_ERROR : 500,

}

class Parameter5100 extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        acceptedFiles: [],
        uploadDetails: null,
        fileInfoTree: null,
        error:null,
        fileUploading:false
      };
  }

  
  render() {
    let {sequenceindex, entry}  = this.props;
    if (sequenceindex === 5100) {

      const {fileUploading, acceptedFiles, uploadDetails, error} = this.state;

      let shwoUploadButton = (acceptedFiles.length === 1) && (fileUploading === false)

      return (

        <div>
          <br/>
          &nbsp;
          <br/>
          <Parameter5100FileInfo
          fileInfoTree = {this.state.fileInfoTree} />
          <br/>
          &nbsp;
          <br/>
          &nbsp;
          <br/>
          <div className="preQ_SME_fileDropZone">
              <Dropzone onDrop={this.onDrop}>
                {({getRootProps, getInputProps, isDragActive}) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? "..." : 'Click me or drag ONE file (.csv or .zip) as StoredIQ-Result!'}
                  </div>
                )}
              </Dropzone>          
              <br />
              <ul>
                {acceptedFiles.length > 0 && acceptedFiles.map(acceptedFile => (
                  <li className="list-group-item list-group-item-success">
                  {acceptedFile.name}
                  </li>
                ))}
            </ul>
            <br/>
            <br/>
            {
              shwoUploadButton === true
              ? <Button kind="tertiary" onClick={this.processUpload.bind(this, this.state.acceptedFiles)} size='sm'>Upload</Button>
              : <div></div>
            }
            {
              fileUploading === true
              ? <InlineLoading description="File uploading ..." /> 
              : <></>
            }            
          </div>
          <br />

        </div>
  
      );
      
    }

    return (
      <div></div>
    );
  
  }

  async processUpload(acceptedFiles) {
    await this.fileSubmit(acceptedFiles);
    await this.captureImportFileInfo();
  }

  async captureImportFileInfo() {
    let fileInfoTree = await AnonyizersRestClient.getStoredfIQImportFileInfo();
    this.setState({
      fileInfoTree:fileInfoTree
    });
  }

  async fileSubmit(acceptedFiles) {

    this.setState({
      fileUploading:true
    });
    let data_FromServer = await restFileUploadClient.submitOneFile(acceptedFiles);
    this.setState({
      fileUploading:false,
      acceptedFiles: []
    });
    let indError = serverResponsePojoIdentifier.isPojoErrorResult(data_FromServer);
    if (indError === true) {
      this.setState({
        uploadDetails:null, error:data_FromServer
      });
    } else {
      environmentService.log('', '');
      let uploadID = data_FromServer.uploadID;
      let orgUnit = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_ORG_UNIT);
      let caseid = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_CASE);
      let data_FromServer2 = await AnonyizersRestClient.checkInFileUploadToStoredIqResultDirectory(orgUnit, caseid, uploadID);
      let indError2 = serverResponsePojoIdentifier.isPojoErrorResult(data_FromServer2);
      if (indError2 === true) {
        this.setState({
          uploadDetails:null, error:data_FromServer2
        });
      } else {
        this.setState({
          uploadDetails:data_FromServer2, error:null
        });
      }
    }
  }



  onDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 1) {
      environmentService.log('', acceptedFiles);

      this.setState({
        acceptedFiles:acceptedFiles, uploadDetails:null, error:null
      });
  
    }

  }
}

export default Parameter5100;
