import React, { Component } from "react";

import DevelopmentTag from "../../common/DevelopmentTag";
import ReactJson from "react-json-view-with-toggle";

class Parameter9750 extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  
  render() {
    let {sequenceindex, entry}  = this.props;
    if (sequenceindex === 9750) {

      return (
        <div className="preQ_SME_label">
          <br />No Parameter(s)<br /><br />
          <hr />
        </div>
      );
      
    }

    return (
      <div></div>
    );
  
  }


}

export default Parameter9750;
