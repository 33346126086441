import React, { Component } from "react";
import { Theme } from '@carbon/react';
import * as HealthStatusRetrievalClient from "./../../modules/commonxV3_001/backend/HealthStatusRetrievalClient";
import PreQExceptionHandlerEntry from "./PreQExceptionHandlerEntry";
import { IoCaretDown, IoCaretForward } from "react-icons/io5";
import { IconContext } from "react-icons";
import { BsExclamationDiamondFill, BsExclamationTriangleFill } from "react-icons/bs";



class PreQExceptionHandler extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        exceptions : null,
        exceptionUiIsOpen : false
      };
  }

  async componentDidMount() {
    this.doCheckForPreQExceptions();
    const intervalId = setInterval(() => {
      this.doCheckForPreQExceptions();
    }, 30000);  
  }

  /**
   * This method checks each second, if there are PreQExceptions available within Session Store
   */
  async doCheckForPreQExceptions() {
    let result = await HealthStatusRetrievalClient.getHealthStatus();
//    let objExceptions = PreQV3Constants.getPreQExceptionList();
    this.setState({ exceptions : result });
  }

  /*
   * Open / Close Excpetion List
   */
  toggleExcpetionListIsOpen() {
    let ind = this.state.exceptionUiIsOpen;
    if (ind === false) {
      this.setState({exceptionUiIsOpen: true});
    } else {
      this.setState({exceptionUiIsOpen: false});
    }
  }
    
  
  render() {


    const { exceptions, exceptionUiIsOpen } = this.state;
    const {style} = this.props;

    let className = 'preQ_PreQException_background_wama';

    if ((exceptions !== null) && (exceptions.preQExceptions.length > 0)) {

      if (exceptionUiIsOpen === true) {

        return (
          <div>
              <p className={className}>
              <br />
              <table width = "100%" >
  
                <tr>
                    <td style={{verticalAlign:"top", paddingLeft:"0px", paddingTop:"3px", borderBottom:"2px", fontSize:"80%"}}>
                      <div>
                        <IconContext.Provider value={{className:"preQ_PreQException_icon_CARET_sme"}}>
                          <IoCaretDown size="24" onClick={this.toggleExcpetionListIsOpen.bind(this)}/>
                        </IconContext.Provider>
                        <IconContext.Provider value={{className:"preQ_PreQException_icon_CRITICAL_sme"}}>
                          <BsExclamationDiamondFill size="24"/>
                        </IconContext.Provider>
                        <IconContext.Provider value={{className:"preQ_PreQException_icon_UNCRITICAL_sme"}}>
                          <BsExclamationTriangleFill size="24"/>
                        </IconContext.Provider>
                      </div>
                    </td>
                  </tr>             
    
    
                {exceptions.preQExceptions.map((preQException) => (
                  <PreQExceptionHandlerEntry
                    preQException = {preQException}
                    style={this.props.style}
                  />
                ))}
    
              </table>
              <br />  
              </p>
          </div>
        );
  
      } else {

        return (
          <div>
              <p className={className}>
              <br />
              <table width = "100%" >
  
                <tr>
                    <td style={{verticalAlign:"top", paddingLeft:"0px", paddingTop:"3px", borderBottom:"2px", fontSize:"80%"}}>
                      <div>
                      <IconContext.Provider value={{className:"preQ_PreQException_icon_CARET_sme"}}>
                          <IoCaretForward size="24" onClick={this.toggleExcpetionListIsOpen.bind(this)}/>
                        </IconContext.Provider>
                        <IconContext.Provider value={{className:"preQ_PreQException_icon_CRITICAL_sme"}}>
                          <BsExclamationDiamondFill size="24"/>
                        </IconContext.Provider>
                        <IconContext.Provider value={{className:"preQ_PreQException_icon_UNCRITICAL_sme"}}>
                          <BsExclamationTriangleFill size="24"/>
                        </IconContext.Provider>
                      </div>
                    </td>
                  </tr>             
  
              </table>
              <br />  
              </p>            
          </div>
        );

      }
      
    } else {

      return (
        <div>
          <></>
        </div>
      );

    }
  }
}

export default PreQExceptionHandler;
