import React, { Component } from "react";

import { IoWalk } from "react-icons/io5";
import { BiRun } from "react-icons/bi";
import { IconContext } from "react-icons";
import * as CT_ProcessingType from  ".//../../../commonxV3_001/codetable/CT_ProcessingType";
import * as CT_ADD_DisplayRightType from  "../../../commonxV3_001/codetable/CT_ADD_DisplayRightType";
import * as workOnCaseUtils from "./../../backend/WorkOnCaseUtils";
import * as EnvironmentService from "./../../../EnvironmentService";



class WorkOnCaseUiProcessingIcon extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {}

  render() {

    const { row, list, autoUpdate, displayRightType, displayRightList} = this.props;

    let indUiStartable = row.indUiStartable;
    let indUiLastStepInSequence = row.lastProcessStep;
    let indUiCurrentSelectedAsSequenced = row.indUiSelectedAsSequenced;

    let prevRow = workOnCaseUtils.getPreviousChainEntry(row, list);
    let indUiPrevSelectedAsSequenced = false;
    let prevRowBusinessIdentifier = null;
    if (prevRow !== null) {
      indUiPrevSelectedAsSequenced = prevRow.indUiSelectedAsSequenced;
      prevRowBusinessIdentifier = prevRow.businessIdentifier;
    }

    EnvironmentService.log('Current Row: ' + row.businessIdentifier + ", Previous Identifier: " + prevRowBusinessIdentifier + " , prevSelectedAsSequenced: " + indUiPrevSelectedAsSequenced);


    let selectSwitch = CT_ProcessingType.CODE.JS_SINGLE_STEP;
    if (indUiCurrentSelectedAsSequenced) {
      selectSwitch = CT_ProcessingType.CODE.JS_SEQUENCING;
    }

    if  (     (autoUpdate === true) 
          ||  (displayRightType === CT_ADD_DisplayRightType.CODE.MANUALLY_RESULT) 
        ){
      return (
        <div>
          &nbsp;
        </div>
      );
    }

    if ((indUiStartable !== true) && (indUiPrevSelectedAsSequenced !== true)){
      return (
        <div>
          &nbsp;
        </div>
      );
    } 

    //check for: Parameter is already listed on the right side ... in this case display ONLY the suitable icon without clickable action
    if    ((displayRightType === CT_ADD_DisplayRightType.CODE.MANUALLY_PARAMETER_SEQUENCE) 
       &&  (workOnCaseUtils.isEntryInDisplayRightParameterList(row, displayRightType, displayRightList))) {

        return (
          <div>
            <IconContext.Provider value={{className:"preQ_SME_icon_processing_JS_SEQUENCING"}}>
              <BiRun size="22"/>
            </IconContext.Provider>
          </div>
        );

      }
      if    ((displayRightType === CT_ADD_DisplayRightType.CODE.MANUALLY_PARAMETER_ONESTEP) 
      &&  (workOnCaseUtils.isEntryInDisplayRightParameterList(row, displayRightType, displayRightList))) {

       return (
         <div>
          {
            indUiLastStepInSequence
            ? <>&nbsp;</>
            :<IconContext.Provider value={{className:"preQ_SME_icon_processing_JS_SEQUENCING"}}>
               <IoWalk size="22"/>
            </IconContext.Provider>
          }
           
         </div>
       );

     }

    switch(selectSwitch) {


      case CT_ProcessingType.CODE.JS_SEQUENCING: 
      if ((indUiStartable === true) || (indUiPrevSelectedAsSequenced === true)) {
        return (
          <div  onClick={this.handleSwitchToSingleStepProcessing.bind(this, this.props.row)}>
            <IconContext.Provider value={{className:"preQ_SME_icon_processing_JS_SEQUENCING"}}>
              <BiRun size="22"/>
            </IconContext.Provider>
          </div>
        );
        }

      case CT_ProcessingType.CODE.JS_SINGLE_STEP: 
      if ((indUiStartable === true) || (indUiPrevSelectedAsSequenced === true)) {

          return (
            <>
            {
            indUiLastStepInSequence
            ?<div></div>
            :
            <div  onClick={this.handleSwitchToSequenceBasedProcessing.bind(this, this.props.row)}>
              <IconContext.Provider value={{className:"preQ_SME_icon_processing_JS_SINGLE_STEP"}}>
                <IoWalk size="22"/>
              </IconContext.Provider>
            </div>
            }
            </>
          );

        }

      default:  
        return (
          <div>&nbsp;</div>
        );

    }

   

  }

  //*******************************/
  //* Icon Touch Handler
  //*******************************/
  handleSwitchToSingleStepProcessing(row) {
      EnvironmentService.log("handleSwitchToSingleStepProcessing")
      EnvironmentService.log(row);
      this.props.handleProcessingChangedToSingleStep(this.props.row);
  }

  handleSwitchToSequenceBasedProcessing(row) {
    EnvironmentService.log("handleSwitchToSequenceBasedProcessing")
    EnvironmentService.log(row);
    this.props.handleProcessingChangedToSequencing(this.props.row);
  }

}

export default WorkOnCaseUiProcessingIcon;
