import React, { Component } from "react";

import { Button, Toggle, Loading, Accordion, AccordionItem, InlineLoading} from "carbon-components-react";
import * as restClient from "../../backend/RestClient";
import WorkOnCaseUiProcessingIcon from "./WorkOnCaseUiProcessingIcon"
import WorkOnCaseUiProcessStatusIcon from "./WorkOnCaseUiProcessStatusIcon"
import WorkOnProcessItemListUi from "./WorkOnProcessItemListUi";
import WorkOnCaseUiDisplayRightUi from "./WorkOnCaseUiDisplayRightUi";
import DevelopmentTag from "./../../../common/DevelopmentTag";
import DevelopmentDisplayWorkOnCaseUi from "./DevelopmentDisplayWorkOnCaseUi";
import * as workOnCaseUtils from "./../../backend/WorkOnCaseUtils";
import * as PreQV3Constants from "./../../../commonxV3_001/PreQV3Constants";
import { BiRun } from "react-icons/bi";
import { IconContext } from "react-icons";
import AsyncProcessDialogs from "./../../../commonxV3_001/app/AsyncProcessDialogs";
import { IoCaretDown, IoCaretForward } from "react-icons/io5";
import WorkOnCaseFileCommander from "./FileCommander/WorkOnCaseFileCommander";
import WorkOnCaseFileCommanderRoot from "./FileCommander/WorkOnCaseFileCommanderRoot";
import WorkOnCaseDeployToFinalFolder from "./DeplyToFinalFolder/WorkOnCaseDeployToFinalFolder";
import SequenceLogFileDownloadLink from "./SequenceLogFileDownloadLink";
import * as environmentService from "./../../../EnvironmentService";


import * as CT_ADD_DisplayRightType from  "./../../../commonxV3_001/codetable/CT_ADD_DisplayRightType";
import * as CT_ProcessingPhase      from  "./../../../commonxV3_001/codetable/CT_ProcessingPhase";
import * as CT_ADD_AsyncAction      from  "./../../../commonxV3_001/codetable/CT_ADD_AsyncAction";
import * as CT_ProcessStatus        from  "./../../../commonxV3_001/codetable/CT_ProcessStatus";




class WorkOnCaseUi extends Component {

  constructor(props) {
    super(props);

       // Create the state
       // displayRightType can be (see: CT_ADD_DisplayRightType):
       //    UNDEFINED : "",
       //    MANUALLY_RESULT : "MR",
       //    MANUALLY_PARAMETER : "MP_ONE" or "MP_SEQ",
       //    AUTOUPDATE_RESULT : "AR",       
       //
       //
       // displayRightList can be:
       //
       //  if displayRightType = "MP_ONE" or "MP_SEQ":  
       //     [0..n]: row ChainEntry (incl. sequenceIndex, parameter, ...)
       //   if displayRightType = "MR":
       //        [0]: ChainEntry.sequenceIndex
       //        [1]: preqsys_process-Object       
       //        [2]: ChainEntry
       //  if displayRightType = "AR":
       //        [0]: ChainEntry.sequenceIndex
       //        [1]: preqsys_process-Object
       //        [2]: ChainEntry

       this.state = {
        list: [],
        error: null,
        isLoading: true,

        uiParams : null,
        fileIoOPen : false,

//        autoUpdate: false,
        autoUpdateTimeStamp : "",

        autoUpdateCurrentRunningProcess: null,
        autoUpdateCurrentRunningProcessChainEntry: null,
        indSetAutoUpdateToFalseOnNextRun : false,

        displayRightType : CT_ADD_DisplayRightType.CODE.UNDEFINED,
        displayRightList : [],

        // if displayRightType = "MP_ONE" or "MP_SEQ":  
        // this are the endPoint Parameters   
//        endPointDefaultParams: [],
//        endPointUserParams: [],

        phaseToProcess : "",
        inProgress : false,
        showStartBatchButton : false,
        completeUnTouchedPhases: [],
        completelyDone : false,
        listFetchObj : null,

/*      
 * ------------------------------------------------------------
 *  Code supporting asyncProcessDialogsModal =====>
 * ------------------------------------------------------------
 */ 
        asyncProcessDialogsModal: {
          active: false,
          tenant: null,
          caseid: null,
          code: null, //see Codetable CT_ADD_AsyncAction
          name : null, //clear text for asyncAction (retrieved from Codetable CT_ADD_AsyncAction) 
          parameter : null, 

        },
/*      
* ------------------------------------------------------------
*  <===== Code supporting asyncProcessDialogsModal
* ------------------------------------------------------------
*/ 

      };
  }

  async componentDidMount() {
    this.updateList();
    const intervalId = setInterval(() => {
      this.updateList();
    }, 2000);  
  }

  async updateList() {

    let today = new Date();
    var now = today.toLocaleString();

    if (this.state.indSetAutoUpdateToFalseOnNextRun) {
      this.setAutoUpdateValue(false);
      this.setState(
        { 
            indSetAutoUpdateToFalseOnNextRun : false
          , autoUpdateTimeStamp : now
        });
      return;
    }

    let lastSeqTriggerTime = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_LATEST_SEQUENCE_TRIGGER_TIME);
    if (lastSeqTriggerTime !== null) { //there was a trigger
      let lastSeqTriggerTimeText = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_LATEST_SEQUENCE_TRIGGER_TIME_CLEAR_TEXT);
      //so within 2 seconds after lastSeqTriggerTime no updateList is performed (server side requires a minimum of 1 second to start a triggered sequence) 
      let dateLastSeqTrigger = new Date();
      let dateValidAskForStatus = new Date();

      let numberValueTriggerTime = Number(lastSeqTriggerTime);
      dateLastSeqTrigger.setTime(numberValueTriggerTime);
      dateValidAskForStatus.setTime(numberValueTriggerTime + 2000); //after this point in time it makes sense to ask for a status
      let dateNow = new Date();
      if (dateNow.getTime() < dateValidAskForStatus.getTime()) {
        return;
      } else {
      }

    }

    
    let bContinue = false;

    let caseReadListSemaphor = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_CASE_READ_LIST_SEMAPHOR);
    if (caseReadListSemaphor === "true") {
      bContinue = true;
      sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_CASE_READ_LIST_SEMAPHOR, "false");
    }

    let autoUpdateInfo = this.getAutoUpdateValue();
    if (autoUpdateInfo === true) {
      bContinue = true;
    }
    if (bContinue === false) {
      return;
    }

    environmentService.log("=== RETRIEVE LIST ==== ");

    let uiParams = this.props.uiParams;
    if (uiParams.showDevTags === true) {
      environmentService.log('--------------------------------------------------------------------------------');
      environmentService.log('caseReadListSemaphor: ' + caseReadListSemaphor);
      environmentService.log('autoUpdateInfo: ' + autoUpdateInfo);
      environmentService.log('--------------------------------------------------------------------------------');
    }

    if (this.state.list.length === 0) {
      this.setState({ isLoading: true });
    }

    let currentCase = this.props.uiGetCurrentCase();
    let currentTenant = this.props.uiGetCurrentTenant();

    if ((currentCase === "") || (currentTenant === "")) {
      return;
    }

    let listFetchObj = await restClient.getProcessChainStatus(currentTenant, currentCase);
    if (listFetchObj === null) {
      return;
    }
    if (listFetchObj === undefined) {
      return;
    }
    if (listFetchObj.steadyFlowStatus === null) {
      return;
    }
    if (listFetchObj.steadyFlowStatus === undefined) {
      return;
    }

    //this.setAutoUpdateValue(listFetchObj.sequenceIsRunning)
    let runningProcess = listFetchObj.runningProcess;
    let runningProcessChainEntry = listFetchObj.runningProcessChainEntry;
    let phaseToProcess = CT_ProcessingPhase.getTextForCode(listFetchObj.phaseToProcess);
    let completeUnTouchedPhases = listFetchObj.completeUnTouchedPhases;
    let completelyDone = listFetchObj.completelyDone;
    

    let seqStatus = listFetchObj.steadyFlowStatus.currentSequenceStatus;
    let indSetAutoUpdateToFalseOnNextRun = false;
    switch(seqStatus) {
        case CT_ProcessStatus.CODE.JS_NOTHING_HAPPENED:
          indSetAutoUpdateToFalseOnNextRun = true;
          //this.setAutoUpdateValue(false);
          break;
        case CT_ProcessStatus.CODE.JS_PROCESS_CORRUPTED:
          indSetAutoUpdateToFalseOnNextRun = true;
          //this.setAutoUpdateValue(false);
          break;
        case CT_ProcessStatus.CODE.JS_PROCESS_DONE:
          indSetAutoUpdateToFalseOnNextRun = true;
          //this.setAutoUpdateValue(false);
          break;
        default:
          break;                
    }

    this.setState({ isLoading: false, listFetchObj: listFetchObj, list: listFetchObj.chain, error: null
                  , indSetAutoUpdateToFalseOnNextRun : indSetAutoUpdateToFalseOnNextRun
                  , autoUpdateTimeStamp : now, autoUpdateCurrentRunningProcess:runningProcess, autoUpdateCurrentRunningProcessChainEntry:runningProcessChainEntry
                  , phaseToProcess: phaseToProcess, inProgress : listFetchObj.sequenceIsRunning, completeUnTouchedPhases:completeUnTouchedPhases, completelyDone:completelyDone
                  , uiParams : uiParams });



  
  }


  async handleSelectAll() {
    await this.doSelectAll()
  }

  async doSelectAll() {
    let chain = this.state.list;
    for (let i = 0; i < chain.length; i++) {
      let status = chain[i].status;
      if (status === "") {
        this.handleProcessingChangedToSequencing(chain[i])
      }
    }

    let currentList = this.state.displayRightList;

    currentList[currentList.length-1].indUiSelectedAsSequenced = false;
    this.handleDisplayRightSettings(CT_ADD_DisplayRightType.CODE.MANUALLY_PARAMETER_SEQUENCE, currentList, true);

  }


  defaultSelectedProcessingSteps(chain) {
    let result = [];
    for (let i = 0; i < 1; i++) {
      if (chain[i].status === "") {
        result.push(chain[i]);
          chain[i].indUiSelectedAsSequenced = false;
      }
    }
    return result;
  }



  render() {

    let currentCase = this.props.uiGetCurrentCase();
    let currentTenant = this.props.uiGetCurrentTenant();
    let indAutoUpdateActive = this.getAutoUpdateValue();
    let showResetButton = (indAutoUpdateActive === false) && (this.state.displayRightType === CT_ADD_DisplayRightType.CODE.UNDEFINED)

    if (currentCase === "") {
      return (
        <div className="preQ_SME_noSelectedCaseWarning">
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          &nbsp;&nbsp;&nbsp;&nbsp;Please select a case before start working here.
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          &nbsp;
        </div>
      );
    }

    const { list, isLoading, showStartBatchButton, asyncProcessDialogsModal, completelyDone } = this.state;

    let logLinkSeqLog = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
    + "/RESTServiceFileDownLoad/Tenant/preq/sbv3/service/analysis/support/sequencelog/" + currentTenant + "/" + currentCase;
    let logFileNameSeqLog = currentTenant + "_" + currentCase + "_SequenceLog.zip";


    ///preq/sbv3/service/tenant/sequence/case/report/document/{tenant}/{caseid}
    let logLinkReport = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
    + "/RESTServiceFileDownLoad/Tenant/preq/sbv3/service/tenant/sequence/case/report/document/" + currentTenant + "/" + currentCase;
    let logFileNameReport = currentTenant + "_" + currentCase + "_CaseReport" + ".pdf";

    let sShowStartBatchButton = 'false';
    if (showStartBatchButton === true) {
      sShowStartBatchButton = 'true';
    }

    if (isLoading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    let indDisplayOldUiParts = false;

    if ((list !== null) && (asyncProcessDialogsModal.active === false)) {

      return (
        <div>
          <DevelopmentTag uiParams = {this.state.uiParams} devTagLabel = {this.constructor.name}/>


          <br/>
          <table width="100%">
              <tr>
                <td width="22%">
                  {indDisplayOldUiParts
                  ? <div>
                    <Toggle toggled={this.getAutoUpdateValue()} 
                      size = 'sm'  
                      labelA="Work as SME"
                      labelB="Processing Status"
                      onClick={this.handleAutoUpdateToggle.bind(this)}/>
                    <br/>
                   </div>
                  : <div>
                    </div>}
                </td>
                <td  width="22%">
                  <div className="preQ_SME_label">
                    Last Server sided Update: {this.state.autoUpdateTimeStamp}
                  </div>
                </td>
                <td  width="12%">
                  &nbsp;
                </td>
                <td  width="44%">
                  {showResetButton
                  ? <div>
                      <Button kind="tertiary" onClick={this.openAsyncModal.bind(this, CT_ADD_AsyncAction.CODE.JS_RESET_CASE, currentTenant, currentCase, '{"ignoreRunningProcesses": false}' )} size='sm'>reset Case</Button>
                  </div>
                  : <div>
                      <Button kind="ghost" disabled='true' size='sm'>&nbsp;</Button>
                    </div>}
                </td>
              </tr>
            </table>
            
            <WorkOnCaseFileCommanderRoot 
              uiParams = {this.state.uiParams}
              completeUnTouchedPhases = {this.state.completeUnTouchedPhases}
            />
            <WorkOnCaseDeployToFinalFolder
              uiParams = {this.state.uiParams}  
              completelyDone = {this.state.completelyDone}
            />

            <br/>
            <table width="100%">
              <tr>
              <td width="44%">
                    <table width="100%">

                      <tr>
                        <td width="1%" className="preQ_SME_backGroundColor_process_REGISTERED"></td>
                        <td width="4%" className="preQ_SME_backGroundColor_process_REGISTERED">&nbsp;</td>
                        <td width="85%"className="preQ_SME_backGroundColor_process_REGISTERED" >
                          <table>
                            <tr>
                              <td>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Phase to Process:&nbsp;&nbsp;&nbsp;{this.state.phaseToProcess}
                              </td>
                              <td>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </td>
                              {
                                  this.getAutoUpdateValue()
                                  ?  
                                  <td>
                                    <InlineLoading description="Server is working"/>
                                  </td>
                                  :
                                  <td>
                                   &nbsp;
                                  </td>
                                }

                            </tr>
                          </table>
                        </td>
                        <td width="4%" className="preQ_SME_backGroundColor_process_REGISTERED">&nbsp;</td>
                        <td  width="4%" className="preQ_SME_backGroundColor_process_REGISTERED">
                          {this.getAutoUpdateValue()
                          ? <div></div>
                          : <div onClick={this.handleSelectAll.bind(this)}>
                              <IconContext.Provider value={{className:"preQ_SME_icon_process_SELECT_ALL"}}>
                                <br/><BiRun size="22"/>
                              </IconContext.Provider>
                            </div>                        
                          }
                          
                        </td>
                        <td width="4%" className="preQ_SME_backGroundColor_process_REGISTERED">&nbsp;</td>
                      </tr>

                  {this.state.list.map((row) => (
                      <tr>
                        <td  className={"preQ_SME_backGroundColor_process_" + row.status}></td>
                        <td  className={"preQ_SME_backGroundColor_process_" + row.status}></td>
                        <td>
                          <Accordion>
                            <AccordionItem title={row.businessIdentifier} open={row.indRunning}>
                            <WorkOnProcessItemListUi 
                                processItem={row}
                                handleDisplayRightSettings = {this.handleDisplayRightSettings.bind(this)}
                              />
                            </AccordionItem>
                          </Accordion>
                        </td>
                        <td>&nbsp;</td>
                        <td>
                          <WorkOnCaseUiProcessingIcon 
                            row={row}
                            list = {this.state.list}
                            handleProcessingChangedToSingleStep = {this.handleProcessingChangedToSingleStep.bind(this)}
                            handleProcessingChangedToSequencing = {this.handleProcessingChangedToSequencing.bind(this)}
                            displayRightType = {this.state.displayRightType}
                            displayRightList = {this.state.displayRightList}
                            autoUpdate = {this.getAutoUpdateValue()}
                            />
                        </td>
                        <td><WorkOnCaseUiProcessStatusIcon 
                                row={row}
                                list = {this.state.list}
                                handleDisplayRightSettings = {this.handleDisplayRightSettings.bind(this)}
                                displayRightType = {this.state.displayRightType}
                                displayRightList = {this.state.displayRightList}
                                autoUpdate = {this.getAutoUpdateValue()}
                              />
                        </td>
                      </tr>
                  ))}

                  </table>           
              </td>
                
              <td width="2%">
                &nbsp; 
              </td>

              <td width="54%">
                    <br></br>
                    <br></br>
                    <br></br>
                    {true ? (
                      <WorkOnCaseUiDisplayRightUi 
                      uiGetCurrentTenant={this.props.uiGetCurrentTenant}
                      uiGetCurrentCase={this.props.uiGetCurrentCase}
                      displayRightType = {this.state.displayRightType}
                      displayRightList = {this.state.displayRightList}
                      autoUpdate = {this.getAutoUpdateValue()}
                      autoUpdateCurrentRunningProcess = {this.state.autoUpdateCurrentRunningProcess}
                      autoUpdateCurrentRunningProcessChainEntry = {this.state.autoUpdateCurrentRunningProcessChainEntry}
                      resetDisplayRightSettings = {this.resetDisplayRightSettings.bind(this)}
                      showStartBatchButton = {this.state.showStartBatchButton}
                      uiParams = {this.state.uiParams}
                      getDisplayListEntry = {this.getDisplayListEntry.bind(this)}
                      updateUserParameter = {this.updateUserParameter.bind(this)}
                      resetUserParameter = {this.resetUserParameter.bind(this)}
                      getDisplayListArray = {this.getDisplayListArray.bind(this)}
                      listFetchObj = {this.state.listFetchObj}
                      logLinkSeqLog = {logLinkSeqLog}
                      logFileNameSeqLog = {logFileNameSeqLog}
                      logLinkReport = {logLinkReport}
                      logFileNameReport = {logFileNameReport}
                    />
                 ) : ( 
                      <div></div>
                     )}
              </td>

              </tr>
            </table>

            <DevelopmentDisplayWorkOnCaseUi 
              uiParams = {this.state.uiParams} 
              devTagLabel={this.constructor.name}
              displayRightType = {this.state.displayRightType}
              displayRightList = {this.state.displayRightList}
              state = {this.state}
              />
        </div>
      );
    }

    /*      
 * ------------------------------------------------------------
 *  Code supporting asyncProcessDialogsModal =====>
 * ------------------------------------------------------------
 */ 
    if (asyncProcessDialogsModal.active === true) {
      return (
        <div>
          <AsyncProcessDialogs
            asyncProcessDialogsModal = {this.state.asyncProcessDialogsModal}
            functionCloseAsyncModal = {this.closeAsyncModal.bind(this)}
           />
        </div>
      );
    }
    
/*      
 * ------------------------------------------------------------
 *  <===== Code supporting asyncProcessDialogsModal
 * ------------------------------------------------------------
 */ 

    return (
      <div>
        <p>Error raised</p>
      </div>
    );

  }



  /*
  * This method is used by left handed navigation to modify display right
  */
  handleDisplayRightSettings(displayRightType, displayRightList, showStartBatchButton) {
    if (this.getAutoUpdateValue() === true) {
      return;
    }
//    let endPointParams = null;
    this.setState({ displayRightType: displayRightType, displayRightList: displayRightList, showStartBatchButton:showStartBatchButton});  
  }

  /*
  * This method is used by right handed display to reset and resets sequencing flags
  * of state.list 
  */
  resetDisplayRightSettings(autoUpdate) {

    //reset all sequencing-flags
    let chainList = this.state.list;
    for (let i = 0; i < chainList.length; i++) {
      let currentEntry = chainList[i]; 
      currentEntry.indUiSelectedAsSequenced = false;
    }

//    let endPointParams = [];
    //reset state: no sequenceing flags, no selection for right Ui
    if (autoUpdate === null) {
	    this.setState({ displayRightType: CT_ADD_DisplayRightType.CODE.UNDEFINED, displayRightList: [], list:chainList,showStartBatchButton:false});  
	  }
    if (autoUpdate === undefined) {
	    this.setState({ displayRightType: CT_ADD_DisplayRightType.CODE.UNDEFINED, displayRightList: [], list:chainList,showStartBatchButton:false});  
	  }
    if (autoUpdate === true) {
      this.setAutoUpdateValue(true);
	    this.setState({ displayRightType: CT_ADD_DisplayRightType.CODE.UNDEFINED, displayRightList: [], list:chainList,showStartBatchButton:false});  
	  }
    if (autoUpdate === false) {
      this.setAutoUpdateValue(false);
	    this.setState({ displayRightType: CT_ADD_DisplayRightType.CODE.UNDEFINED, displayRightList: [], list:chainList,showStartBatchButton:false});  
	  }
  }

  handleAutoUpdateToggle() {

    let today = new Date();
    var now = today.toLocaleString();
    if (this.getAutoUpdateValue() === true) {
      this.setAutoUpdateValue(false);
      this.setState({ autoUpdateTimeStamp:now, autoUpdateCurrentRunningProcess: null, autoUpdateCurrentRunningProcessChainEntry:null, displayRightType : CT_ADD_DisplayRightType.CODE.UNDEFINED, displayRightList : [], showStartBatchButton : false });
    } else {
      this.setAutoUpdateValue(true);
      this.setState({ autoUpdateTimeStamp:now, autoUpdateCurrentRunningProcess: null, autoUpdateCurrentRunningProcessChainEntry:null, displayRightType : CT_ADD_DisplayRightType.CODE.UNDEFINED, displayRightList : [], showStartBatchButton : false, fileIoOPen : false, });
    }
  }


  handleProcessingChangedToSingleStep(chainEntry) {
    let chainList = this.state.list;
    let displayRightList = this.state.displayRightList;
    let displayRightType = CT_ADD_DisplayRightType.CODE.MANUALLY_PARAMETER_SEQUENCE;  //might change, when the last entry has been removed
    environmentService.log('', chainEntry);

    let indexToRemove = -1;
    for (let i = 0; i < displayRightList.length; i++) {
      if (displayRightList[i].sequenceIndex === chainEntry.sequenceIndex) {
        indexToRemove = i;
      } 
    }

    environmentService.log('', displayRightList.length)
    let lastIndex = displayRightList.length-1;
    while (lastIndex >= indexToRemove) {
      displayRightList.pop();
      lastIndex = lastIndex-1;
    } 


  }

  handleProcessingChangedToSequencing(selectedEntry) {
    let selectedChainEntry = selectedEntry.chainEntry;
    let chainList = this.state.list;
    let displayRightList = this.state.displayRightList;
    let displayRightType = CT_ADD_DisplayRightType.CODE.MANUALLY_PARAMETER_SEQUENCE;

    let entryAlreadyAvailable = workOnCaseUtils.isEntryInDisplayRightParameterList(selectedEntry, displayRightType, displayRightList)
/*    let entryAlreadyAvailable = false;
    for (let i = 0; i < displayRightList.length; i++) {
      let index1 = displayRightList[i].chainEntry.sequenceIndex;
      let index2 = selectedChainEntry.sequenceIndex; 
      if (index1 === index2) {
        entryAlreadyAvailable = true;
      }
    } */

    if (entryAlreadyAvailable === false) {
      for (let i = 0; i < chainList.length; i++) {
        let currentEntry = chainList[i]; 
        let currentChainEntry = currentEntry.chainEntry;
        let index1 = currentChainEntry.sequenceIndex;
        let index2 = selectedChainEntry.sequenceIndex;
        if (index1 === index2) {
          chainList[i].indUiSelectedAsSequenced = true;
        }
      }
      displayRightList.push(selectedEntry);
      //for all entries in displayRightList: 
      //   IF: (attribute selectedUiParamenters === '') is true                             empty
      //  AND: (attribute chainEntry.endPointProcessTriggerParameter === '') is false       not empty
      // THEN: transfer attribute chainEntry.endPointProcessTriggerParameter ===>   attribute selectedUiParamenters
      for (let i = 0; i <  displayRightList.length; i++) {
        let currentEntry = displayRightList[i];
        let indEndPointProcessTriggerParameterIsEmpty =  (currentEntry.chainEntry.endPointProcessTriggerParameter === "");
        if (currentEntry.selectedUiParameters === "") {
          if (!indEndPointProcessTriggerParameterIsEmpty) {
            currentEntry.selectedUiParameters = currentEntry.chainEntry.endPointProcessTriggerParameter;
          }
        }
      }

//      let endPointParams = null;
      this.setState({ displayRightType: displayRightType, displayRightList: displayRightList, list:chainList}); 
        
    }
  }

  updateUserParameter(sequenceindex, parameters) {
    let chainList = this.state.list;

    for (let i = 0; i < chainList.length; i++) {
      let currentEntry = chainList[i]; 
      let cSequenceindex = currentEntry.sequenceindex;
      if (cSequenceindex === sequenceindex) {
        chainList[i].selectedUiParameters = parameters;
      }
    }

    this.setState({ list:chainList}); 

  }



  resetUserParameter(sequenceindex) {
    let chainList = this.state.list;
    for (let i = 0; i < chainList.length; i++) {
      let currentEntry = chainList[i]; 
      let cSequenceindex = currentEntry.sequenceindex;
      if (cSequenceindex === sequenceindex) {
        chainList[i].selectedUiParameters = chainList[i].chainEntry.endPointProcessTriggerParameter;
      }
    }
    this.setState({ list:chainList}); 
  }


  getDisplayListEntry(sequenceindex) {
    let chainList = this.state.list;
    for (let i = 0; i < chainList.length; i++) {
      let currentEntry = chainList[i]; 
      let cSequenceindex = currentEntry.sequenceindex;
      if (cSequenceindex === sequenceindex) {
        return currentEntry
      }
    }
    return null;
  }

  getDisplayListArray() {
    let displayResultList = this.state.displayRightList;
    /*
    let result = produce(displayResultList, draft => {
    }) */ 
    return displayResultList;
  }

  getAutoUpdateValue() {
    let value = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_CASE_AUTOUPDATE);
    if (value === "true") {
      return true;
    } else {
      return false;
    }
  }

  setAutoUpdateValue(indBoolean) {
    if (indBoolean === true) {
      sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_CASE_AUTOUPDATE, "true");
    } else {
      sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_CASE_AUTOUPDATE, "false");
    }
  }


  /*      
 * ------------------------------------------------------------
 *  Code supporting asyncProcessDialogsModal =====>
 * ------------------------------------------------------------
 */ 
async openAsyncModal(asyncAction, tenant, caseid, asyncParameter) {
  //  alert(asyncAction + " : [" + tenant + "] / [" + caseid + "]");
  
    let asyncActionName = CT_ADD_AsyncAction.getTextForCode(asyncAction);
    this.setState({ asyncProcessDialogsModal: {...this.state.asyncProcessDialogsModal, 
      active: true,
      tenant: tenant,
      caseid: caseid,
      code: asyncAction, //see Codetable CT_ADD_AsyncAction
      name : asyncActionName,
      parameter : asyncParameter,
      } 
    }); 
  
  }
  
  async closeAsyncModal() {
  
    //Specifics for this Component.PRE: a tenant delete must be finalized with a sync call to clean up preqsys_process
    let indReset =  (this.state.asyncProcessDialogsModal.code === CT_ADD_AsyncAction.CODE.JS_RESET_CASE);
    if (indReset) {
      this.setAutoUpdateValue(true);      
    }
  
    let actionDone = this.state.asyncProcessDialogsModal.code;

  
    if (actionDone === CT_ADD_AsyncAction.CODE.JS_RESET_CASE) {
      this.setState({ asyncProcessDialogsModal: {...this.state.asyncProcessDialogsModal, 
        active: false,
        tenant: null,
        caseid: null,
        code: null,
        name : null,
        parameter : null,
        indSetAutoUpdateToFalseOnNextRun : true,
      } 
      });
  
    } else {

    //Standard Code supporting asyncProcessDialogsModal =====>
    this.setState({ asyncProcessDialogsModal: {...this.state.asyncProcessDialogsModal, 
      active: false,
      tenant: null,
      caseid: null,
      code: null,
      name : null,
      parameter : null,
      } 
    });
    //<===== Standard Code supporting asyncProcessDialogsModal 

    }
    //Specifics for this Component.POST: Update of Master Component
  
  }
  
}

export default WorkOnCaseUi;
