import React, { Component } from "react";
import DevelopmentTag from "./../../../common/DevelopmentTag";

import Status95 from "./../../../archivesV3_001/app/Status95";
import Status100 from "./../../../archivesV3_001/app/Status100";
import Status200 from "./../../../contenttypesV3_001/app/Status200";
import Status300 from "./../../../convertersV3_001/app/Status300";
import Status5000 from "./../../../harvesterV3_001/app/Status5000";
import Status5100 from "./../../../anonymizersV3_001/app/Status5100";
import Status5500 from "./../../../postprocessingV3_001/app/Status5500";
import Status5600 from "./../../../postprocessingV3_001/app/Status5600";
import Status6100 from "./../../../anonymizersV3_001/app/Status6100";
import Status6200 from "./../../../anonymizersV3_001/app/Status6200";
import Status9650 from "./../../../anonymizersV3_001/app/Status9650";
import Status9700 from "./../../../anonymizersV3_001/app/Status9700";
import Status9720 from "./../../../anonymizersV3_001/app/Status9720";
import Status9750 from "./../../../anonymizersV3_001/app/Status9750";
import Status9800 from "./../../../archivesV3_001/app/Status9800";
import Status9955 from "./../../../archivesV3_001/app/Status9955";


class WorkOnCaseUiDisplayRightResultStatusUi extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }


  render() {
    let row = this.props.row;
    let chainEntry = this.props.chainEntry;
    let moduleUrls = this.props.moduleUrls;


    if ((row === null) || (chainEntry === null) || (moduleUrls === null)) {
      return (
        <div>&nbsp;</div>
      );
    } else {

      if (row.sequenceindex === 95) {
        return (
          <div>
            <Status95 
              row = {row} 
              moduleUrls = {moduleUrls}
              chainEntry = {chainEntry}
              uiParams = {this.props.uiParams}
            />
          </div>
        );  
      }

      if (row.sequenceindex === 100) {
        return (
          <div>
            <Status100 
              row = {row} 
              moduleUrls = {moduleUrls}
              chainEntry = {chainEntry}
              uiParams = {this.props.uiParams}
            />
          </div>
        );  
      }


      if (row.sequenceindex === 200) {
        return (
          <div>
            <Status200 
              row = {row} 
              moduleUrls = {moduleUrls}
              chainEntry = {chainEntry}
              uiParams = {this.props.uiParams}
            />
          </div>
        );  
      }


      if (row.sequenceindex === 300) {
        return (
          <div>
            <Status300 
              row = {row} 
              moduleUrls = {moduleUrls}
              chainEntry = {chainEntry}
              uiParams = {this.props.uiParams}
            />
          </div>
        );  
      }

      if (row.sequenceindex === 5000) {
        return (
          <div>
            <Status5000 
              row = {row} 
              moduleUrls = {moduleUrls}
              chainEntry = {chainEntry}
              uiParams = {this.props.uiParams}
            />
          </div>
        );  
      }

      if (row.sequenceindex === 5100) {
        return (
          <div>
            <Status5100 
              row = {row} 
              moduleUrls = {moduleUrls}
              chainEntry = {chainEntry}
              uiParams = {this.props.uiParams}
            />
          </div>
        );  
      }

      if (row.sequenceindex === 5500) {
        return (
          <div>
            <Status5500 
              row = {row} 
              moduleUrls = {moduleUrls}
              chainEntry = {chainEntry}
              uiParams = {this.props.uiParams}
            />
          </div>
        );  
      }

      if (row.sequenceindex === 5600) {
        return (
          <div>
            <Status5600 
              row = {row} 
              moduleUrls = {moduleUrls}
              chainEntry = {chainEntry}
              uiParams = {this.props.uiParams}
            />
          </div>
        );  
      }

      if (row.sequenceindex === 6100) {
        return (
          <div>
            <Status6100 
              row = {row} 
              moduleUrls = {moduleUrls}
              chainEntry = {chainEntry}
              uiParams = {this.props.uiParams}
            />
          </div>
        );  
      }

      if (row.sequenceindex === 6200) {
        return (
          <div>
            <Status6200 
              row = {row} 
              moduleUrls = {moduleUrls}
              chainEntry = {chainEntry}
              uiParams = {this.props.uiParams}
            />
          </div>
        );  
      }

      if (row.sequenceindex === 9650) {
        return (
          <div>
            <Status9650 
              row = {row} 
              moduleUrls = {moduleUrls}
              chainEntry = {chainEntry}
              uiParams = {this.props.uiParams}
            />
          </div>
        );  
      }

      if (row.sequenceindex === 9700) {
        return (
          <div>
            <Status9700 
              row = {row} 
              moduleUrls = {moduleUrls}
              chainEntry = {chainEntry}
              uiParams = {this.props.uiParams}
            />
          </div>
        );  
      }

      if (row.sequenceindex === 9720) {
        return (
          <div>
            <Status9720 
              row = {row} 
              moduleUrls = {moduleUrls}
              chainEntry = {chainEntry}
              uiParams = {this.props.uiParams}
            />
          </div>
        );  
      }

      if (row.sequenceindex === 9750) {
        return (
          <div>
            <Status9750 
              row = {row} 
              moduleUrls = {moduleUrls}
              chainEntry = {chainEntry}
              uiParams = {this.props.uiParams}
            />
          </div>
        );  
      }

      if (row.sequenceindex === 9800) {
        return (
          <div>
            <Status9800 
              row = {row} 
              moduleUrls = {moduleUrls}
              chainEntry = {chainEntry}
              uiParams = {this.props.uiParams}
            />
          </div>
        );  
      }



      if (row.sequenceindex === 9955) {
        return (
          <div>
            <Status9955 
              row = {row} 
              moduleUrls = {moduleUrls}
              chainEntry = {chainEntry}
              uiParams = {this.props.uiParams}
            />
          </div>
        );  
      }



      return (
        <div>WorkOnCaseUiDisplayRightResultStatusUi:???</div>
      );  


    }
  }

}

export default WorkOnCaseUiDisplayRightResultStatusUi;
