import * as Facade from "./../../Facade";
import * as environmentService from "./../../EnvironmentService";
import * as PreQV3Constants from "./../PreQV3Constants";

const HttpCodes = {
    OK : 200,
    BAD_REQUEST : 400,
    INTERNAL_SERVER_ERROR : 500,
  
  }

export async function submitOneFile(acceptedFiles) {

    let indTest = false;
    if (indTest) {
        let errorJson = PreQV3Constants.CONSTANTS.ERROR_UNKNOWN;
        let error = JSON.parse(errorJson); 
        return error;
    
    }

    let uploadUrl = await Facade.FILE_UPLOAD_URL();

    const formData = new FormData();
    formData.append('PreQ-Header-User', PreQV3Constants.getLoggedInUser());
    const base64TransactionKey = btoa(PreQV3Constants.getTransactionKey());
    formData.append('PreQ-Header-TransactionKey', base64TransactionKey);
    for (let i = 0; i < acceptedFiles.length; i++) {    
        let acceptedFile = acceptedFiles[i];
        formData.append('file_' + i.toString(), acceptedFile);
    }

    let response_FromServer = await fetch(uploadUrl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json, text/plain'
        },
          body : formData,
       })
    
    let httpStatus = HttpCodes.OK;
    if (!response_FromServer.ok) {
        httpStatus = response_FromServer.status;
    }

    if (httpStatus === HttpCodes.OK) {
        let data_FromServer = await response_FromServer.json();
        environmentService.log("data_FromServer", data_FromServer);
        return data_FromServer;
    }  

    if (httpStatus === HttpCodes.BAD_REQUEST) {
        let data_FromServer = await response_FromServer.json();
        environmentService.log("data_FromServer", data_FromServer);
        return data_FromServer;
    }
   
    if (httpStatus === HttpCodes.INTERNAL_SERVER_ERROR) {
        let data_FromServer = await response_FromServer.json();
        environmentService.log("data_FromServer", data_FromServer);
        return data_FromServer;
    }

    let errorJson = PreQV3Constants.CONSTANTS.ERROR_UNKNOWN;
    let error = JSON.parse(errorJson); 
    return error;

}