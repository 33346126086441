//if value are not yet set, then result is null
export const CONSTANTS = {

  BOOLEAN_TRUE : 'true',
  BOOLEAN_FALSE : 'false',

  //PATH-EXTENSIONS
	PE_PROCESS_TRIGGER	:  "/trigger",
	PE_PROCESS_TRIGGER_IN_SEQUENCE	: "/triggerInSequence",

  //MODULE_IDENTIFIER
	MI_TENANT	:  "Tenant",

  //Relatrive URLs
  RU_START_SEQUENCING : "/preq~sbv3~service~tenant~sequence~trigger",

  ERROR_UNKNOWN : '{"errorText": "","e": null,"errorCode": "00000"}',
  ERROR_UNKNOWN_CODE : '00000',

  EMPTY_PREQ_EXCEPTION_LIST : '{"preQExceptions":[]}',
  
  //****************************************
  //SessionStorageKEYS 
  //****************************************

  //LoggedIn User
  SESSION_STORAGE_KEY_LOGGED_IN_USER_NAME :"loggedInPreQUser",
  SESSION_STORAGE_KEY_PREQ_KEY :"preQKey",
  SESSION_STORAGE_KEY_TRANSACTION_KEY :"transactionKey",
  SESSION_STORAGE_KEY_TRANSACTION_KEY_TIMESTAMP :"transactionKeyTimeStamp",

  //Ui-Parameters
  SESSION_STORAGE_KEY_UI_PARAMETERS :"uiParams",

  //SME-Ui: Selected OrgUnit and Case 
  SESSION_STORAGE_KEY_SELECTED_ORG_UNIT :"selectedOrgUnit",
  SESSION_STORAGE_KEY_SELECTED_CASE :"selectedCase",

  //SME-Ui:  WorkOnCaseUI
  SESSION_STORAGE_KEY_CASE_READ_LIST_SEMAPHOR :"caseReadListSemaphor",
  SESSION_STORAGE_KEY_CASE_AUTOUPDATE :"autoUpdateFlag",

  //SME-Ui: 
  //remember last point in time triggering a sequence
  //Status retrieval loop will wait 2 seconds before retrieving status for the first time
  SESSION_STORAGE_KEY_LATEST_SEQUENCE_TRIGGER_TIME : "latestTriggerSequenceTime",
  SESSION_STORAGE_KEY_LATEST_SEQUENCE_TRIGGER_TIME_CLEAR_TEXT : "latestTriggerSequenceTimeClearText",


  //Enable/Disable (i) and (?)
//  SESSION_STORAGE_KEY_HELP_AND_ABOUT_BLOCKED : "headerHelpAndAboutBlocked",

  //WAMA-Ui:
  SESSION_STORAGE_KEY_CURRENT_WAMA_FILE_UPLOAD_ID : "wamaCurrentFileUploadID",
  SESSION_STORAGE_KEY_CURRENT_WAMA_WAITS_FOR_PROCESSING : "wamaWaitsForProcessing", //"true" or "false"

  SESSION_STORAGE_KEY_I18N_SELECTED : "i18nSelectedLanguage",
  SESSION_STORAGE_KEY_LOCALE_ACTIVE : "i18nLocaleActive",

  SESSION_STORAGE_KEY_PREQ_EXCEPTION_LIST : "preQExceptions"
}


export function resetSessionVariablesToBaseSet(removeLoggedInUser, removeI18nSelectedLanguage) {

  if (removeLoggedInUser === true) {
    sessionStorage.removeItem(CONSTANTS.SESSION_STORAGE_KEY_LOGGED_IN_USER_NAME);
    sessionStorage.removeItem(CONSTANTS.SESSION_STORAGE_KEY_PREQ_KEY);
    sessionStorage.removeItem(CONSTANTS.SESSION_STORAGE_KEY_TRANSACTION_KEY);
    sessionStorage.removeItem(CONSTANTS.SESSION_STORAGE_KEY_TRANSACTION_KEY_TIMESTAMP);
  }

    //SESSION_STORAGE_KEY_UI_PARAMETERS is retrieved in App.js at the beginning
  //containing environment-variables ... therefore: NEVER remove this one


  //remove all those, which are standard to be removed:
  sessionStorage.removeItem(CONSTANTS.SESSION_STORAGE_KEY_SELECTED_ORG_UNIT);
  sessionStorage.removeItem(CONSTANTS.SESSION_STORAGE_KEY_SELECTED_CASE);

  sessionStorage.removeItem(CONSTANTS.SESSION_STORAGE_KEY_CASE_READ_LIST_SEMAPHOR);
  sessionStorage.removeItem(CONSTANTS.SESSION_STORAGE_KEY_CASE_AUTOUPDATE);

  sessionStorage.removeItem(CONSTANTS.SESSION_STORAGE_KEY_LATEST_SEQUENCE_TRIGGER_TIME);
  sessionStorage.removeItem(CONSTANTS.SESSION_STORAGE_KEY_LATEST_SEQUENCE_TRIGGER_TIME_CLEAR_TEXT);

  sessionStorage.removeItem(CONSTANTS.SESSION_STORAGE_KEY_CURRENT_WAMA_FILE_UPLOAD_ID);
  sessionStorage.removeItem(CONSTANTS.SESSION_STORAGE_KEY_CURRENT_WAMA_WAITS_FOR_PROCESSING);

  if (removeI18nSelectedLanguage === true) {
    sessionStorage.removeItem(CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED);
  }
  sessionStorage.removeItem(CONSTANTS.SESSION_STORAGE_KEY_LOCALE_ACTIVE);

}

export function getPreqKey() {
  let value = sessionStorage.getItem(CONSTANTS.SESSION_STORAGE_KEY_PREQ_KEY);
  if (value === null) {
    return '';
  }
  if (value === undefined) {
    return '';
  }
  return value;
}

export function getLoggedInUser() {
  let value = sessionStorage.getItem(CONSTANTS.SESSION_STORAGE_KEY_LOGGED_IN_USER_NAME);
  if (value === null) {
    return '';
  }
  if (value === undefined) {
    return '';
  }
  return value;
}

export function setTransactionKey(transactionKey) {
  sessionStorage.setItem(CONSTANTS.SESSION_STORAGE_KEY_TRANSACTION_KEY, transactionKey);
  sessionStorage.setItem(CONSTANTS.SESSION_STORAGE_KEY_TRANSACTION_KEY_TIMESTAMP, new Date().toISOString());
}


export function getTransactionKey() {
  let value = sessionStorage.getItem(CONSTANTS.SESSION_STORAGE_KEY_TRANSACTION_KEY);
  if (value === null) {
    return '';
  }
  if (value === undefined) {
    return '';
  }
  return value;
}

export function getSelectedOrgUnit() {
  let value = sessionStorage.getItem(CONSTANTS.SESSION_STORAGE_KEY_SELECTED_ORG_UNIT);
  if (value === null) {
    return '';
  }
  if (value === undefined) {
    return '';
  }
  return value;
}

export function getSelectedCase() {
  let value = sessionStorage.getItem(CONSTANTS.SESSION_STORAGE_KEY_SELECTED_CASE);
  if (value === null) {
    return '';
  }
  if (value === undefined) {
    return '';
  }
  return value;
}


export function getTransactionKeyTimeStamp() {
  let value = sessionStorage.getItem(CONSTANTS.SESSION_STORAGE_KEY_TRANSACTION_KEY_TIMESTAMP);
  if (value === null) {
    return null;
  }
  if (value === undefined) {
    return null;
  }
  return value;
}


export function getUiParamsFromSession() {
  let value = sessionStorage.getItem(CONSTANTS.SESSION_STORAGE_KEY_UI_PARAMETERS);
  if (value === null) {
    return null;
  }
  if (value === undefined) {
    return null;
  }
  const obj = JSON.parse(value);
  return obj;
}

export function getPreQExceptionList() {
  let value = sessionStorage.getItem(CONSTANTS.SESSION_STORAGE_KEY_PREQ_EXCEPTION_LIST);
  if (value === null) {
    value = CONSTANTS.EMPTY_PREQ_EXCEPTION_LIST;
    sessionStorage.setItem(CONSTANTS.SESSION_STORAGE_KEY_PREQ_EXCEPTION_LIST, value);
  }
  if (value === undefined) {
    value = CONSTANTS.EMPTY_PREQ_EXCEPTION_LIST;
    sessionStorage.setItem(CONSTANTS.SESSION_STORAGE_KEY_PREQ_EXCEPTION_LIST, value);
  }
  const obj = JSON.parse(value);
  return obj;
}

export function addPreQExceptionToList() {

}



export function getAutoUpdateValue() {
  let value = sessionStorage.getItem(CONSTANTS.SESSION_STORAGE_KEY_CASE_AUTOUPDATE);
  if (value === "true") {
    return true;
  } else {
    return false;
  }
}

