import React, { Component } from "react";

import { Loading } from "carbon-components-react";
import * as PreQV3Constants from "./../../../../commonxV3_001/PreQV3Constants";
import * as RestClient from "../../../backend/RestClient";
import WorkOnCaseFileCommanderPane from "./WorkOnCaseFileCommanderPane";
import AsyncProcessDialogs from "./../../../../commonxV3_001/app/AsyncProcessDialogs";
import * as CT_ADD_AsyncAction from "../../../../commonxV3_001/codetable/CT_ADD_AsyncAction";
import * as environmentService from "./../../../../EnvironmentService";



class WorkOnCaseFileCommander extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {

        listToIbm: [],
        listToPreQ: [],
        error: null,
        isLoading: true,
  
        /*      
        * ------------------------------------------------------------
        *  Code supporting asyncProcessDialogsModal =====>
        * ------------------------------------------------------------
        */ 
        asyncProcessDialogsModal: {
          active: false,
          tenant: null,
          caseid: null,
          code: null, //see Codetable CT_ADD_AsyncAction
          name : null, //clear text for asyncAction (retrieved from Codetable CT_ADD_AsyncAction) 
          parameter : null,
        }
        /*      
        * ------------------------------------------------------------
        *  <===== Code supporting asyncProcessDialogsModal
        * ------------------------------------------------------------
        */ 

      };
  }


  async componentDidMount() {
    this.updateList();
  }
  
  async updateList() {

    let tenant = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_ORG_UNIT);
    let caseid = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_CASE);

    let lstToIbm = await RestClient.getFileListTenantToIbm(tenant);
    let lstToPrq = await RestClient.getFileListTenantCaseToPreQ(tenant, caseid);


    this.setState(
      {
      listToIbm: lstToIbm,
      listToPreQ: lstToPrq,
      error: null,
      isLoading: false,
      }
    ); 
  }

  async move_toIbm_to_toPreQ(fileList) {

    if (fileList.length === 0) {
      return;
    }
    let tenant = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_ORG_UNIT);
    let caseid = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_CASE);
    let asyncAction = CT_ADD_AsyncAction.CODE.JS_MOVE_FILES_SRC_TOIBM_DEST_TOPREQ;
    let parameter1 = '{ "files": [] }'; //TODO empty list is only to show: call is OK
    let parameter2 = this.buildParameterFileListToMove(fileList);
    environmentService.log('', parameter2);
    this.openAsyncModal(asyncAction, tenant, caseid, parameter2)
  }

  async move_toPreQ_to_toIbm(fileList) {

    if (fileList.length === 0) {
      return;
    }
    let tenant = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_ORG_UNIT);
    let caseid = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_CASE);
    let asyncAction = CT_ADD_AsyncAction.CODE.JS_MOVE_FILES_SRC_TOOREQ_DEST_TOIBM;
    let parameter1 = '{ "files": [] }'; //TODO empty list is only to show: call is OK
    let parameter2 = this.buildParameterFileListToMove(fileList);
    environmentService.log('', parameter2);
    this.openAsyncModal(asyncAction, tenant, caseid, parameter2)
  }

  buildParameterFileListToMove(fileList) {
    let parameter_Start = '{ "files": [';
    let parameter_End = '] }';
    let paramenter_Entries = '';
    fileList.forEach(function (item, index) {
      environmentService.log('', item);
      environmentService.log('', index);
      paramenter_Entries = paramenter_Entries + '"' + item + '",';
    });
    if (paramenter_Entries.endsWith(',')) {
      paramenter_Entries = paramenter_Entries.substring(0, paramenter_Entries.length - 1);
    }
    let parameter = parameter_Start + paramenter_Entries + parameter_End;
    return parameter;
  }


  render() {

    let tenant = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_ORG_UNIT);
    let caseid = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_CASE);

    const {listToIbm, listToPreQ, error, isLoading, asyncProcessDialogsModal} = this.state;

    if (isLoading && asyncProcessDialogsModal.active === false) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    if ((listToIbm !== null) && (listToPreQ !== null) && (asyncProcessDialogsModal.active === false)) {

      return (
        <div>
          <table  width="100%">
            <tr>
              <td width="49%" style={{fontWeight:"normal",backgroundColor: "#fffff"}}>
                <WorkOnCaseFileCommanderPane
                  level="tenant"
                  files={listToIbm.submittedFiles}
                  uiParams = {this.props.uiParams}
                  moveFunction={this.move_toIbm_to_toPreQ.bind(this)}
                  updateBothListsFunction={this.updateList.bind(this)}
                />
              </td>
              <td width="2%" style={{fontWeight:"normal",backgroundColor: "#fafafa"}}>
                &nbsp;
              </td>
              <td width="49%" style={{fontWeight:"normal",backgroundColor: "#fffff"}}>
              <WorkOnCaseFileCommanderPane
                  level="case"
                  files={listToPreQ.submittedFiles}
                  uiParams = {this.props.uiParams}
                  moveFunction={this.move_toPreQ_to_toIbm.bind(this)}
                  updateBothListsFunction={this.updateList.bind(this)}
                  />
              </td>
            </tr>
          </table>
        </div>
      );
    }

/*      
 * ------------------------------------------------------------
 *  Code supporting asyncProcessDialogsModal =====>
 * ------------------------------------------------------------
 */ 
  if (asyncProcessDialogsModal.active === true) {
    return (
      <div>
        <AsyncProcessDialogs
          asyncProcessDialogsModal = {this.state.asyncProcessDialogsModal}
          functionCloseAsyncModal = {this.closeAsyncModal.bind(this)}
        />
      </div>
    );
  }

/*      
* ------------------------------------------------------------
*  <===== Code supporting asyncProcessDialogsModal
* ------------------------------------------------------------
*/ 


    return(
      <div>
        WorkOnCaseFileCommander Raised Error
      </div>
    );



  }


/*      
 * ------------------------------------------------------------
 *  Code supporting asyncProcessDialogsModal =====>
 * ------------------------------------------------------------
 */ 
async openAsyncModal(asyncAction, tenant, caseid, asyncParameter) {
  //  alert(asyncAction + " : [" + tenant + "] / [" + caseid + "]");
  
    let asyncActionName = CT_ADD_AsyncAction.getTextForCode(asyncAction);
    this.setState({ asyncProcessDialogsModal: {...this.state.asyncProcessDialogsModal, 
      active: true,
      tenant: tenant,
      caseid: caseid,
      code: asyncAction, //see Codetable CT_ADD_AsyncAction
      name : asyncActionName,
      parameter : asyncParameter,
      } 
    }); 
  
  }
  
  async closeAsyncModal() {
  
    //Specifics for this Component.PRE: a tenant delete must be finalized with a sync call to clean up preqsys_process
    // ... none
    
  
    //Standard Code supporting asyncProcessDialogsModal =====>
    this.setState({ asyncProcessDialogsModal: {...this.state.asyncProcessDialogsModal, 
      active: false,
      tenant: null,
      caseid: null,
      code: null,
      name : null,
      parameter : null,
      } 
    });
    //<===== Standard Code supporting asyncProcessDialogsModal 
  
  
    //Specifics for this Component.POST: Update List based on performed changes
    this.updateList()

  
  }
  
  /*      
   * ------------------------------------------------------------
   *  <===== Code supporting asyncProcessDialogsModal
   * ------------------------------------------------------------
   */ 
  
}



export default WorkOnCaseFileCommander;
