import React, { Component } from "react";
import { Modal} from "carbon-components-react";

import { BiPowerOff } from "react-icons/bi";
import { IconContext } from "react-icons";
import DevelopmentTag from "./../../../common//DevelopmentTag";

import Parameter95 from "./../../../archivesV3_001/app/Parameter95";
import Parameter100 from "./../../../archivesV3_001/app/Parameter100";
import Parameter200 from "./../../../contenttypesV3_001/app/Parameter200";
import Parameter300 from "./../../../convertersV3_001/app/Parameter300";
import Parameter5000 from "./../../../harvesterV3_001/app/Parameter5000";
import Parameter5100 from "./../../../anonymizersV3_001/app/Parameter5100";
import Parameter5500 from "./../../../postprocessingV3_001/app/Parameter5500";
import Parameter5600 from "./../../../postprocessingV3_001/app/Parameter5600";
import Parameter6100 from "./../../../anonymizersV3_001/app/Parameter6100";
import Parameter6200 from "./../../../anonymizersV3_001/app/Parameter6200";
import Parameter9650 from "./../../../anonymizersV3_001/app/Parameter9650";
import Parameter9700 from "./../../../anonymizersV3_001/app/Parameter9700";
import Parameter9720 from "./../../../anonymizersV3_001/app/Parameter9720";
import Parameter9750 from "./../../../anonymizersV3_001/app/Parameter9750";
import Parameter9800 from "./../../../archivesV3_001/app/Parameter9800";
import Parameter9955 from "./../../../archivesV3_001/app/Parameter9955"; 

import * as CT_ADD_DisplayRightType from  "../../../commonxV3_001/codetable/CT_ADD_DisplayRightType";
import WorkOnCaseUiDisplayRightParameterUiStartProcessButton from "./WorkOnCaseUiDisplayRightParameterUiStartProcessButton";
import DevelopmentDisplayWorkOnCaseUiDisplayRightParameterUi from "./DevelopmentDisplayWorkOnCaseUiDisplayRightParameterUi";
import * as PreQV3Constants from "./../../../commonxV3_001/PreQV3Constants";
import * as restClient from "./../../../adminV3_001/backend/RestClient";
import * as pojoIdentifier from "../../../commonxV3_001/ServerResponsePojoIdentifier";
import ServerSyncCallResultDisplayUi from "./../../../commonxV3_001/ServerSyncCallResultDisplayUi";
import * as RestClient from "./../../backend/RestClient";
import * as environmentService from "./../../../EnvironmentService";



class WorkOnCaseUiDisplayRightParameterUi extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {

        modalShowUi : false,
        modalDialogError: null,
        listToPreQ: []
  
      };
  }

  async componentDidMount() {
    this.updateList();
  }

  async updateList() {

    let tenant = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_ORG_UNIT);
    let caseid = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_CASE);

    let lstToPrq = await RestClient.getFileListTenantCaseToPreQ(tenant, caseid);


    this.setState(
      {
        listToPreQ: lstToPrq.submittedFiles
      }
    ); 
  }

  render() {

    const { modalShowUi, modalDialogError } = this.state;
    const {listFetchObj} = this.props;

    let row = this.props.row;
    let displayRightType = this.props.displayRightType;
    let displayRightList = this.props.displayRightList;
    let showStartBatchButton = this.props.showStartBatchButton;
    let preQInputFilesAmount = this.state.listToPreQ.length;





    let headName = "???";
    let modalHeading = "???";
    if (displayRightType === CT_ADD_DisplayRightType.CODE.MANUALLY_PARAMETER_ONESTEP) {
      headName = "Parameter for Single Step";
      modalHeading = "Single Step"
    }
    if (displayRightType === CT_ADD_DisplayRightType.CODE.MANUALLY_PARAMETER_SEQUENCE) {
      headName = "Parameters for Step-Sequence";
      modalHeading = "Step-Sequnence"
    }
    let devTagName = this.constructor.name + ' WITH ==> displayRightType: ' + displayRightType + ' , showStartBatchButton: ' + showStartBatchButton; 

    if (preQInputFilesAmount < 1) {
      return (
        <div>
          <DevelopmentTag uiParams = {this.props.uiParams} devTagLabel = {devTagName}/>
          <table width="100%">
          <tr>
          <td width="3%">
            <div onClick={this.handleReset.bind(this)}>
              <IconContext.Provider value={{className:"preQ_SME_icon_process_status_JS_PROCESS_CORRUPTED"}}>
                  <BiPowerOff size="22"/>
              </IconContext.Provider>
            </div>
          </td>
          <td width="97%">
              <h6>No Files available in Case Data Input ( /toPreQ )</h6>
            </td>
          </tr>
         </table> 
        </div>
      );

    }


    if (modalShowUi === false) {

      return (
        <div>
        <DevelopmentTag uiParams = {this.props.uiParams} devTagLabel = {devTagName}/>
         <table width="100%">
          <tr>
          <td width="3%">
            <div onClick={this.handleReset.bind(this)}>
              <IconContext.Provider value={{className:"preQ_SME_icon_process_status_JS_PROCESS_CORRUPTED"}}>
                  <BiPowerOff size="22"/>
              </IconContext.Provider>
            </div>
          </td>
          <td width="97%">
              <h6>{headName}</h6>
            </td>
          </tr>
         </table>
          <br/>
          <hr/>
          {displayRightList.map((entry) => (
            <div>{entry.businessIdentifier} 
              { entry.sequenceindex === 95 
                ? <Parameter95 sequenceindex = {entry.sequenceindex} uiParams = {this.props.uiParams} entry={entry} updateUserParameter = {this.props.updateUserParameter} resetUserParameter = {this.props.resetUserParameter} getDisplayListEntry = {this.props.getDisplayListEntry} listFetchObj = {listFetchObj}/> 
                : <div></div>
              }
              { entry.sequenceindex === 100 
                ? <Parameter100 sequenceindex = {entry.sequenceindex} uiParams = {this.props.uiParams} entry={entry} updateUserParameter = {this.props.updateUserParameter} resetUserParameter = {this.props.resetUserParameter} getDisplayListEntry = {this.props.getDisplayListEntry} listFetchObj = {listFetchObj}/> 
                : <div></div>
              }
              { entry.sequenceindex === 200 
                ? <Parameter200 sequenceindex = {entry.sequenceindex} uiParams = {this.props.uiParams} entry={entry} updateUserParameter = {this.props.updateUserParameter} resetUserParameter = {this.props.resetUserParameter} getDisplayListEntry = {this.props.getDisplayListEntry} listFetchObj = {listFetchObj}/> 
                : <div></div>
              }
              { entry.sequenceindex === 300 
                ? <Parameter300 sequenceindex = {entry.sequenceindex} uiParams = {this.props.uiParams} entry={entry} updateUserParameter = {this.props.updateUserParameter} resetUserParameter = {this.props.resetUserParameter} getDisplayListEntry = {this.props.getDisplayListEntry} listFetchObj = {listFetchObj}/> 
                : <div></div>
              }
              { entry.sequenceindex === 5000 
                ? <Parameter5000 sequenceindex = {entry.sequenceindex} uiParams = {this.props.uiParams} entry={entry} updateUserParameter = {this.props.updateUserParameter} resetUserParameter = {this.props.resetUserParameter} getDisplayListEntry = {this.props.getDisplayListEntry} listFetchObj = {listFetchObj}/> 
                : <div></div>
              }
              { entry.sequenceindex === 5100 
                ? <Parameter5100 sequenceindex = {entry.sequenceindex} uiParams = {this.props.uiParams} entry={entry} updateUserParameter = {this.props.updateUserParameter} resetUserParameter = {this.props.resetUserParameter} getDisplayListEntry = {this.props.getDisplayListEntry} listFetchObj = {listFetchObj}/> 
                : <div></div>
              }
              { entry.sequenceindex === 5500 
                ? <Parameter5500 sequenceindex = {entry.sequenceindex} uiParams = {this.props.uiParams} entry={entry} updateUserParameter = {this.props.updateUserParameter} resetUserParameter = {this.props.resetUserParameter} getDisplayListEntry = {this.props.getDisplayListEntry} listFetchObj = {listFetchObj}/> 
                : <div></div>
              }
              { entry.sequenceindex === 5600 
                ? <Parameter5600 sequenceindex = {entry.sequenceindex} uiParams = {this.props.uiParams} entry={entry} updateUserParameter = {this.props.updateUserParameter} resetUserParameter = {this.props.resetUserParameter} getDisplayListEntry = {this.props.getDisplayListEntry} listFetchObj = {listFetchObj}/> 
                : <div></div>
              }
              { entry.sequenceindex === 6100 
                ? <Parameter6100 sequenceindex = {entry.sequenceindex} uiParams = {this.props.uiParams} entry={entry} updateUserParameter = {this.props.updateUserParameter} resetUserParameter = {this.props.resetUserParameter} getDisplayListEntry = {this.props.getDisplayListEntry} listFetchObj = {listFetchObj}/> 
                : <div></div>
              }
              { entry.sequenceindex === 6200 
                ? <Parameter6200 sequenceindex = {entry.sequenceindex} uiParams = {this.props.uiParams} entry={entry} updateUserParameter = {this.props.updateUserParameter} resetUserParameter = {this.props.resetUserParameter} getDisplayListEntry = {this.props.getDisplayListEntry} listFetchObj = {listFetchObj}/> 
                : <div></div>
              }
              { entry.sequenceindex === 9650 
                ? <Parameter9650 sequenceindex = {entry.sequenceindex} uiParams = {this.props.uiParams} entry={entry} updateUserParameter = {this.props.updateUserParameter} resetUserParameter = {this.props.resetUserParameter} getDisplayListEntry = {this.props.getDisplayListEntry} listFetchObj = {listFetchObj}/> 
                : <div></div>
              }
              { entry.sequenceindex === 9700 
                ? <Parameter9700 sequenceindex = {entry.sequenceindex} uiParams = {this.props.uiParams} entry={entry} updateUserParameter = {this.props.updateUserParameter} resetUserParameter = {this.props.resetUserParameter} getDisplayListEntry = {this.props.getDisplayListEntry} listFetchObj = {listFetchObj}/> 
                : <div></div>
              }
              { entry.sequenceindex === 9720 
                ? <Parameter9720 sequenceindex = {entry.sequenceindex} uiParams = {this.props.uiParams} entry={entry} updateUserParameter = {this.props.updateUserParameter} resetUserParameter = {this.props.resetUserParameter} getDisplayListEntry = {this.props.getDisplayListEntry} listFetchObj = {listFetchObj}/> 
                : <div></div>
              }
              { entry.sequenceindex === 9750 
                ? <Parameter9750 sequenceindex = {entry.sequenceindex} uiParams = {this.props.uiParams} entry={entry} updateUserParameter = {this.props.updateUserParameter} resetUserParameter = {this.props.resetUserParameter} getDisplayListEntry = {this.props.getDisplayListEntry} listFetchObj = {listFetchObj}/> 
                : <div></div>
              }
              { entry.sequenceindex === 9800 
                ? <Parameter9800 sequenceindex = {entry.sequenceindex} uiParams = {this.props.uiParams} entry={entry} updateUserParameter = {this.props.updateUserParameter} resetUserParameter = {this.props.resetUserParameter} getDisplayListEntry = {this.props.getDisplayListEntry} listFetchObj = {listFetchObj}/> 
                : <div></div>
              }
              { entry.sequenceindex === 9955 
                ? <Parameter9955 sequenceindex = {entry.sequenceindex} uiParams = {this.props.uiParams} entry={entry} updateUserParameter = {this.props.updateUserParameter} resetUserParameter = {this.props.resetUserParameter} getDisplayListEntry = {this.props.getDisplayListEntry} listFetchObj = {listFetchObj}/> 
                : <div></div>
              }
            </div>
          ))}
          <WorkOnCaseUiDisplayRightParameterUiStartProcessButton
            showStartBatchButton = {this.props.showStartBatchButton}
            displayRightType = {this.props.displayRightType}
            uiParams = {this.props.uiParams} 
            preQInputFilesAmount = {preQInputFilesAmount}
            handleModalOpen = {this.handleModalOpen.bind(this)}
          />
        </div>
      );
  
    }

    if (modalShowUi === true) {

      return (
        <div>
          <Modal
          modalHeading={modalHeading}
          open
          primaryButtonText="Start"
          secondaryButtonText="Cancel"
          onRequestSubmit={e => this.handleModalClose("")}
          onSecondarySubmit={e => this.handleModalClose(null)}
          >
            <div>
              {displayRightList.map((entry) => (
                <div>
                  {entry.businessIdentifier} 
                </div>
              ))}              
            </div>
            <ServerSyncCallResultDisplayUi 
              resultToDisplay = {modalDialogError}
            />
  
          </Modal> 
        </div>
      );

    }

  }


  async handleModalOpen() {
    this.setState({ modalShowUi : true, modalDialogError: null});
  }

  /**
   * This method reacts on [Save] or [Cancel] the modal Dialog 
   * @param {*} event 
   */
     async handleModalClose(event) {

      if (event === null) { //[Cancel]
        this.setState({ modalShowUi : false, modalDialogError: null});
      
      } else { // [Start]
        let displayRightType = this.props.displayRightType;
        let displayArray = this.props.getDisplayListArray();

        let callTenant = this.props.uiGetCurrentTenant();
        let callCaseid = this.props.uiGetCurrentCase();
        let callModule = "";
        let callEndPoint = "";
        let callParameter = "";

//        if (displayRightType === CT_ADD_DisplayRightType.CODE.MANUALLY_PARAMETER_ONESTEP) {

//          callModule = displayArray[0].chainEntry.moduleName
//          callEndPoint = displayArray[0].chainEntry.endPointProcessTrigger;
//          callEndPoint = callEndPoint.replace(PreQV3Constants.CONSTANTS.PE_PROCESS_TRIGGER_IN_SEQUENCE, PreQV3Constants.CONSTANTS.PE_PROCESS_TRIGGER)
//          callParameter = displayArray[0].selectedUiParameters;


//        }
//        if (displayRightType === CT_ADD_DisplayRightType.CODE.MANUALLY_PARAMETER_SEQUENCE) {

          callModule = PreQV3Constants.CONSTANTS.MI_TENANT;
          callEndPoint = PreQV3Constants.CONSTANTS.RU_START_SEQUENCING;

          var sequence = {
            step: []
          };
          for (let i = 0; i < displayArray.length; i++) {
            let currentDisplayArrayEntry = displayArray[i];
            let entryObj = {
              sequenceindex : currentDisplayArrayEntry.sequenceindex,
              parameter : currentDisplayArrayEntry.selectedUiParameters,
            }
            sequence.step.push(entryObj);
          }
          let seqObj = {
            sequence : sequence,
          }
          callParameter = JSON.stringify(seqObj);

//        }


        let data_FromServer = await restClient.triggerProcess(callTenant, callCaseid, callModule , callEndPoint, callParameter);
        let isError = pojoIdentifier.isPojoErrorResult(data_FromServer);
//        let isError = false;
        if (isError) {
          this.setState({ modalDialogError: data_FromServer });
        } else {
          this.setState({ modalShowUi : false, modalDialogError: null});
          let dTrigger = new Date();
          sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_LATEST_SEQUENCE_TRIGGER_TIME, dTrigger.getTime().toString());
          sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_LATEST_SEQUENCE_TRIGGER_TIME_CLEAR_TEXT, dTrigger.toString());
          this.handleResetSetAutoUpdateTrue();
        }

      }
    }
  



  handleReset() {
    this.props.resetDisplayRightSettings(null);
  }

  handleResetSetAutoUpdateTrue() {
    this.props.resetDisplayRightSettings(true);
  }

}

export default WorkOnCaseUiDisplayRightParameterUi;
