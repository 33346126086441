import React, { Component } from "react";

import { IconContext } from "react-icons";
import { BsExclamationDiamondFill, BsExclamationTriangleFill, BsFillTrash3Fill } from "react-icons/bs";
import * as CT_ErrorCodes from "./../../commonxV3_001/codetable/CT_ErrorCodes";
import * as PreQV3Constants from "./../../commonxV3_001/PreQV3Constants";
import { Button } from '@carbon/react';


class AllPreqXceptionsEntry extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  async refresh() {
  }

  async doDeleteEntry() {
    let timeStamp = this.props.preQException.lTimeStamp;
    var stimeStamp = timeStamp.toString();
    this.props.deleteByTimeStampAndRefresh(stimeStamp);
  }

  async doDeleteCategory() {
    let errorCode = this.props.preQException.errorCode;
    this.props.deleteByErrorCodeAndRefresh(errorCode);
  }

  render() {

    const { preQException} = this.props;
    let preqKey = PreQV3Constants.getPreqKey();
    let indXA = preqKey.startsWith('xa')
    let showDeleteIcons = (preQException.relevance !== "SYSTEM") && indXA;

    let errorCode = preQException.errorCode;
    let hint = CT_ErrorCodes.getHintForCode(errorCode);


    let cssRowStyle ="preQ_PreQException_background_wama";

    let logLink = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
    + "/RESTServiceCall/UI/deliverLogFile/"
    + preQException.urlToLogFile;
    let logFileName = "logFile.log";

    return (
      <>
      <tr  class={cssRowStyle}>
        <td  style={{borderTop: "1px solid #d0d0d0",  width:"5%", verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px", fontSize:"90%"}}>
        { (preQException.critical === true)
            ? 
              <IconContext.Provider value={{className:"preQ_PreQException_icon_CRITICAL_sme"}}>
                <BsExclamationDiamondFill size="24"/>
              </IconContext.Provider>
            : 
              <IconContext.Provider value={{className:"preQ_PreQException_icon_UNCRITICAL_sme"}}>
                <BsExclamationTriangleFill size="24"/>
              </IconContext.Provider>
             }
        </td>
        <td  style={{borderTop: "1px solid #d0d0d0",  width:"15%", verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px", fontSize:"90%"}}>
        ErrorCode: {preQException.errorCode}

        {
         (showDeleteIcons === true) 
           ?
           <>
              <br/>
              <br/>
                &nbsp;
                <IconContext.Provider value={{className:"preQ_PreQException_delete_entry"}}>
                      <BsFillTrash3Fill size="14" onClick={this.doDeleteEntry.bind(this)}/>
                </IconContext.Provider> this
                &nbsp;
                <IconContext.Provider value={{className:"preQ_PreQException_delete_entry"}}>
                      <BsFillTrash3Fill size="14"onClick={this.doDeleteCategory.bind(this)}/>
                </IconContext.Provider> similar(s)
           </>
           :
            <>
                &nbsp;
            </>
          }


        </td>             
        <td style={{borderTop: "1px solid #d0d0d0", width:"80%", verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px", fontSize:"90%"}}>
          {
         (preQException.relevance === "SYSTEM") 
           ?
           <>
           {preQException.relevance}&nbsp;:&nbsp;&nbsp;{preQException.errorText}
           </>
           :
           <>
           {preQException.errorText}
           </>
          }
          
          <br/>

          {(indXA === true) 
          ?
            <>
              <br/>
              TimeStamp: {preQException.timeStamp}&nbsp;&nbsp;&nbsp;&nbsp;({preQException.lTimeStamp})&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;in&nbsp;&nbsp;&nbsp;
              <a
                      href={logLink}
                      download ={logFileName}
                      rel="noopener"
                      aria-label='.log'
                      >
                      .log
                      </a>
            </>
          :
            <>
              <br/>
              TimeStamp: {preQException.timeStamp}
            </>
          }
          <br/>
          {preQException.dtls}

          <br/>
          {hint}

          <br/>&nbsp;
        </td>
      </tr>             
    

    </>    );
  }
}

export default AllPreqXceptionsEntry;
