import React, { Component } from "react";

import { Loading } from "carbon-components-react";
import DevelopmentTag from "./../../../common//DevelopmentTag";
import WorkOnCaseUiDisplayRightResultUi from "./WorkOnCaseUiDisplayRightResultUi";
import WorkOnCaseUiDisplayRightParameterUi from "./WorkOnCaseUiDisplayRightParameterUi";
import * as CT_ADD_DisplayRightType from  "../../../commonxV3_001/codetable/CT_ADD_DisplayRightType";
import * as CT_ProcessingPhase from  "./../../../commonxV3_001/codetable/CT_ProcessingPhase"
import * as CT_ProcessStatus from  "./../../../commonxV3_001/codetable/CT_ProcessStatus"
import * as restClient from "./../../../adminV3_001/backend/RestClient";
import AHrefWithPleaseWait from "./../../../commonxV3_001/app/AHrefWithPleaseWait";

class WorkOnCaseUiDisplayRightUi extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        error: null,
        isLoading: true,
        moduleUrls:null,
        };
  }

  async componentDidMount() {
    this.updateInfo();
  }

  async updateInfo() {
    this.setState({ isLoading: true });

    //    let listFetchObj = null;

    let moduleUrls = await restClient.getModuleBaseUrlsPlusTenant();

    //Update State
    this.setState({ isLoading: false, moduleUrls: moduleUrls, error: null });
  }

  render() {

    const { moduleUrls, isLoading, error } = this.state;
    const {listFetchObj, logLinkSeqLog, logFileNameSeqLog, logLinkReport, logFileNameReport} = this.props;


    if (isLoading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }


    let autoUpdate = this.props.autoUpdate;
    let autoUpdateCurrentRunningProcess = this.props.autoUpdateCurrentRunningProcess;
    let autoUpdateCurrentRunningProcessChainEntry = this.props.autoUpdateCurrentRunningProcessChainEntry;

    let currentTenant=this.props.uiGetCurrentTenant();
    let currentCase=this.props.uiGetCurrentCase();
    let displayRightType = this.props.displayRightType;
    let displayRightList = this.props.displayRightList;
    let showStartBatchButton = this.props.showStartBatchButton;


    let devTagName = this.constructor.name + ' WITH ==> displayRightType: ' + displayRightType; 
    if (autoUpdate === true) {
      if (autoUpdateCurrentRunningProcess === null) {
        return (
          <div>&nbsp;</div>
        );
      } else {
        return (
          <div>
            <DevelopmentTag uiParams = {this.props.uiParams} devTagLabel = {devTagName} />
            <WorkOnCaseUiDisplayRightResultUi 
              row = {autoUpdateCurrentRunningProcess}
              chainEntry = {autoUpdateCurrentRunningProcessChainEntry}  
              moduleUrls = {this.state.moduleUrls}
              resetDisplayRightSettings = {null}
            />
          </div>
        );
      }
    }
    if (displayRightType === CT_ADD_DisplayRightType.CODE.MANUALLY_RESULT) {
      let displayRightList = this.props.displayRightList;
      let row = displayRightList[1]; //preqsys_process-Object
      let chainEntry = displayRightList[2];
        return (
        <div>
          <DevelopmentTag uiParams = {this.props.uiParams} devTagLabel = {devTagName}/>
          <WorkOnCaseUiDisplayRightResultUi 
            row = {row}
            chainEntry = {chainEntry}
            moduleUrls = {this.state.moduleUrls}
            resetDisplayRightSettings = {this.props.resetDisplayRightSettings}
            uiParams = {this.props.uiParams}
            />
        </div>
      );
  
    }

    if (displayRightType === CT_ADD_DisplayRightType.CODE.MANUALLY_PARAMETER_SEQUENCE) {
      let displayRightList = this.props.displayRightList;
        let row = displayRightList[0];
        return (
        <div>
          <DevelopmentTag uiParams = {this.props.uiParams} devTagLabel = {devTagName}/>
          <WorkOnCaseUiDisplayRightParameterUi 
            row = {row}
            uiGetCurrentTenant={this.props.uiGetCurrentTenant}
            uiGetCurrentCase={this.props.uiGetCurrentCase}
            resetDisplayRightSettings = {this.props.resetDisplayRightSettings}
            displayRightType = {displayRightType}
            displayRightList = {displayRightList}
            showStartBatchButton = {this.props.showStartBatchButton}
            uiParams = {this.props.uiParams}
            getDisplayListEntry = {this.props.getDisplayListEntry}
            updateUserParameter = {this.props.updateUserParameter}
            resetUserParameter = {this.props.resetUserParameter}
            getDisplayListArray = {this.props.getDisplayListArray}
            listFetchObj = {listFetchObj}
            />
        </div>
      );
  
    }


    if (displayRightType === CT_ADD_DisplayRightType.CODE.MANUALLY_PARAMETER_ONESTEP) {
      let displayRightList = this.props.displayRightList;
        let row = displayRightList[0];
        return (
        <div>
          <DevelopmentTag uiParams = {this.props.uiParams} devTagLabel = {devTagName}/>
          <WorkOnCaseUiDisplayRightParameterUi 
            row = {row}
            uiGetCurrentTenant={this.props.uiGetCurrentTenant}
            uiGetCurrentCase={this.props.uiGetCurrentCase}
            resetDisplayRightSettings = {this.props.resetDisplayRightSettings}
            displayRightType = {displayRightType}
            displayRightList = {displayRightList}
            showStartBatchButton = {this.props.showStartBatchButton}
            uiParams = {this.props.uiParams}
            getDisplayListEntry = {this.props.getDisplayListEntry}
            updateUserParameter = {this.props.updateUserParameter}
            resetUserParameter = {this.props.resetUserParameter}
            getDisplayListArray = {this.props.getDisplayListArray}
            listFetchObj = {listFetchObj}
            />
        </div>
      );
  
    }

    let indShowEmptyPage = this.showEmptyPage(listFetchObj);
    let indShowReviewPage = false;

    if (indShowEmptyPage === true) {
      return(
        <div>
          <table width = "100%">
            <tr>
              <td width = "33%">
                &nbsp;
              </td>
              <td width = "33%">
                <img src="/preq_images/PreqLogo.jpg" with="550px" height="200px"/><br /><br/>
                <br />
                <br />
              </td>
              <td width = "33%">
                &nbsp;
              </td>
            </tr>
          </table>
        </div>
      );
    } else {
      return(
        <div>
          <table width = "100%">
            <tr>
              <td width = "10%">
                &nbsp;
              </td>
              <td width = "47%">
                All Logs of Case-Processing<br/>(as .zip-File)<br/>
                &nbsp;
              </td>
              <td width = "43%">
                <a
                  href={logLinkSeqLog}
                  download ={logFileNameSeqLog}
                  rel="noopener"
                  aria-label='Download'
                  >
                Download
                </a>
              </td>
            </tr>
            <tr>
              <td width = "10%">
                &nbsp;
              </td>
              <td width = "47%">
                Case Report (english)<br/>(based on current Processing Status)<br/>
                &nbsp;
              </td>
              <td width = "43%">
                <AHrefWithPleaseWait
                  logLinkReport = {logLinkReport + "/1"} 
                  logFileNameReport = {logFileNameReport}
                  downloadLabel = 'Download'
                />
              </td>
            </tr>
          </table>
        </div>
      );
  
    }

  }

  showEmptyPage(listFetchObj) {

    if (listFetchObj === null) {
      return true;
    }
    if (listFetchObj === undefined) {
      return true;
    }
    if (listFetchObj.steadyFlowStatus === null) {
      return true;
    }
    if (listFetchObj.steadyFlowStatus === undefined) {
      return true;
    }

    let completeUnTouchedPhases = listFetchObj.completeUnTouchedPhases;
    let indUntouchedPre = completeUnTouchedPhases.includes(CT_ProcessingPhase.CODE.JS_PRE)
    if (indUntouchedPre === true) {
      return true;
    }
    return false;
  }


  // showReviewElements(listFetchObj) {
  //   let phases = listFetchObj.steadyFlowStatus.phases;
  //   let matchStatus = phases[1].status;
  //   let indMatchingDone = false;
  //   if (matchStatus === CT_ProcessStatus.CODE.JS_PROCESS_DONE) {
  //     indMatchingDone = true;
  //   }
  //   let seqIndex = this.identifySequenceIdOfFirstUndoneStep(listFetchObj);
  //   let indNextStepIsAnonymizing = (seqIndex === 9700);
  //   let result = indMatchingDone && indNextStepIsAnonymizing;
  //   return result;
  // }

  // identifySequenceIdOfFirstUndoneStep(listFetchObj) {
  //   let steps = listFetchObj.chain;
  //   for (let i = 0; i < steps.length; i++) {
  //     let step = steps[i];
  //     if (step.status !== CT_ProcessStatus.CODE.JS_PROCESS_DONE) {
  //       return step.sequenceindex;
  //     }
  //   } 
  //   return -1;
  // }


}

export default WorkOnCaseUiDisplayRightUi;
