import * as environmentService from "./../../EnvironmentService";
import * as PreQV3Constants from "./../../commonxV3_001/PreQV3Constants";
import * as CT_ADD_AsyncAction from "../../commonxV3_001/codetable/CT_ADD_AsyncAction";
import * as CT_ADD_ModuleNames from "../../commonxV3_001/codetable/CT_ADD_ModuleNames";

const HttpCodes = {
  OK : 200,
  BAD_REQUEST : 400,
  INTERNAL_SERVER_ERROR : 500,

}

/**
 * This method is eplicit an only for display of ModuleList in Admin UI and in Dialoig Versions & Settings
 * React is explicitly NOT listed
 */
export async function getModuleInfoToVisualizeInAdminUi() {
  let preResult = await this.getModuleBaseUrlsPlusTenant();
  let result = []
  for (let i = 0; i < preResult.length; i++) {
    let preResultEntry = preResult[i];
    let identifier = preResultEntry.identifier;
    if (identifier !== 'React') {
      result.push(preResultEntry);
    }
  }
  return result;
}

/**
 * 
 * @returns This method delivers the links to all Modules (Processing Modules PLUS Tenant-Module )
 */
export async function getModuleBaseUrlsPlusTenant() {
  let tenantBaseUrl = await environmentService.getTenantBaseUrl();
  let sUrl = tenantBaseUrl + "/moduleUrls";
  let response_FromServer = await fetch(sUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json, text/plain',
        'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
        'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
      }
     });

  if (!response_FromServer.ok) {
    return null;
  }

  let tenantLine = 
  '{ "identifier":"' + PreQV3Constants.CONSTANTS.MI_TENANT + '" , "baseUrl":"' + tenantBaseUrl + '"}'; 
  let jsonTenant = JSON.parse(tenantLine); 
  
  
  let data_FromServer = await response_FromServer.json();
  environmentService.log("data_FromServer", data_FromServer);

  let result = data_FromServer.moduleUrls;

  for (let i = 0; i < result.length; i++) {
    let currentEntry = result[i];
    let currentIdentifiyer = result[i].identifier;
    let calculatedUrl = await environmentService.getModuleBaseUrl(currentIdentifiyer);
    result[i].baseUrl = calculatedUrl;
  } 
//  result.push(jsonTenant)
  environmentService.log("result", result);

  return result;
}

/**
 * @returns This method delivers the Process Chain (as identified by the Tenant-Module)
 */
export async function getProcessSequenceChain() {
  let tenantBaseUrl = await environmentService.getTenantBaseUrl();
  let sUrl = tenantBaseUrl + "/preq/sbv3/service/tenant/config/sequence/details";
  let response_FromServer = await fetch(sUrl, {
    method: 'GET',
    headers: {
      'Accept': 'application/json, text/plain',
      'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
      'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
    }
   });

  if (!response_FromServer.ok) {
    return null;
  }

  let data_FromServer = await response_FromServer.json();
  environmentService.log("data_FromServer", data_FromServer);

  let result = data_FromServer.chain;
  environmentService.log("result", result);

  return result;
}

/**
 * This method delivers the information about a Module retreiving it from '/module'
 * @param {*} baseUrl 
 * @returns 
 */
export async function getModuleInfo(baseUrl) {
  let sUrl = baseUrl + "/module";
  let response_FromServer = await fetch(sUrl, {
    method: 'GET',
    headers: {
      'Accept': 'application/json, text/plain',
      'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
      'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
    }
   });

  if (!response_FromServer.ok) {
    return null;
  }
  let data_FromServer = await response_FromServer.json();
  environmentService.log("data_FromServer", data_FromServer);

  return data_FromServer;
}


/**
 * This method delivers information about configuration from a 
 * configuration url. This Url is usually an url ending with '/config'
 * @param {*} url 
 */
export async function getConfiguration(url) {

  let response_FromServer = await fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json, text/plain',
      'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
      'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
    }
   });

  if (!response_FromServer.ok) {
    return null;
  }

  let data_FromServer = await response_FromServer.json();
  environmentService.log("data_FromServer", data_FromServer);

  let result = data_FromServer.coniguration;
  environmentService.log("result", result);

  return result;

}

/**
 * This method is used to trigger async processes, which are NOT part of sequence chain
 * @param {*} callTenant 
 * @param {*} callCaseid 
 * @param {*} callAsyncAction 
 * @param {*} callParameter 
 * @returns 
 */
export async function triggerParticularProcess(callTenant, callCaseid, callAsyncAction, callParameter) {

  let objCall = {
    callTenant: callTenant,
    callCaseid: callCaseid,
    callAsyncAction : callAsyncAction,
    callParameter : callParameter
  }
  environmentService.log('', objCall);

  let callModul = null;
  let callEndPoint = null;

  switch(callAsyncAction) {
    case CT_ADD_AsyncAction.CODE.JS_DELETE_CASE:
      // /preq/sbv3/service/tenant/tenantdelete/trigger/{tenant}
      callModul = CT_ADD_ModuleNames.CODE.JS_MODULE_TENANT;
      callEndPoint = "/preq~sbv3~service~tenant~casedelete~trigger";
      break;
    case CT_ADD_AsyncAction.CODE.JS_DELETE_TENANT:
      // /preq/sbv3/service/tenant/casedelete/trigger/{tenant}/{caseid}
      callModul = CT_ADD_ModuleNames.CODE.JS_MODULE_TENANT;
      callEndPoint = "/preq~sbv3~service~tenant~tenantdelete~trigger";
      break;
    case CT_ADD_AsyncAction.CODE.JS_RESET_CASE:
      // /preq/sbv3/service/tenant/casereset/trigger/{tenant}/{caseid}
      callModul = CT_ADD_ModuleNames.CODE.JS_MODULE_TENANT;
      callEndPoint = "/preq~sbv3~service~tenant~casereset~trigger";
      break;
    case CT_ADD_AsyncAction.CODE.JS_MOVE_FILES_SRC_TOIBM_DEST_TOPREQ:
       // /preq/sbv3/service/tenant/inputdatamovetocaselevel/trigger 
       callModul = CT_ADD_ModuleNames.CODE.JS_MODULE_TENANT;
       callEndPoint = "/preq~sbv3~service~tenant~inputdatamovetocaselevel~trigger"
       break;
    case CT_ADD_AsyncAction.CODE.JS_MOVE_FILES_SRC_TOOREQ_DEST_TOIBM:
      // /preq/sbv3/service/tenant/inputdatamovetotenatlevel/trigger
      callModul = CT_ADD_ModuleNames.CODE.JS_MODULE_TENANT;
      callEndPoint = "/preq~sbv3~service~tenant~inputdatamovetotenatlevel~trigger"
      break;
    case CT_ADD_AsyncAction.CODE.JS_MOVE_FILES_SRC_FROMPREQ_DEST_FROMIBM:
        // /preq/sbv3/service/tenant/deploytofromibm/trigger
        callModul = CT_ADD_ModuleNames.CODE.JS_MODULE_TENANT;
        callEndPoint = "/preq~sbv3~service~tenant~deploytofromibm~trigger"
        break;
    case CT_ADD_AsyncAction.CODE.JS_CREATE_REVIEW_FILE_EXPORT:
      // /preq/sbv3/service/anonymizers/reviewfileexport/trigger/{tenant}/{caseid}
      callModul = CT_ADD_ModuleNames.CODE.JS_MODULE_ANONYMIZERS;
      callEndPoint = "/preq~sbv3~service~anonymizers~reviewfileexport~trigger"
      break;
      case CT_ADD_AsyncAction.CODE.JS_CHECK_REVIEW_FILE_IMPORT:
        // /preq/sbv3/service/anonymizers/reviewfileimport/trigger/{tenant}/{caseid}
        callModul = CT_ADD_ModuleNames.CODE.JS_MODULE_ANONYMIZERS;
        callEndPoint = "/preq~sbv3~service~anonymizers~reviewfileimport~trigger"
        break;
      default:
      // code block
  } 

  objCall = {
    callTenant: callTenant,
    callCaseid: callCaseid,
    callModul : callModul,
    callEndPoint : callEndPoint,
    callParameter : callParameter
  }
  environmentService.log('', objCall);

  environmentService.log('', 'do it');

  let result = await triggerProcess(callTenant, callCaseid, callModul, callEndPoint, callParameter);
  return result;
}

/**
 * This method is used to retrieve status of async processes, which are NOT part of sequence chain
 * @param {*} callTenant 
 * @param {*} callCaseid 
 * @param {*} callAsyncAction 
 */
export async function getStatusOfParticularPocess(callTenant, callCaseid, callAsyncAction, processId) {

  let callModul = null;
  let callEndPoint = null;

  switch(callAsyncAction) {
    case CT_ADD_AsyncAction.CODE.JS_DELETE_CASE:
      // /preq/sbv3/service/tenant/casedelete/status/{processID}
      callModul = CT_ADD_ModuleNames.CODE.JS_MODULE_TENANT;
      callEndPoint = "/preq/sbv3/service/tenant/casedelete/status/" + processId; 
      break;
    case CT_ADD_AsyncAction.CODE.JS_DELETE_TENANT:
      // /preq/sbv3/service/tenant/tenantdelete/status/{processID}
      callModul = CT_ADD_ModuleNames.CODE.JS_MODULE_TENANT;
      callEndPoint = "/preq/sbv3/service/tenant/tenantdelete/status/" + processId;
      break;
    case CT_ADD_AsyncAction.CODE.JS_RESET_CASE:
      // /preq/sbv3/service/tenant/casereset/status/{processID}
      callModul = CT_ADD_ModuleNames.CODE.JS_MODULE_TENANT;
      callEndPoint = "/preq/sbv3/service/tenant/casereset/status/" + processId; 
      break;
    case CT_ADD_AsyncAction.CODE.JS_MOVE_FILES_SRC_TOIBM_DEST_TOPREQ:
      // /preq/sbv3/service/tenant/inputdatamovetocaselevel/status/{processID}
      callModul = CT_ADD_ModuleNames.CODE.JS_MODULE_TENANT;
      callEndPoint = "/preq/sbv3/service/tenant/inputdatamovetocaselevel/status/" + processId;
      break;
    case CT_ADD_AsyncAction.CODE.JS_MOVE_FILES_SRC_TOOREQ_DEST_TOIBM:
      // /preq/sbv3/service/tenant/inputdatamovetotenatlevel/status/{processID}
      callModul = CT_ADD_ModuleNames.CODE.JS_MODULE_TENANT;
      callEndPoint = "/preq/sbv3/service/tenant/inputdatamovetotenatlevel/status/" + processId;
      break;
    case CT_ADD_AsyncAction.CODE.JS_MOVE_FILES_SRC_FROMPREQ_DEST_FROMIBM:
        // /preq/sbv3/service/tenant/deploytofromibm/status/{processID}
        callModul = CT_ADD_ModuleNames.CODE.JS_MODULE_TENANT;
        callEndPoint = "/preq/sbv3/service/tenant/deploytofromibm/status/" + processId;
        break;
    case CT_ADD_AsyncAction.CODE.JS_CREATE_REVIEW_FILE_EXPORT:
      // /preq/sbv3/service/anonymizers/reviewfileexport/status/{processID}
      callModul = CT_ADD_ModuleNames.CODE.JS_MODULE_ANONYMIZERS;
      callEndPoint = "/preq/sbv3/service/anonymizers/reviewfileexport/status/" + processId;
      break;
    case CT_ADD_AsyncAction.CODE.JS_CHECK_REVIEW_FILE_IMPORT:
        // /preq/sbv3/service/anonymizers/reviewfileimport/status/{processID}
        callModul = CT_ADD_ModuleNames.CODE.JS_MODULE_ANONYMIZERS;
        callEndPoint = "/preq/sbv3/service/anonymizers/reviewfileimport/status/" + processId;
        break;
      default:
      // code block
  } 

  let objCall = {
    callModul : callModul,
    callEndPoint : callEndPoint,
  }

  //identify Module's BaseURL
  let callModuleBaseUrl = "";
  let moduleInfo = await getModuleBaseUrlsPlusTenant();
  for (let i = 0; i < moduleInfo.length; i++) {
    let moduleInfoCandidate = moduleInfo[i];
    if (moduleInfoCandidate.identifier === callModul) {
      callModuleBaseUrl = moduleInfoCandidate.baseUrl;
    }
  }

  let statusUrl = callModuleBaseUrl + callEndPoint;
  let response_FromServer = await fetch(statusUrl,
    {
      method: 'GET',
      headers: {
        'Accept': 'application/json, text/plain',
        'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
        'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
      },
     }
    );
  environmentService.log('', statusUrl);
  environmentService.log('', response_FromServer);

  let httpStatus = HttpCodes.OK;
  if (!response_FromServer.ok) {
    httpStatus = response_FromServer.status;
  }

  if (httpStatus === HttpCodes.OK) {
   let data_FromServer = await response_FromServer.json();
   environmentService.log("data_FromServer", data_FromServer);
   return data_FromServer;
 }

 if (httpStatus === HttpCodes.BAD_REQUEST) {
   let data_FromServer = await response_FromServer.json();
   environmentService.log("data_FromServer", data_FromServer);
   return data_FromServer;
 }

 if (httpStatus === HttpCodes.INTERNAL_SERVER_ERROR) {
   let data_FromServer = await response_FromServer.json();
   environmentService.log("data_FromServer", data_FromServer);
   return data_FromServer;
 }

 let errorJson = PreQV3Constants.CONSTANTS.ERROR_UNKNOWN;
 let error = JSON.parse(errorJson); 
 return error;

}



export async function triggerProcess(callTenant, callCaseid, callModul, callEndPoint, callParameter) {

  let callModuleBaseUrl = "";
  let moduleInfo = await getModuleBaseUrlsPlusTenant();
  for (let i = 0; i < moduleInfo.length; i++) {
    let moduleInfoCandidate = moduleInfo[i];
    if (moduleInfoCandidate.identifier === callModul) {
      callModuleBaseUrl = moduleInfoCandidate.baseUrl;
    }
  }

  let objCall = {
    callTenant: callTenant,
    callCaseid: callCaseid,
    callModul : callModul,
    callModuleBaseUrl : callModuleBaseUrl,
    callEndPoint : callEndPoint,
    callParameter : callParameter
  }
  environmentService.log('', objCall);

  let triggerUrl = "";
  if (callCaseid === null) {
    triggerUrl = callModuleBaseUrl + callEndPoint + "~" + callTenant;
  } else {
    triggerUrl = callModuleBaseUrl + callEndPoint + "~" + callTenant + "~" + callCaseid;
  }


  ///preq/sbv3/service/tenant/sequence/trigger/{tenant}/{caseid}

  //call post and fetch
  let response_FromServer = await fetch(triggerUrl, {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8',
      'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
      'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
    },
      body : callParameter,
   })

   let httpStatus = HttpCodes.OK;
   if (!response_FromServer.ok) {
     httpStatus = response_FromServer.status;
   }
 
   if (httpStatus === HttpCodes.OK) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }

  if (httpStatus === HttpCodes.BAD_REQUEST) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }

  if (httpStatus === HttpCodes.INTERNAL_SERVER_ERROR) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }

  let errorJson = PreQV3Constants.CONSTANTS.ERROR_UNKNOWN;
  let error = JSON.parse(errorJson); 
  return error;


  return null;
}

function sleep(seconds) 
{
  var e = new Date().getTime() + (seconds * 1000);
  while (new Date().getTime() <= e) {}
}