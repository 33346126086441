import React, { Component } from "react";
import { Error16, Warning16 } from '@carbon/icons-react';
import { IconContext } from "react-icons";
import { BsExclamationDiamondFill, BsExclamationTriangleFill } from "react-icons/bs";


import { ContentSwitcher, Switch} from "carbon-components-react";

class CommonXProcessExceptionsEntry extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  render() {

    const { preQException } = this.props;

    let logLink = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
    + "/RESTServiceCall/UI/deliverLogFile/"
    + preQException.urlToLogFile;
    let logFileName = "logFile.log";

    return (
      <table width = "100%" >

      <tr>
        <td style={{width:"4%", verticalAlign:"top", textAlign:"right", paddingLeft:"0px", paddingTop:"3px", borderBottom:"2px", fontSize:"80%"}}>
          &nbsp;
        </td>
        <td style={{borderLeft: "1px solid #d0d0d0", borderTop: "1px solid #d0d0d0",  width:"3%", verticalAlign:"top", textAlign:"right", paddingLeft:"0px", paddingTop:"3px", borderBottom:"2px", fontSize:"80%"}}>
            { (preQException.critical === true)
            ? 
              <IconContext.Provider value={{className:"preQ_PreQException_icon_CRITICAL_sme"}}>
                <BsExclamationDiamondFill size="24"/>
              </IconContext.Provider>
            : 
              <IconContext.Provider value={{className:"preQ_PreQException_icon_UNCRITICAL_sme"}}>
                <BsExclamationTriangleFill size="24"/>
              </IconContext.Provider>
             }
        </td>
        <td style={{borderTop: "1px solid #d0d0d0", width:"93%", verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px", fontSize:"80%"}}>
        {preQException.affectedFile}
        </td>
      </tr>             
      <tr>
        <td style={{width:"4%", verticalAlign:"top", paddingLeft:"0px", paddingTop:"3px", borderBottom:"2px", fontSize:"80%"}}>
          &nbsp;&nbsp;
        </td>
        <td style={{borderLeft: "1px solid #d0d0d0", width:"3%", verticalAlign:"top", paddingLeft:"0px", paddingTop:"3px", borderBottom:"2px",  fontSize:"80%"}}>
          &nbsp;&nbsp;
        </td>
        <td style={{width:"93%", verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px", fontSize:"80%"}}>
        {preQException.errorCode} : {preQException.errorText}
        </td>
      </tr>             
    </table> 
    );
  }
}

export default CommonXProcessExceptionsEntry;
