import * as PreQV3Constants from "./../PreQV3Constants";

export const CODE = {
  UNDEFINED: "",
  JS_C95: "C95",
  JS_C100: "C100",
  JS_C200: "C200",
  JS_C300: "C300",
  JS_C5000: "C5000",
  JS_C5100: "C5100",
  JS_C5500: "C5500",
  JS_C5600: "C5600",
  JS_C6100: "C6100",
  JS_C6200: "C6200",
  JS_C9650: "C9650",
  JS_C9700: "C9700",
  JS_C9720: "C9720",
  JS_C9750: "C9750",
  JS_C9800: "C9800",
  JS_C9955: "C9955",
}


export function getTextForCode(code) {

  let i18nSessionVar = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED);
  if ((i18nSessionVar === null) || (i18nSessionVar === undefined)) {
    i18nSessionVar = "en";
  }

  if (i18nSessionVar === "de") {

    switch (code) {
      case "C95": return "A.1.1 Dateien in das INput-Verzeichnis kopieren";
      case "C100": return "A.1.2 Archive auspacken";
      case "C200": return "A.1.3 Dateitypen klassifizieren (ContentTypeen scannen)";
      case "C300": return "A.1.4 Dateien konvertieren";
      case "C5000": return "A.2 Watson NLP Analyse";
      case "C5100": return "A.2.1 Import der StoredIQ Resultate";
      case "C5500": return "A.2.2 Automatische Bereinigung";
      case "C5600": return "A.2.3 Pseudonym Generierung";
      case "C6100": return "A.3.1 Report zum Review erstellen";
      case "C6200": return "A.3 Review";
      case "C9650": return "A.5.1 Fr�heren Bestand anonymisierte Daten l�schen";
      case "C9700": return "A.5.2 Anonymisierung";
      case "C9720": return "A.5.3 L�schen der konvertierten Dateien (.converted)";
      case "C9750": return "A.5.4 Kopieren nicht anonymisierter Dateien";
      case "C9800": return "A.6.1 Archive wiederherstellen";
      case "C9955": return "A.6.2 Daten in das 'fromPreQ'-Verzeichnis kopieren";
      default:
        return code;
    }
  }

  switch (code) {
    case "C95": return "A.1.1 Copy files to 'input' directory";
    case "C100": return "A.1.2 Extract Data";
    case "C200": return "A.1.3 Classify file types (content type scanner)";
    case "C300": return "A.1.4 Convert files";
    case "C5000": return "A.2 Watson NLP Analysis";
    case "C5100": return "A.2.1 Import StoredIQ Results";
    case "C5500": return "A.2.2 Auto Deletion";
    case "C5600": return "A.2.3 Pseudonym Generation";
    case "C6100": return "A.3.1 Export Report for Review";
    case "C6200": return "A.3 Review";
    case "C9650": return "A.5.1 Clear old data in anonymizer's target directory";
    case "C9700": return "A.5.2 Anonymization";
    case "C9720": return "A.5.3 Delete '.converted' files";
    case "C9750": return "A.5.4 Copy unanonymized files";
    case "C9800": return "A.6.1 Repackage Data";
    case "C9955": return "A.6.2 Copy Data to 'fromPreQ'-Directory";
  
    default:
      return "";
  }
}  
  
export function getHintForCode(code) {

  let i18nSessionVar = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED);
  if ((i18nSessionVar === null) || (i18nSessionVar === undefined)) {
    i18nSessionVar = "en";
  }

  if (i18nSessionVar === "de") {

    switch (code) {
      case "C95": return "";
      case "C100": return "";
      case "C200": return "";
      case "C300": return "";
      case "C5000": return "";
      case "C5100": return "";
      case "C5500": return "";
      case "C5600": return "";
      case "C6100": return "";
      case "C6200": return "";
      case "C9650": return "";
      case "C9700": return "";
      case "C9720": return "";
      case "C9750": return "";
      case "C9800": return "";
      case "C9955": return "";
      default:
        return code;
    }
  }

  switch (code) {
    case "C95": return "";
    case "C100": return "";
    case "C200": return "";
    case "C300": return "";
    case "C5000": return "";
    case "C5100": return "";
    case "C5500": return "";
    case "C5600": return "";
    case "C6100": return "";
    case "C6200": return "";
    case "C9650": return "";
    case "C9700": return "";
    case "C9720": return "";
    case "C9750": return "";
    case "C9800": return "";
    case "C9955": return "";
  
    default:
      return "";
  }
  
}

