import React, { Component } from "react";

import WorkOnProcessItemListUiStatusIcon from "./WorkOnProcessItemListUiStatusIcon";
import * as CT_ADD_DisplayRightType from  "../../../commonxV3_001/codetable/CT_ADD_DisplayRightType";

class WorkOnProcessItemListUi extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
         selectedProcessId: 0,
      };
  }

  render() {

    let item = this.props.processItem;
    let chainEntry = this.props.processItem.chainEntry;
    let list = item.processStatusList;

    return (
      <div>
        <p>
          <table className="processIdInfo" size="100%">
          {list.map((row) => (

            <tr onClick={this.handleSelectProcessId.bind(this, row, chainEntry)}>
              <td className={"preQ_SME_backGroundColor_process_" + row.status}>&nbsp;&nbsp;&nbsp;</td>
              <td className={"preQ_SME_backGroundColor_process_" + row.status}>started: {row.added_txt}</td>
              <td className={"preQ_SME_backGroundColor_process_" + row.status}>&nbsp;&nbsp;&nbsp;</td>
              <td className={"preQ_SME_backGroundColor_process_" + row.status}><WorkOnProcessItemListUiStatusIcon row={row}/></td>
              <td className={"preQ_SME_backGroundColor_process_" + row.status}>&nbsp;&nbsp;&nbsp;</td>
            </tr>

            ))}
          </table>

        </p>
      </div>
    );
  }


  handleSelectProcessId(row, chainEntry) {
    let ary = [];
    ary.push(row.sequenceindex);
    ary.push(row);
    ary.push(chainEntry);
    this.props.handleDisplayRightSettings(CT_ADD_DisplayRightType.CODE.MANUALLY_RESULT, ary, false);
  }
  
  
}


export default WorkOnProcessItemListUi;
