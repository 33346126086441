import React, { Component } from "react";

import {
  Button
} from "carbon-components-react";

import { v4 as uuidv4 } from 'uuid';
import * as ListFetchObjectTools from "./../../commonxV3_001/ListFetchObjectTools";
import * as CT_ADD_AsyncAction from "./../../commonxV3_001/codetable/CT_ADD_AsyncAction";
import * as PreQV3Constants from "./../../commonxV3_001/PreQV3Constants";
import AsyncProcessDialogs from "./../../commonxV3_001/app/AsyncProcessDialogs";
import * as environmentService from "./../../EnvironmentService";


class Parameter6200Export extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        asyncProcessId : null,
        reviewOutAvalable: false,
        reviewFileName: null,
        reviewErrorAvalable: false,

        /*      
        * ------------------------------------------------------------
        *  Code supporting asyncProcessDialogsModal =====>
        * ------------------------------------------------------------
        */ 
        asyncProcessDialogsModal: {
          active: false,
          tenant: null,
          caseid: null,
          code: null, //see Codetable CT_ADD_AsyncAction
          name : null, //clear text for asyncAction (retrieved from Codetable CT_ADD_AsyncAction) 
          parameter : null,
        }
        /*      
        * ------------------------------------------------------------
        *  <===== Code supporting asyncProcessDialogsModal
        * ------------------------------------------------------------
        */ 

  
    };
  }

  
  render() {
    let {sequenceindex, entry, listFetchObj}  = this.props;
    const {asyncProcessDialogsModal, reviewOutAvalable, reviewErrorAvalable, reviewFileName, asyncProcessId} = this.state;

    let currentTenant = PreQV3Constants.getSelectedOrgUnit();
    let currentCase = PreQV3Constants.getSelectedCase();

    ///preq/sbv3/service/tenant/sequence/case/report/document/{tenant}/{caseid}
    let linkFileToReview = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
    + "/RESTServiceFileDownLoad/Anonymizers/preq/sbv3/service/review/deliverFileToReview/" + currentTenant + "/" + currentCase;
    let linkFileNameToReview = currentTenant + "_" + currentCase + "_" + reviewFileName;
    
    let logLink = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
    + "/RESTServiceFileDownLoad/Tenant/preq/sbv3/service/analysis/support/processlog/"
    + asyncProcessId;
    let logFileName = asyncProcessId + ".log";


    if (sequenceindex === 6200) {

      let matchId = ListFetchObjectTools.identifyIdOfMatchProcess(listFetchObj);
      if (matchId === null) {
        return (
          <div>
            &nbsp;<br/> We have got a problem ... processID of match could not be identified
            <br/>
          </div>
        );
      }


      /* 
      * ------------------------------------------------------------
      * Code supporting asyncProcessDialogsModal =====>
      * ------------------------------------------------------------
      */ 
      if (asyncProcessDialogsModal.active === true) {
        return (
        <div>
          <AsyncProcessDialogs
            asyncProcessDialogsModal = {this.state.asyncProcessDialogsModal}
            functionCloseAsyncModal = {this.closeAsyncModal.bind(this)}
            callBackHandleSuccess = {this.handleSucessDataFromServer.bind(this)}
            callBackHandleFailure = {this.handleFailureDataFromServer.bind(this)}
          />
        </div>
        );
      }/* 
      * ------------------------------------------------------------
      * <===== Code supporting asyncProcessDialogsModal
      * ------------------------------------------------------------
      */ 

      return (
        <div>
          &nbsp;<br/>&nbsp;
          <table width = "100%">
            <tr>
              <td width = "53%">
                <Button kind="tertiary" onClick={this.createReviewExport.bind(this)}>
                    Export File for Review 
                </Button>
                &nbsp;
              </td>
              <td width = "47%">
                &nbsp;<br/>
                { reviewOutAvalable === true 
                  ? <>
                    <a
                        href={linkFileToReview}
                        download ={linkFileNameToReview}
                        rel="noopener"
                        aria-label={linkFileNameToReview}
                        >
                      Download
                      </a>
                  </>
                  : <></>
                }                 
                { reviewErrorAvalable === true 
                  ? <>
                    <a
                        href={logLink}
                        download ={logFileName}
                        rel="noopener"
                        aria-label={logFileName}
                        >
                      Error.log
                      </a>
                  </>
                  : <></>
                }                 
                &nbsp;
              </td>
            </tr>
            <tr>
              <td width = "43%">
                &nbsp;<br/>
              </td>
              <td width = "47%">
                &nbsp;<br/>
                { reviewOutAvalable === true 
                  ? <>
                    Please download file and deliver it to Review-Team
                  </>
                  : <>&nbsp;</>
                }
                
                
              </td>
            </tr>
            </table>
        </div>
      );
      
    }

    return (
      <div></div>
    );
  
  }


  async createReviewExport() {

    //"reviewid": "Review ID set by Client"
    let reviewId = uuidv4();

    let sParam = '{"reviewid": "' + reviewId + '"}';
    let tenant = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_ORG_UNIT);
    let caseid = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_CASE);
    let asyncAction = CT_ADD_AsyncAction.CODE.JS_CREATE_REVIEW_FILE_EXPORT;
    this.openAsyncModal(asyncAction, tenant, caseid, sParam);
  }
    

/*      
 * ------------------------------------------------------------
 *  Code supporting asyncProcessDialogsModal =====>
 * ------------------------------------------------------------
 */ 
async openAsyncModal(asyncAction, tenant, caseid, asyncParameter) {
  //  alert(asyncAction + " : [" + tenant + "] / [" + caseid + "]");
  
    let asyncActionName = CT_ADD_AsyncAction.getTextForCode(asyncAction);
    this.setState({ asyncProcessDialogsModal: {...this.state.asyncProcessDialogsModal, 
      active: true,
      tenant: tenant,
      caseid: caseid,
      code: asyncAction, //see Codetable CT_ADD_AsyncAction
      name : asyncActionName,
      parameter : asyncParameter,
      } 
    }); 
  
  }
  
  async closeAsyncModal() {
  
    //Specifics for this Component.PRE: a tenant delete must be finalized with a sync call to clean up preqsys_process
    // ... none
    
  
    //Standard Code supporting asyncProcessDialogsModal =====>
    this.setState({ asyncProcessDialogsModal: {...this.state.asyncProcessDialogsModal, 
      active: false,
      tenant: null,
      caseid: null,
      code: null,
      name : null,
      parameter : null,
      } 
    });
    //<===== Standard Code supporting asyncProcessDialogsModal 
  
  
    //Specifics for this Component.POST: Update List based on performed changes
    //

  
  }
  
  /*      
   * ------------------------------------------------------------
   *  <===== Code supporting asyncProcessDialogsModal
   * ------------------------------------------------------------
   */ 

 async handleSucessDataFromServer(data_FromServer) {

  let asyncProcessId = null;
  try {
    asyncProcessId = data_FromServer.status.processid;
  } catch (error) {
    asyncProcessId = null;
  }

  let sFilename = data_FromServer.process_specifics.filename;
  let orgUnit = data_FromServer.status.tenant;
  let caseid = data_FromServer.status.caseid;
  this.setState(
    {
      reviewOutAvalable: true,
      reviewFileName: sFilename,
      reviewErrorAvalable : false,
      asyncProcessId : asyncProcessId,
    }
  );
 } 

  async handleFailureDataFromServer(data_FromServer) {

    let asyncProcessId = null;
    try {
      asyncProcessId = data_FromServer.status.processid;
    } catch (error) {
      asyncProcessId = null;
    }
  
  this.setState(
    {
      reviewOutAvalable: false,
      reviewFileName: null,
      reviewErrorAvalable : true,
      asyncProcessId : asyncProcessId,
    }
  );
 } 

}

export default Parameter6200Export;
