export const CODE = {
  UNDEFINED : "",
  MANUALLY_RESULT : "MR",
  MANUALLY_PARAMETER_ONESTEP : "MP_ONE",
  MANUALLY_PARAMETER_SEQUENCE : "MP_SEQ",
  AUTOUPDATE_RESULT : "AR",
}



