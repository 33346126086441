import React, { Component } from "react";
import {
  Form, Button,
  FormGroup,
  NumberInput,
  RadioButtonGroup, RadioButton,
  FormLabel, Grid, Row, Column, TextInput, //DatePicker, DatePickerInput,
  TimePicker, TimePickerSelect, SelectItem, Dropdown
} from 'carbon-components-react';


import DevelopmentTag from "./../../common/DevelopmentTag";
import ReactJson from "react-json-view-with-toggle";
import * as AnonyizersRestClient from "./../backend/AnonyizersRestClient";
import * as CT_ADD_DropDownStandards from "./../../commonxV3_001/codetable/CT_ADD_DropDownStandards";
import * as PreQV3Constants from "./../../commonxV3_001/PreQV3Constants";
import { produce } from 'immer'
import * as environmentService from "./../../EnvironmentService";


class Parameter9700 extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        dropdown_items_matchCatcherID : [],
        form : {
          matchCatcherID : CT_ADD_DropDownStandards.CODE.JS_DROPDOWN_USE_FIRST_AVAILABLE_MATCH_ID,
      },
      };
  }

  async componentDidMount() {

      //*----------------------------------------------------------------------
      //* Reading DropDown Values
      //*----------------------------------------------------------------------
      let fieldName_matchCatcherID = 'dropdown_items_matchCatcherID';
      let fieldVal_matchCatcherID = await AnonyizersRestClient.getMatchCatcherCandidates();
      this.setState({[fieldName_matchCatcherID]: fieldVal_matchCatcherID});

  }

  handleDropDownChange_matchCatcherID (event) {
    let fieldName = 'matchCatcherID';
    let fieldKey = event.selectedItem.key;
    if (fieldKey === undefined) {fieldKey = ''};
    this.setState({form: {...this.state.form, [fieldName]: fieldKey}});

    let entry = this.props.getDisplayListEntry(9700);
    let {sequenceindex}  = this.props;
    let objParams = JSON.parse(entry.selectedUiParameters);

    let newParam = null;
    let fieldVal = fieldKey;
    if (fieldVal === CT_ADD_DropDownStandards.CODE.JS_DROPDOWN_USE_FIRST_AVAILABLE_MATCH_ID) {
      fieldVal = CT_ADD_DropDownStandards.getTextForCode(fieldVal);
    }
    newParam = produce(objParams, draft => {
      draft.processid  = fieldVal;
    })

    let sParam = JSON.stringify(newParam);
    this.props.updateUserParameter(sequenceindex, sParam);
    
    environmentService.log('', newParam);

  }


  render() {
    let {sequenceindex, entry}  = this.props;
    if (sequenceindex === 9700) {

      let objParams = JSON.parse(entry.selectedUiParameters);

      //***************************************************************************
      // DropDown Definition for Field 'matchCatcherID' starts here
      //*************************************************************************** 
      const dropdown_matchCatcherID_items = this.state.dropdown_items_matchCatcherID;
      let dropdown_matchCatcherID_default = null;
      dropdown_matchCatcherID_items.map(gen => {
         if (gen.key === this.state.form.matchCatcherID) {
            dropdown_matchCatcherID_default = gen;
         }
      });          
      //***************************************************************************
      // DropDown Definition for Field 'matchCatcherID' ends here
      //*************************************************************************** 
      

      return (
        <div className="preQ_SME_label">
          <Grid>
            <Row>
              <Column>
                <pr className="preQ_SME_label">&nbsp;<br/></pr>
                <pr className="preQ_SME_label">matchCatcherID</pr>
                <Dropdown
                    id="matchCatcherID"
                    selectedItem={dropdown_matchCatcherID_default}
                    titleText=""
                    label="Please Select"
                    items={dropdown_matchCatcherID_items}
                    itemToString={(item) => (item ? item.val : '')}
                    onChange={this.handleDropDownChange_matchCatcherID.bind(this)}
                />
                <br/>                
                <pr className="preQ_SME_label">&nbsp;&nbsp;&nbsp;* means: use the one and only you will find during processing <br/></pr>
              </Column>
            </Row>
          </Grid>
          <br />
        </div>
      );
      
    }

    return (
      <div></div>
    );
  
  }


}

export default Parameter9700;
