import React, { Component } from "react";

import { ContentSwitcher, NumberInputSkeleton, Switch} from "carbon-components-react";
import DevelopmentTag from "./../../common/DevelopmentTag";
import ReactJson from "react-json-view-with-toggle";
import { produce } from 'immer'
import {
  Grid, Row, Column, TextInput
} from 'carbon-components-react';
import { BiReset, BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
import { IconContext } from "react-icons";

class Parameter300 extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  render() {


    //***************************************************************************
    // Ui Properties Definition starts here
    //*************************************************************************** 

    const ui_Props_TextInput_Converters = {
      className: 'some-class',
      id: 'Converters',
      labelText: '',
      placeholder: 'All Converters',
   };

   const ui_Props_TextInput_Files = {
    className: 'some-class',
    id: 'Files',
    labelText: '',
    placeholder: 'No File selecion required',
  };


    //***************************************************************************
    // render Ui
    //*************************************************************************** 


    let {sequenceindex, entry}  = this.props;
    if (sequenceindex === 300) {
      let entry = this.props.getDisplayListEntry(300);
      let objParams = JSON.parse(entry.selectedUiParameters);

      return (
        <div>
          <DevelopmentTag uiParams = {this.props.uiParams} devTagLabel = {this.constructor.name}/>
          <div className="preQ_SME_label"><br />

          <table  width="100%">
            <tr>
              <td width="3%">
                <div onClick={this.handleResetParameters.bind(this, 300)}>
                <IconContext.Provider value={{className:"preQ_SME_icon_process_status_JS_PROCESS_DONE"}}>
                    <BiReset size="22"/>
                </IconContext.Provider>
                 </div>
              </td>
              <td width="97%">
                &nbsp;
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  &nbsp;
                 </div>
              </td>
              <td>
              {this.props.uiParams.showDevTags ? (
                <div className="preQ_SME_label">
                  entry.selectedUiParameters<br/><br/>
                  <ReactJson 
                    src={JSON.parse(entry.selectedUiParameters)} 
                    collapsed={false} 
                    enableClipboard={false}
                    name = {false}
                    />
                </div>
              ) : (
                <div>&nbsp;</div>
              )}
              </td>
            </tr>
          </table>

          <Grid>
            <Row>
                  <Column>
                      <div className="preQ_SME_label">
                      Selected Converters
                      </div>
                      <TextInput
                        disabled={true}
                        {...ui_Props_TextInput_Converters} 
                    />
                  </Column>
                </Row>
                <Row>
                  <Column>
                      <div className="preQ_SME_label">
                      Selected Files
                      </div>
                      <TextInput
                        disabled={true}
                        {...ui_Props_TextInput_Files} 
                    />
                  </Column>
                </Row>
          </Grid>      
           <br /><hr />
          </div>
        </div>
      );
    }

    return (
      <div></div>
    );
  
  }


  //*******************************/
  //* Parameter Reset Handler
  //*******************************/
  handleResetParameters(sequenceIndex) {
    this.props.resetUserParameter(sequenceIndex);
  }

}

export default Parameter300;
