export const CODE = {
  UNDEFINED : "",
  JS_MODULE_ARCHIVES : "Archives",
  JS_MODULE_ANONYMIZERS : "Anonymizers",
  JS_MODULE_CONTENT_TYPES : "ContentTypes",
  JS_MODULE_CONVERTERS : "Converters",
  JS_MODULE_HARVESTER : "Harvester",
  JS_MODULE_TENANT : "Tenant",
  JS_MODULE_REACT : "React",
}



