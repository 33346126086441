import React, { Component } from "react";

import { Button } from "@carbon/react";
import { Reset, Download } from '@carbon/react/icons';

import * as tenantRestClient from "./../../tenantV3_001/backend/RestClient";
import * as PreQV3Constants from "./../../commonxV3_001/PreQV3Constants";

import * as CT_ProcessStatus from "./../../commonxV3_001/codetable/CT_ProcessStatus";
import * as CT_ProcessingPhasesGamingNames from "./../../commonxV3_001/codetable/CT_ProcessingPhasesGamingNames";

import { v4 as uuidv4 } from 'uuid';

import WamaDisplayFileUploadID from "./WamaDisplayFileUploadID";
import WamaDisplayErrorWrapper from './WamaDisplayErrorWrapper';
import * as ErrorCodeBuilder from "./../../commonxV3_001/backend/ErrorCodeBuilder";
import * as RestClient from "./../../../modules/tenantV3_001/backend/RestClient";

import i18next from "i18next";


class WamaProcessing extends Component {
  constructor(props) {
    super(props);
    this.resetState();
  }


  async resetState() {
    // Create the state
    let today = new Date();
    var now = today.toLocaleString();

    this.state = {

      list: [],

      autoUpdateTimeStamp: now,
      autoUpdateCurrentRunningProcess: null,
      autoUpdateCurrentRunningProcessChainEntry: null,
      completelyDone: false,

      steadyFlowStatus: null,
      phases: [],
      sequenceSteps: [],

      errorMessageVisible:false,
      error:null,
      errorTitle:null,
      errorActionButtonLabel:'Do it',
      errorActionButonReset : false,

      chainEndTime:null,

    };
  }

  async componentDidMount() {
    this.updateList();
    const intervalId = setInterval(() => {
      this.updateList();
    }, 500);
  }

  async updateList() {

    let today = new Date();
    let processingID = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_CURRENT_WAMA_FILE_UPLOAD_ID);

    //Update only works each 2 seconds, if there is an indicator to do so
    let processingRuns = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_CURRENT_WAMA_WAITS_FOR_PROCESSING);
    let indRun = (processingRuns === PreQV3Constants.CONSTANTS.BOOLEAN_TRUE)
    if (!indRun) {
      return;
    }

    let listFetchObj = await tenantRestClient.getProcessChainStatus(processingID, processingID);

    let runningProcess = listFetchObj.runningProcess;
    let runningProcessChainEntry = listFetchObj.runningProcessChainEntry;
    let completelyDone = listFetchObj.completelyDone;
    var now = today.toLocaleString();

    let phases = listFetchObj.steadyFlowStatus.phases;
    let sequenceSteps = listFetchObj.steadyFlowStatus.sequence;
    let steadyFlowStatus = listFetchObj.steadyFlowStatus;

    if (completelyDone === true) {
      sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_CURRENT_WAMA_WAITS_FOR_PROCESSING, PreQV3Constants.CONSTANTS.BOOLEAN_FALSE);
    }

    let chainEndTime = null;
    if (completelyDone === true) {
      let chain = listFetchObj.chain;
      let lastEntry = chain[chain.length-1];
      let processInfo = lastEntry.processStatusList[0];
      chainEndTime = processInfo.ended_int;
    }

    this.setState({
      list: listFetchObj.getProcessChainStatus,
      autoUpdateTimeStamp: now,
      autoUpdateCurrentRunningProcess: runningProcess,
      autoUpdateCurrentRunningProcessChainEntry: runningProcessChainEntry,
      steadyFlowStatus: steadyFlowStatus,
      phases: phases,
      sequenceSteps: sequenceSteps,
      completelyDone: completelyDone,
      chainEndTime : chainEndTime,
    });

    let corruptedFlag = false;
    for (let index = 0; index < sequenceSteps.length; index++) {
      let sequenceStep = sequenceSteps[index];
      if (sequenceStep.status === CT_ProcessStatus.CODE.JS_PROCESS_CORRUPTED) {
        corruptedFlag = true;
      }
    }
    if (corruptedFlag) {
      let errorCode = PreQV3Constants.CONSTANTS.ERROR_UNKNOWN_CODE;
      let errorText = i18next.t("WamaProcessing.corrupted.text1") + processingID + ". " + i18next.t("WamaProcessing.corrupted.text2");
      let error = ErrorCodeBuilder.displayableErrorObject(errorCode, errorText);

      this.setState({
        errorMessageVisible:true,
        error:error,
        errorTitle: null,
        errorActionButtonLabel: "OK",
        errorActionButonReset : false,  
        });
  
    }

  }

  /**
   * This method delivers Sequence Summary
   * If sequence is running, then result is null and result miust be identified by sequence's running details
   * @returns
   */
  getSequenceSummary() {
    const { steadyFlowStatus } = this.state;
    let curSeqStat = steadyFlowStatus.currentSequenceStatus;
    switch (curSeqStat) {
      case CT_ProcessStatus.CODE.JS_IN_PROCESS:
        return null;

      default:
        return curSeqStat;
    }
  }

  getSequencePhasesSummary() {
    const { phases } = this.state;
    if (phases.length > 0) {
      for (let index = 0; index < phases.length; index++) {
        let phaseCandidate = phases[index];
        switch (phaseCandidate.status) {

          case CT_ProcessStatus.CODE.JS_IN_PROCESS:
            return CT_ProcessingPhasesGamingNames.getTextForCode(phaseCandidate.name);

          case CT_ProcessStatus.CODE.JS_RAISING:
            return CT_ProcessingPhasesGamingNames.getTextForCode(phaseCandidate.name);

          case CT_ProcessStatus.CODE.JS_TO_BE_PROCESSED:
            return CT_ProcessingPhasesGamingNames.getTextForCode(phaseCandidate.name);

          default:
            break;
        }
      }
    }
  }

  render() {

    let jsonUiParams = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_UI_PARAMETERS);
    let uiParams = JSON.parse(jsonUiParams);

    const sSEQ_PREFIX = i18next.t("WamaProcessing.const.sSEQ_PREFIX");
    const sPHASE_PREFIX = i18next.t("WamaProcessing.const.sPHASE_PPREFIX");

    const { steadyFlowStatus, sequenceSteps,errorMessageVisible, error, errorTitle, errorActionButtonLabel, errorActionButonReset } = this.state;

    let runningPhaseInfo = "";
    let runningPhaseInfoSource = "";
    let runningPhaseDisplay = "";
    if (steadyFlowStatus !== null) {
      let sequenceSummaryToDisplay = this.getSequenceSummary();
      if (sequenceSummaryToDisplay !== null) {
        runningPhaseInfo = sequenceSummaryToDisplay;
        runningPhaseInfoSource = sSEQ_PREFIX;
        runningPhaseDisplay = runningPhaseInfoSource + runningPhaseInfo;

      }
      else {
        runningPhaseInfo = this.getSequencePhasesSummary();
        runningPhaseInfoSource = sPHASE_PREFIX;
        runningPhaseDisplay = runningPhaseInfoSource + runningPhaseInfo;
      }
    }
    let wamaProcessingDone = false;
    if ((runningPhaseInfoSource === sSEQ_PREFIX) && (runningPhaseInfo === CT_ProcessStatus.CODE.JS_PROCESS_DONE)) {
      wamaProcessingDone = true;
      runningPhaseDisplay = i18next.t("WamaProcessing.phases.done");
    }
    let buttonsEnabled = (wamaProcessingDone && !errorActionButonReset)

    if ((runningPhaseInfoSource === sSEQ_PREFIX) && (runningPhaseInfo === CT_ProcessStatus.CODE.JS_RAISING)) {
      runningPhaseDisplay = i18next.t("WamaProcessing.phases.js_raising");
    }
    if ((runningPhaseInfoSource === sSEQ_PREFIX) && (runningPhaseInfo === CT_ProcessStatus.CODE.JS_TO_BE_PROCESSED)) {
      runningPhaseDisplay = i18next.t("WamaProcessing.phases.js_to_be_processed");
    }
    if (runningPhaseInfoSource === sPHASE_PREFIX) {
      runningPhaseDisplay = runningPhaseInfo;
    }

    let witdhPerStep = Math.floor(100 / sequenceSteps.length);
    let stepWidthString = witdhPerStep.toString() + "%";

    return (
      <div className="grid-setup margin-t-96">
        <div className="padding-0 bg-inverse width-608">
          <WamaDisplayErrorWrapper
            errorMessageVisible={errorMessageVisible}
            error={error}
            title = {errorTitle}
            actionButtonLabel = {errorActionButtonLabel}
            setError={this.setError.bind(this)}
          />
          <div className="padding-16">
            <h3 className="margin-b-16">{i18next.t("app.title-short")}</h3>
            <WamaDisplayFileUploadID />
            <p className="margin-b-40">{i18next.t("WamaProcessing.text.subheading1")} {uiParams.wamaPreQResultMaxAgeInMinutes} {i18next.t("WamaProcessing.text.subheading2")}).</p>
            <div className="margin-b-8" padding-top="$spacing-24">
              <table width="100%">
                <tr>
                  {sequenceSteps.map(sequenceStep => (
                    <td width={stepWidthString}><hr className={"preQ_progress_indicator_" + sequenceStep.status} /></td>
                  ))}
                </tr>
              </table>
              <br />
              <table width="100%" id="preQ_centered_one_column_table">
                <tr>
                  <td>{runningPhaseDisplay}</td>
                </tr>
              </table>
              <br />
              <br />
            </div>
          </div>
          <div className="cds--modal-footer cds--btn-set">
            <Button kind="tertiary" disabled={!buttonsEnabled} className="margin-r-16" renderIcon={Reset} iconDescription="Reset" onClick={this.reset.bind(this)}>{i18next.t("WamaProcessing.buttonReset")}</Button>
            <Button kind="primary" disabled={!buttonsEnabled} className="margin-r-16" renderIcon={Download} iconDescription="Download" onClick={this.downLoadResult.bind(this)}>{i18next.t("WamaProcessing.buttonDownload")}</Button>
          </div>
        </div>
      </div>
    );
  }


  async reset() {
    PreQV3Constants.resetSessionVariablesToBaseSet(false, false);
    window.location.replace('/');
  }

  async downLoadResult() {

    let jsonUiParams = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_UI_PARAMETERS);
    let uiParams = JSON.parse(jsonUiParams);

    let orgUnit = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_CURRENT_WAMA_FILE_UPLOAD_ID);
    let caseid = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_CURRENT_WAMA_FILE_UPLOAD_ID);
    let filedownloadId = uuidv4()

    let logLink = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
      + "/RESTServiceFileDownLoad/Tenant/preq/sbv3/service/tenant/frompreq/download/" + orgUnit + "/" + caseid + "/" + filedownloadId

    const { acceptedFiles } = this.props;

    let listFromPreQ = await RestClient.getFileListTenantCaseFromPreQ(orgUnit, caseid);
    let downloadFileName = null;
    if (acceptedFiles.length == 1) {
      downloadFileName = listFromPreQ.submittedFiles[0].key
    }

    let endPontInTimeToDownload = this.state.chainEndTime + uiParams.wamaPreQResultMaxAgeInMinutes * 60 * 1000;
    //reduce endPontInTimeToDownload by some seconds (some time to perform the download - link)
    endPontInTimeToDownload = endPontInTimeToDownload - (5 * 1000);

    let currentTimeStamp = new Date().getTime();

    if (currentTimeStamp > endPontInTimeToDownload) {

      let errorCode = PreQV3Constants.CONSTANTS.ERROR_UNKNOWN_CODE;
      let errorText = i18next.t("WamaProcessing.timeout.text");
      let error = ErrorCodeBuilder.displayableErrorObject(errorCode, errorText);

      this.setState({
        errorMessageVisible:true,
        error:error,
        errorTitle: i18next.t("WamaProcessing.timeout.title"),
        errorActionButtonLabel: i18next.t("WamaProcessing.timeout.button"),
        errorActionButonReset : true,  
        });

      
    } else {
      const a = document.createElement('a')
      a.href = logLink
      a.download = downloadFileName
      a.click()
    }

  }


    /**
   * This method sets the error - information
   * @param {*} error can be filled with an error object or is null (no error)
   */
    setError(error, errorTitle) {

      const { errorActionButonReset } = this.state;
      let doReset = errorActionButonReset;
      if (error === null) {
        this.setState({
          errorMessageVisible:false,
          error:null,
          errorTitle:null,
          errorActionButtonLabel:'',
          errorActionButonReset : false,
        });
        if (doReset) {
          this.reset();
        }
      } else {
        this.setState({
          errorMessageVisible:true,
          error:error,
          errorTitle:errorTitle,
          errorActionButtonLabel:'',
          errorActionButonReset : false,
        });
      }
    }
  
}

export default WamaProcessing;
