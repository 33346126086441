import React, { Component } from "react";
import { IoCaretDown, IoCaretForward } from "react-icons/io5";
import { IconContext } from "react-icons";
import { BsExclamationDiamondFill, BsExclamationTriangleFill } from "react-icons/bs";
import { Theme } from '@carbon/react';
import CommonXProcessExceptionsEntry from "./CommonXProcessExceptionsEntry";

class CommonXProcessExceptions extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        exceptionUiIsOpen : false
      };
  }


  /*
   * Open / Close Excpetion List
   */
  toggleExcpetionListIsOpen() {
    let ind = this.state.exceptionUiIsOpen;
    if (ind === false) {
      this.setState({exceptionUiIsOpen: true});
    } else {
      this.setState({exceptionUiIsOpen: false});
    }
  }

  render() {

    const {exceptionUiIsOpen } = this.state;
    const {processExceptions} = this.props;

    let displayExceptions = false;
    if ((processExceptions !== null) && (processExceptions.length > 0)) {
      displayExceptions = true;
    }



    if (displayExceptions > 0) 
    { //process exceptions

      if (exceptionUiIsOpen === true) {

        return (
          <div>
            <Theme theme="white">
            <p>
              <br />
                <table width = "100%" >
    
                <tr>
                  <td style={{width:"100%", verticalAlign:"top", paddingLeft:"0px", paddingTop:"3px", borderBottom:"2px", fontSize:"80%"}}>
                    <div>
                      <IconContext.Provider value={{className:"preQ_PreQException_icon_CARET_sme"}}>
                        <IoCaretDown size="24" onClick={this.toggleExcpetionListIsOpen.bind(this)}/>
                      </IconContext.Provider>
                      <IconContext.Provider value={{className:"preQ_PreQException_icon_CRITICAL_sme"}}>
                        <BsExclamationDiamondFill size="24"/>
                      </IconContext.Provider>
                      <IconContext.Provider value={{className:"preQ_PreQException_icon_UNCRITICAL_sme"}}>
                        <BsExclamationTriangleFill size="24"/>
                      </IconContext.Provider>
                    </div>
                  </td>
                </tr>             
    

                </table>
                {processExceptions.map((preQException) => (
                  <CommonXProcessExceptionsEntry
                  preQException = {preQException} 
                  style={this.props.style}/>
                ))}

              <br />  
            </p>
          </Theme>
          </div>
        );
  
      } else {

        return (
          <div>
            <Theme theme="white">
            <p>
              <br />
                <table width = "100%" >
    
                <tr>
                  <td style={{width:"100%", verticalAlign:"top", paddingLeft:"0px", paddingTop:"3px", borderBottom:"2px", fontSize:"80%"}}>
                    <div>
                    <IconContext.Provider value={{className:"preQ_PreQException_icon_CARET_sme"}}>
                        <IoCaretForward size="24" onClick={this.toggleExcpetionListIsOpen.bind(this)}/>
                      </IconContext.Provider>
                      <IconContext.Provider value={{className:"preQ_PreQException_icon_CRITICAL_sme"}}>
                        <BsExclamationDiamondFill size="24"/>
                      </IconContext.Provider>
                      <IconContext.Provider value={{className:"preQ_PreQException_icon_UNCRITICAL_sme"}}>
                        <BsExclamationTriangleFill size="24"/>
                      </IconContext.Provider>
                    </div>
                  </td>
                </tr>             
    
                </table>
              <br />  
            </p>
          </Theme>
          </div>
        );
  
      }

    } else { //no process exceptions

      return (
        <div>
          <></>
        </div>
      );
      

    }


  }
}

export default CommonXProcessExceptions;
