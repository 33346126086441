import React, { Component } from "react";

import { ContentSwitcher, Switch } from "carbon-components-react";
import { ActionableNotification } from "@carbon/react";
import * as PreQV3Constants from "./../../../modules/commonxV3_001/PreQV3Constants"


import i18next from "i18next";

class WamaDisplayErrorWrapper extends Component {
  constructor(props) {
    super(props);

    // Create the state
    this.state = {
      // pageToDisplay: 0,
    };
  }


  resetError() {
    this.props.setError(null);
    sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_LOCALE_ACTIVE, "YES");
  }

  render() {

    const { errorMessageVisible, error, title, actionButtonLabel } = this.props;

    let usedTitle = title;
    if (title === undefined) {
      usedTitle = i18next.t("WamaDisplayErrorWrapper.undefinedTitle")
    } 
    if (title === null) {
      usedTitle = i18next.t("WamaDisplayErrorWrapper.nullTitle")
    } 
    
    let usedActionButtonLabel = actionButtonLabel;
    if (actionButtonLabel === undefined) {
      usedActionButtonLabel = i18next.t("WamaDisplayErrorWrapper.undefinedButton");
    }
    if (actionButtonLabel === null) {
      usedActionButtonLabel = i18next.t("WamaDisplayErrorWrapper.nullButton");
    }

    if (errorMessageVisible === true) {

      return (
        <div>
          <ActionableNotification
            kind="warning"
            title={usedTitle}
            className="width-608"
            actionButtonLabel={usedActionButtonLabel}
            ariaLabel="closes notification"
            onActionButtonClick={this.resetError.bind(this)}
            onClose={function noRefCheck() { }}
            onCloseButtonClick={function noRefCheck() { }}
            hideCloseButton={true}
            statusIconDescription=" "
            subtitle={error.errorText}
          />

          <table width="100%">
            <tr>
              <td width="80%">&nbsp;</td>
              <td width="20%">
                <div className="preQ_Display_ErrorCodes">
                  <br />
                  &nbsp;&nbsp;&nbsp;ErrorCode: {error.errorCode}
                  <br />
                </div>
              </td>
            </tr>
          </table>


        </div>

      );

    } else {
      return (
        <div></div>);
    }
  }
}

export default WamaDisplayErrorWrapper;
