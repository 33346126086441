import React, { Component } from "react";

import { ContentSwitcher, Switch} from "carbon-components-react";
import DevelopmentTag from "./../../../common//DevelopmentTag";
import WorkOnCaseUiDisplayRightResultParameterUi from "./WorkOnCaseUiDisplayRightResultParameterUi";
import WorkOnCaseUiDisplayRightResultHeaderUi from "./WorkOnCaseUiDisplayRightResultHeaderUi";
import WorkOnCaseUiDisplayRightResultStatisticsUi from "./WorkOnCaseUiDisplayRightResultStatisticsUi";
import WorkOnCaseUiDisplayRightResultStatusUi from "./WorkOnCaseUiDisplayRightResultStatusUi";
import WorkOnCaseUiDisplayRightResultSummary from "./WorkOnCaseUiDisplayRightResultSummary";

class WorkOnCaseUiDisplayRightResultUi extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  render() {
    let preqsys_process = this.props.row;
    let chainEntry = this.props.chainEntry;
    let moduleUrls = this.props.moduleUrls;
    return (
      <div>
          <DevelopmentTag uiParams = {this.props.uiParams} devTagLabel = {this.constructor.name}/>
          <WorkOnCaseUiDisplayRightResultHeaderUi
            row = {preqsys_process}
            chainEntry = {chainEntry}
            resetDisplayRightSettings = {this.props.resetDisplayRightSettings}
            uiParams = {this.props.uiParams}
           />
          <br/>
          <WorkOnCaseUiDisplayRightResultParameterUi
            row = {preqsys_process}
            uiParams = {this.props.uiParams}
           />
          <br/>
          <WorkOnCaseUiDisplayRightResultStatusUi
            row = {preqsys_process}
            moduleUrls = {moduleUrls}
            chainEntry = {chainEntry}
            uiParams = {this.props.uiParams}
           />
          <br/>
          <WorkOnCaseUiDisplayRightResultSummary
            row = {preqsys_process}
          />
          <br/>
          <WorkOnCaseUiDisplayRightResultStatisticsUi
            row = {preqsys_process}
            uiParams = {this.props.uiParams}
           />
          <br/>
      </div>
    );
  }
}

export default WorkOnCaseUiDisplayRightResultUi;
