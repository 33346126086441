import React, { Component } from "react";
import * as environmentService from "./../../modules/EnvironmentService";

class I18nSelect extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  setI18n(language) {
    environmentService.log('', 'Language:' + language);
  }
  render() {

    let flagSize = '30px';
    return (
      <div>
        <table>
          <tr>
            <td>
              &nbsp;&nbsp;&nbsp;
            </td>
            <td>
              <div>
                &nbsp;<br />
              </div>
            </td>
            <td>
              &nbsp;
            </td>
            <td>
              <div>
                <img src="/preq_images/FreeIcons/png/256x256/intl/flag-white.png" with={flagSize} height={flagSize}/><br />
              </div>
            </td>

          </tr>          
        </table>
      </div>
    );
  }
}

export default I18nSelect;
