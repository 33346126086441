import * as PreQV3Constants from "./../PreQV3Constants";

export const CODE = {
  UNDEFINED: "",
  JS_PRE: "PRE_PROCESSING",
  JS_POST: "POST_PROCESSING",
  JS_MATCHING: "MATCHING",
  JS_REVIEW: "REVIEW",
  JS_DEVELOPMENT_TOOL: "DEVELOPMENT_TOOL",
}


export function getTextForCode(code) {

  let i18nSessionVar = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED);
  if ((i18nSessionVar === null) || (i18nSessionVar === undefined)) {
    i18nSessionVar = "en";
  }

  if (i18nSessionVar === "de") {

    switch (code) {
      case "PRE_PROCESSING": return "Vorbereitung der Daten...";
      case "POST_PROCESSING": return "Nachbearbeitung...";
      case "MATCHING": return "Analyse der Daten...";
      case "REVIEW": return "Abstimmung Ihrer Treffer";
      case "DEVELOPMENT_TOOL": return "Entwicklung und Test";
      default:
        return code;
    }
  }

  switch (code) {
    case "PRE_PROCESSING": return "Preparing data...";
    case "POST_PROCESSING": return "Post-processing...";
    case "MATCHING": return "Analyzing data...";
    case "REVIEW": return "Review your matches";
    case "DEVELOPMENT_TOOL": return "Development and Test";
  
    default:
      return "";
  }
}  
  
export function getHintForCode(code) {

  let i18nSessionVar = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED);
  if ((i18nSessionVar === null) || (i18nSessionVar === undefined)) {
    i18nSessionVar = "en";
  }

  if (i18nSessionVar === "de") {

    switch (code) {
      case "PRE_PROCESSING": return "";
      case "POST_PROCESSING": return "";
      case "MATCHING": return "";
      case "REVIEW": return "";
      case "DEVELOPMENT_TOOL": return "";
      default:
        return code;
    }
  }

  switch (code) {
    case "PRE_PROCESSING": return "";
    case "POST_PROCESSING": return "";
    case "MATCHING": return "";
    case "REVIEW": return "";
    case "DEVELOPMENT_TOOL": return "";
  
    default:
      return "";
  }
  
}

