import * as PreQV3Constants from "./../PreQV3Constants";


export function displayableErrorObject(errorCode, errorText) {

    let errorJson = PreQV3Constants.CONSTANTS.ERROR_UNKNOWN;
    let error = JSON.parse(errorJson); 
    error.errorText = errorText;
    error.errorCode = errorCode;
    
    return error;

}