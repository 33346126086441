/**
 * This function identifies via ExpressJS Server the 
 * TenantBaseURL
 * @returns true, if json represents PojoErrorResult
 */
export function isPojoErrorResult(data_FromServer) {

  if (data_FromServer === null) {
    return false
  }

  if (data_FromServer === "undefined") {
    return false
  }

  let indError1 = (typeof(data_FromServer.errorCode) !== "undefined");
  let indError2 = (typeof(data_FromServer.errorText) !== "undefined");
  let indError3 = (typeof(data_FromServer.e) !== "undefined");

  let result = indError1 && indError2 && indError3;
  return result;

}


