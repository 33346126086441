export const CODE = {
  UNDEFINED : "",
  JS_DROPDOWN_USE_FIRST_AVAILABLE_MATCH_ID : "USE_FIRST_AVAILABLE_MATCH_ID",
}


export function getTextForCode(code) {
  switch (code) {

  case "USE_FIRST_AVAILABLE_MATCH_ID": return "*";
  
    default:
      return "";
  }
}

