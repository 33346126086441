import * as environmentService from "./../../EnvironmentService";
import * as CT_ADD_ModuleNames from "./../../commonxV3_001/codetable/CT_ADD_ModuleNames";
import * as PreQV3Constants from "./../../commonxV3_001/PreQV3Constants";
import * as CT_ADD_DropDownStandards from "./../../commonxV3_001/codetable/CT_ADD_DropDownStandards";
import * as Facade from "./../../Facade";

const HttpCodes = {
  OK : 200,
  BAD_REQUEST : 400,
  INTERNAL_SERVER_ERROR : 500,

}

/**
 * This method reads list of ContentTypes/Amounts incl the used SQL-Queries 
 * @returns 
 */
export async function getContentTypesAmounts(processId) {

  let callModul = CT_ADD_ModuleNames.CODE.JS_MODULE_CONTENT_TYPES;
  let callEndPoint = "/preq/sbv3/service/contenttypes/report/drilldown/contenttypeamounts/" + processId + "";

  let moduleBaseUrl = await environmentService.getModuleBaseUrl(callModul);
  let url = moduleBaseUrl + callEndPoint;
  
  let response_FromServer = await fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json, text/plain',
      'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
      'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
    }
   });
   
  if (!response_FromServer.ok) {
    return null;
  }

  let data_FromServer = await response_FromServer.json();
  environmentService.log("data_FromServer", data_FromServer);
  
  return data_FromServer;

}



