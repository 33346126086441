import React, {Component } from "react";
import * as PreQV3Constants from "./../PreQV3Constants";
import * as CT_UiModus from "./../../commonxV3_001/codetable/CT_UiModus";
import * as versionInfo from "./../../VersionInfo";

import { Loading } from "carbon-components-react";

class VersionsAndSettingsDisplayContent extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
    //    var : null,
      };
  }

  render() {

    let jsonUiParams = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_UI_PARAMETERS);
    let uiParams = JSON.parse(jsonUiParams);

    if (uiParams === null) {
      return (
        <div>
          <Loading/>
        </div>
      );
    }
    
    let indWamaActive = ( uiParams.uiModus === CT_UiModus.CODE.JS_UI_MODUS_WAMA);

    return (
      <div>
            <table>
            <tr>
               <td colspan="4">
              
               {uiParams.moduleInfos.map((row) => (
                          <h3>
                          {row.appName}
                          </h3>
                    ))}
              
               </td>
            </tr>
             <tr>
               <td colspan="4">
                <h5>({versionInfo.getBuildID()})</h5>
              </td>
             </tr> 
              <tr>
                <td>

                <table>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td colspan="3"><h4>Server and Versions:</h4></td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Server</td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td>{uiParams.instanceServerType}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Build</td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td>Version {versionInfo.getBuildVersion()}</td>
                    </tr>
                    <tr>
                      <td>Base common-x</td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td>Version {versionInfo.getCommonXVersion()}</td>
                    </tr>
                    {uiParams.moduleInfos.map((row) => (
                          <tr>
                          <td>Module {row.identifier}</td>
                          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                          <td>Version {row.version}</td>
                        </tr>
                    
                    ))}        
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>

                    {uiParams.useWatsonNLP
                    ?
                    <>
                      <tr>
                        <td>Module Watson NLP</td>
                        <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                        <td>Version {versionInfo.getWatsonNlpVersion()}</td>
                      </tr>
                        <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </>
                    : <>
                    </>                   
                    }

                    <tr>
                      <td>User Management Tool</td>
                      <td>&nbsp;</td>
                      <td>Version {versionInfo.getUserManagementToolVersion()}</td>
                    </tr>
                  </table> 




                </td>
                <td>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </td>
                <td>


                <table>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td><h4>Settings:</h4></td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Data Folder</td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td>{uiParams.instanceFolderData}</td>
                    </tr>
                    <tr>
                      <td>Model Folder</td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td>{uiParams.instanceFolderModel}</td>
                    </tr>
                    <tr>
                      <td>Postgre SQL Folder</td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td>{uiParams.instanceFolderPostgre}</td>
                    </tr>
                    <tr>
                      <td>PG Admin Settings Folder</td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td>{uiParams.instanceFolderPgAdmin}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Log-Level</td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td>{uiParams.logLevel}</td>
                    </tr>
                    <tr>
                      <td>SQL Logging enabled</td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td>{uiParams.logsql}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>UI Modus</td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td>{uiParams.uiModus}</td>
                    </tr>
                    <tr>
                      <td>Usage of WatsonNLP</td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td>{uiParams.useWatsonNLP.toString()}</td>
                    </tr>
                    <tr>
                      <td>Usage of StoredIQ</td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td>{uiParams.useStoredIQ.toString()}</td>
                    </tr>
                    <tr>
                      <td>Auto Deletion Included</td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td>{uiParams.sequenceIncludesPPAutoDelete.toString()}</td>
                    </tr>
                    <tr>
                      <td>Pseudonym Generation Included</td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td>{uiParams.sequenceIncludesPPPseudoGen.toString()}</td>
                    </tr>
                    {
                      uiParams.sequenceIncludesPPPseudoGen === true
                      ? <tr>
                        <td>&nbsp;-&nbsp;Pseudonym Generation Plugin</td>
                        <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                        <td>{uiParams.pseudoGenSelectedPlugin}</td>
                      </tr>
                      : <></>
                    }
                    {
                      uiParams.sequenceIncludesPPPseudoGen === true
                      ? <tr>
                        <td>&nbsp;-&nbsp;Pseudonym Generation Plugin Config File</td>
                        <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                        <td>{uiParams.pseudoGenSelectedConfig}</td>
                      </tr>
                      : <></>
                    }
                    <tr>
                      <td>Review Included</td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td>{uiParams.sequenceInculdesReview.toString()}</td>
                    </tr>
                    <tr>
                      <td>Sequence Modus</td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td>{uiParams.sequenceCaseStatusMode.toString()}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Multi Org Unit System</td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td>{uiParams.multiTenant.toString()}</td>
                    </tr>
                    <tr>
                      <td>Show Development Tags</td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      <td>{uiParams.showDevTags.toString()}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    </table> 
                    
                    {
                    indWamaActive === true
                    ? <div>

                      <table> 
                        <tr>
                          <td><h4>WAMA-Settings:</h4></td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>Max FileSize of uploadable files</td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>&nbsp;&nbsp;- in KByte:</td>
                          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                          <td>{uiParams.wamaMaxUploadableFileSizeInKb.toString()}</td>
                        </tr>
                        <tr>
                          <td>&nbsp;&nbsp;- in Byte:</td>
                          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                          <td>{uiParams.wamaMaxUploadableFileSize.toString()}</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>Allowed Extensions as WAMA-Input</td>
                          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                          <td>{uiParams.wamaInputAllowedExtensions}</td>
                        </tr>
                        <tr>
                          <td>Result Availability</td>
                          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                          <td>{uiParams.wamaPreQResultMaxAgeInMinutes} min</td>
                        </tr>
                      </table> 
                      </div>
                    : <div>
                      </div>
                    }





                </td>
              </tr>
            </table>
      </div>
    );
  }
}

export default VersionsAndSettingsDisplayContent;
