import React, { Component } from "react";

import { ContentSwitcher, Switch} from "carbon-components-react";
import * as pojoIdentifier from "./ServerResponsePojoIdentifier";
import JSONViewer from "react-json-viewer";

class ServerSyncCallResultDisplayUi extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  render() {
    const { resultToDisplay } = this.props;

    let indError = pojoIdentifier.isPojoErrorResult(resultToDisplay);

    if (indError) {

      let errorDisplay = {
        errorCode: resultToDisplay.errorCode,
        errorText: resultToDisplay.errorText,
      };


      if (resultToDisplay.e !== null) {
        return (
          <div>
            <br/>
            <div className="statusLineError">
            <JSONViewer
              json = {errorDisplay}
             />
            <JSONViewer
              json = {resultToDisplay.e}
             />
            </div>
  
          </div>
        );
  
      } else {
        return (
          <div>
            <br/>
            <div className="statusLineError">
            <JSONViewer
              json = {errorDisplay}
             />
            </div>
  
          </div>
        );
  
      }
    }

    return (
      <div>
        <p>&nbsp;</p>
      </div>
    );
  }
}

export default ServerSyncCallResultDisplayUi;

/**
 * 
 *           <p className="statusLineError">&nbsp;&nbsp;ErrorCode: {resultToDisplay.errorCode}</p>
 *         <p className="statusLineError">&nbsp;&nbsp;{resultToDisplay.errorText}</p>
 */