import React, { Component } from "react";

import * as CT_ErrorCodes from "./../../modules/commonxV3_001/codetable/CT_ErrorCodes"
import { IconContext } from "react-icons";
import { BsExclamationDiamondFill, BsExclamationTriangleFill } from "react-icons/bs";

class PreQExceptionHandlerEntry extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  render() {

    const { preQException, style } = this.props;

    let errorCode = preQException.errorCode;
    let hint = CT_ErrorCodes.getHintForCode(errorCode);

    let cssRowStyle = "";
    if (style === "sme") {
      cssRowStyle = "preQ_PreQException_background_sme";
    } else {
      cssRowStyle = "preQ_PreQException_background_wama";
    }

    let logLink = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
    + "/RESTServiceCall/UI/deliverLogFile/"
    + preQException.urlToLogFile;
    let logFileName = "logFile.log";

    return (
      <>
      <tr  class={cssRowStyle}>
        <td  style={{borderTop: "1px solid #d0d0d0",  width:"5%", verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px", fontSize:"90%"}}>
        { (preQException.critical === true)
            ? 
              <IconContext.Provider value={{className:"preQ_PreQException_icon_CRITICAL_sme"}}>
                <BsExclamationDiamondFill size="24"/>
              </IconContext.Provider>
            : 
              <IconContext.Provider value={{className:"preQ_PreQException_icon_UNCRITICAL_sme"}}>
                <BsExclamationTriangleFill size="24"/>
              </IconContext.Provider>
             }
        </td>  
        <td  style={{borderTop: "1px solid #d0d0d0",  width:"10%", verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px", fontSize:"90%"}}>
          &nbsp;&nbsp;ErrorCode: {preQException.errorCode}
        </td>
        <td style={{borderTop: "1px solid #d0d0d0", width:"85%", verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px", fontSize:"90%"}}>
          {preQException.errorText}
        </td>
      </tr>             
      <tr  class={cssRowStyle}>
        <td>
        </td>

        <td style={{verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px", fontSize:"80%"}}>
          &nbsp;
        </td>
        <td style={{verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px", fontSize:"80%"}}>
        TimeStamp: {preQException.timeStamp}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;in&nbsp;&nbsp;
        <a
                    href={logLink}
                    download ={logFileName}
                    rel="noopener"
                    aria-label='.log'
                    >
                    .log
                    </a>
        
        </td>
      </tr>

      <tr  class={cssRowStyle}>

        <td style={{verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px", fontSize:"90%"}}>
        
        </td>

        <td style={{verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px", fontSize:"90%"}}>
          
        </td>

        <td style={{verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px", fontSize:"90%"}}>
          {preQException.dtls}
        </td>

      </tr>         

      <tr  class={cssRowStyle}>

        <td style={{verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px", fontSize:"90%"}}>
        
        </td>

        <td style={{verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px", fontSize:"90%"}}>
          
        </td>

        <td style={{verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px", fontSize:"90%"}}>
          Hint: {hint}
        </td>

      </tr>         


    </>    );
  }
}

export default PreQExceptionHandlerEntry;
