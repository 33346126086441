import * as environmentService from "./../../EnvironmentService";
import * as CT_ADD_ModuleNames from "./../../commonxV3_001/codetable/CT_ADD_ModuleNames";
import * as PreQV3Constants from "./../../commonxV3_001/PreQV3Constants";
import * as CT_ADD_DropDownStandards from "./../../commonxV3_001/codetable/CT_ADD_DropDownStandards";
import * as Facade from "./../../Facade";

const HttpCodes = {
  OK : 200,
  BAD_REQUEST : 400,
  INTERNAL_SERVER_ERROR : 500,

}


export async function checkInFileUploadToStoredIqResultDirectory(orgUnit, caseid, uploadedid) {

  let module = CT_ADD_ModuleNames.CODE.JS_MODULE_ANONYMIZERS;
  let endPoint = '/preq~sbv3~service~anonymizers~catchstorediqmatches~checkin~upload' //+ {tenant}/{caseid}/{uploadedid}'
  endPoint = endPoint + '~' + orgUnit + '~' + caseid + '~' + uploadedid
  let checkInUrl = await Facade.REST_CALL_UR_FOR_ENDPOINT(module, endPoint);

  let response_FromServer = await fetch(checkInUrl, {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain',
      'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
      'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
    }
    ,
    body : null,
   })

  let httpStatus = HttpCodes.OK;
  if (!response_FromServer.ok) {
    httpStatus = response_FromServer.status;
  }

  if (httpStatus === HttpCodes.OK) {
      let data_FromServer = await response_FromServer.json();
      environmentService.log("data_FromServer", data_FromServer);
      return data_FromServer;
  }  

  if (httpStatus === HttpCodes.BAD_REQUEST) {
      let data_FromServer = await response_FromServer.json();
      environmentService.log("data_FromServer", data_FromServer);
      return data_FromServer;
  }

  if (httpStatus === HttpCodes.INTERNAL_SERVER_ERROR) {
      let data_FromServer = await response_FromServer.json();
      environmentService.log("data_FromServer", data_FromServer);
      return data_FromServer;
  }

  let errorJson = PreQV3Constants.CONSTANTS.ERROR_UNKNOWN;
  let error = JSON.parse(errorJson); 
  return error;

}


/**
 * This method reads list of available csv-lines to import to be offered/selected 
 * @returns 
 */
export async function getStoredIQResultCandidates() {

  let tenant = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_ORG_UNIT);
  let caseid = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_CASE);
  let callModul = CT_ADD_ModuleNames.CODE.JS_MODULE_ANONYMIZERS;
  let callEndPoint = "/preq/sbv3/service/anonymizers/storediq/match/list/csv/" + tenant + "/" + caseid + "";
  let result = [];

  let moduleBaseUrl = await environmentService.getModuleBaseUrl(callModul);
  let url = moduleBaseUrl + callEndPoint;
  
  let response_FromServer = await fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json, text/plain',
      'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
      'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
    }
   });
  let httpStatus = HttpCodes.OK;
  if (!response_FromServer.ok) {
    httpStatus = response_FromServer.status;
  }

  if (httpStatus === HttpCodes.OK) {
    let data_FromServer = await response_FromServer.json();
    let candidates = data_FromServer.candidates; //array of string
    for (const csvCandidate of candidates) {
      let result_entry1 = {
        key: csvCandidate,
        val: csvCandidate,
      }
    result.push(result_entry1);
    }
  }  
  return result;
}



/**
 * This method reads list of available csv-lines to import to be offered/selected 
 * @returns 
 */
 export async function getMatchCatcherCandidates() {

  let tenant = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_ORG_UNIT);
  let caseid = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_CASE);
  let callModul = CT_ADD_ModuleNames.CODE.JS_MODULE_ANONYMIZERS;
  let callEndPoint = "/preq/sbv3/service/anonymizers/match/result/lists/" + tenant + "/" + caseid + "";
  let result = [];

  let moduleBaseUrl = await environmentService.getModuleBaseUrl(callModul);
  let url = moduleBaseUrl + callEndPoint;
  
  let response_FromServer = await fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json, text/plain',
      'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
      'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
    }
   });
  let httpStatus = HttpCodes.OK;
  if (!response_FromServer.ok) {
    httpStatus = response_FromServer.status;
  }

  if (httpStatus === HttpCodes.OK) {
    let data_FromServer = await response_FromServer.json();
    let candidates = data_FromServer.matchCatcherResults; //array of string
    for (const matchCatcherCandidate of candidates) {
      let result_entry1 = {
        key: matchCatcherCandidate,
        val: matchCatcherCandidate,
      }
    result.push(result_entry1);
    }
  }

  let result_entry2 = {
    key: CT_ADD_DropDownStandards.CODE.JS_DROPDOWN_USE_FIRST_AVAILABLE_MATCH_ID,
    val: CT_ADD_DropDownStandards.getTextForCode(CT_ADD_DropDownStandards.CODE.JS_DROPDOWN_USE_FIRST_AVAILABLE_MATCH_ID),
  }
  result.push(result_entry2);
  
  return result;
}


/**
 * This method reads list of ContentTypes/Amounts incl the used SQL-Queries 
 * @returns 
 */
export async function getAnonymizersAmounts(processId) {

  let callModul = CT_ADD_ModuleNames.CODE.JS_MODULE_ANONYMIZERS;
  let callEndPoint = "/preq/sbv3/service/anonymizers/report/amounts/" + processId + "";

  let moduleBaseUrl = await environmentService.getModuleBaseUrl(callModul);
  let url = moduleBaseUrl + callEndPoint;
  
  let response_FromServer = await fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json, text/plain',
      'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
      'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
    }
   });
  if (!response_FromServer.ok) {
    return null;
  }

  let data_FromServer = await response_FromServer.json();
  environmentService.log("data_FromServer", data_FromServer);
  
  return data_FromServer;

}




/**
 * This method reads Info about StoredIQ Import files
 * @returns 
 */
export async function getStoredfIQImportFileInfo(processId) {

  let tenant = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_ORG_UNIT);
  let caseid = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_SELECTED_CASE);
  let callModul = CT_ADD_ModuleNames.CODE.JS_MODULE_ANONYMIZERS;
  let callEndPoint = "/preq/sbv3/service/anonymizers/storediq/match/list/csv/"  + tenant + "/" + caseid + "";

  let moduleBaseUrl = await environmentService.getModuleBaseUrl(callModul);
  let url = moduleBaseUrl + callEndPoint;
  
  let response_FromServer = await fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json, text/plain',
      'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
      'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
    }
   });
  if (!response_FromServer.ok) {
    return null;
  }

  let data_FromServer = await response_FromServer.json();
  environmentService.log("data_FromServer", data_FromServer);
  
  return data_FromServer;

}



