import React, { Component } from 'react';
import './app.scss';
import { Content } from 'carbon-components-react';
import ApplicationHeader from './components/ApplicationHeader/ApplicationHeader';
import { Theme } from '@carbon/react';
import { Route, Switch } from 'react-router-dom';
import LandingPage from './content/LandingPage/LandingPage';

import i18next from "i18next"

import * as PreQV3Constants from "./modules/commonxV3_001/PreQV3Constants"
import * as environmentService from "./modules/EnvironmentService";

class App extends Component {

  constructor(props) {
    super(props);
    let today = new Date();
    var now = today.toLocaleString();

    this.state = {
     renderTimeStamp : now,
     uiParams : null,
     showAppInfo : false,
     showAppAbout : false
    };

  }

  async componentDidMount() {
    this.updateUiParams();
  }

  async updateUiParams() {
    let uiParams = await environmentService.getUiParams();
    let uiParamsAsString = JSON.stringify(uiParams);
    sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_UI_PARAMETERS, uiParamsAsString);
    this.setState({uiParams : uiParams });
    let i18nSessionVar = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED);
    if ((i18nSessionVar === null) || (i18nSessionVar === undefined)) {
      i18nSessionVar = "en";
      sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED, i18nSessionVar);
    }
    i18next.changeLanguage(i18nSessionVar);

  }


  render() {

    const {showAppAbout, showAppInfo} = this.state;

    return (
      <>
        <Theme theme="g100">
          <ApplicationHeader 
              uiParams = {this.state.uiParams}
          />
        </Theme>     
        
        <Content>
          <Switch>
            <Route exact path="/" component={() => 
              <LandingPage 
                updateUi={this.updateUi.bind(this)}/> } 
            />
          </Switch>
        </Content>
      </>
    );
  }

  updateUi() {
    let today = new Date();
    var now = today.toLocaleString();
    this.setState({
      renderTimeStamp : now
    });

  }

}

export default App;