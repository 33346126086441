import React, { Component } from "react";

import { ContentSwitcher, NumberInputSkeleton, Switch} from "carbon-components-react";
import DevelopmentTag from "./../../common/DevelopmentTag";
import ReactJson from "react-json-view-with-toggle";

class Parameter200 extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  render() {
    let {sequenceindex, entry}  = this.props;
    if (sequenceindex === 200) {

      return (
        <div className="preQ_SME_label">
          <br />No Parameter(s)<br /><br />
          <hr />
        </div>
      );

    }

    return (
      <div></div>
    );
  
  }

}

export default Parameter200;
