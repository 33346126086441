import React, { Component } from "react";
import { InlineLoading } from "carbon-components-react";

import WamaDisplayFileUploadID from "./WamaDisplayFileUploadID";
import WamaDisplayErrorWrapper from './WamaDisplayErrorWrapper';

import i18next from "i18next";


class WamaFileUploading extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {

      };
  }

  render() {
    const {acceptedFiles} = this.props;

    return (
      <div className="grid-setup margin-t-96">
        <div className="padding-16 bg-inverse width-608 tile-center margin-b-40">
          <h3 className="margin-b-16">{i18next.t("app.title-short")}</h3>
          <p className="margin-b-16">
            <WamaDisplayFileUploadID />
            {i18next.t("WamaFileUploading.text")} {acceptedFiles[0].name}<br/><br/>
            <InlineLoading/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{i18next.t("WamaFileUploading.wait")}
            <WamaDisplayErrorWrapper />
          </p>
              
        </div>
      </div>
    );

  }
}

export default WamaFileUploading;
