import * as environmentService from "./../../EnvironmentService";
import * as PreQV3Constants from "./../../commonxV3_001/PreQV3Constants";
import * as Facade from "./../../Facade";

const HttpCodes = {
  OK : 200,
  BAD_REQUEST : 400,
  INTERNAL_SERVER_ERROR : 500,

}



export async function getExceptions(amount, cutoff) {

  let restURL = await Facade.REST_CALL_UR_FOR_ENDPOINT('UI', '/exceptions/?amount=' + amount + '&cutoff=' + cutoff);
  let response_FromServer = await fetch(restURL, {
    method: 'GET',
    headers: {
      'Accept': 'application/json, text/plain'
    }
   });

  let httpStatus = HttpCodes.OK;
  if (!response_FromServer.ok) {
    httpStatus = response_FromServer.status;
  }

  if (httpStatus === HttpCodes.OK) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }
  if (httpStatus === HttpCodes.BAD_REQUEST) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }
  if (httpStatus === HttpCodes.INTERNAL_SERVER_ERROR) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }

  let errorJson = '{"errorText": "","errorCode": "00000"}';
  let error = JSON.parse(errorJson); 
  return error;
}


export async function deleteExceptions(type, id) {

  let sUrl = '/exceptiondeletion/?type=' + type + '&id=' + id;
  let restURL = await Facade.REST_CALL_UR_FOR_ENDPOINT('UI', sUrl);
  let response_FromServer = await fetch(restURL, {
    method: 'GET',
    headers: {
      'Accept': 'application/json, text/plain',
      'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
      'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
    }
   });

  let httpStatus = HttpCodes.OK;
  if (!response_FromServer.ok) {
    httpStatus = response_FromServer.status;
  }

  if (httpStatus === HttpCodes.OK) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }
  if (httpStatus === HttpCodes.BAD_REQUEST) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }
  if (httpStatus === HttpCodes.INTERNAL_SERVER_ERROR) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }

  let errorJson = '{"errorText": "","errorCode": "00000"}';
  let error = JSON.parse(errorJson); 
  return error;
}
