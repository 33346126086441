import React, { Component } from "react";

import { IoIosRemove } from "react-icons/io";
import { IconContext } from "react-icons";

class InvisibleIcon extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <IconContext.Provider value={{ color: "#ffffff", size: "50px" }}>
        <IoIosRemove size="22" />
      </IconContext.Provider>
    );
  }
}

export default InvisibleIcon;
