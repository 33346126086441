import React, { Component } from "react";

import WamaInput from "./WamaInput";
import WamaProcessing from "./WamaProcessing";
import WamaDisplayError from "./WamaDisplayError";
import WamaFileUploading from "./WamaFileUploading";

import * as PreQV3Constants from "./../../../modules/commonxV3_001/PreQV3Constants"
import * as restFileUploadClient from "./../../commonxV3_001/backend/RestFileUploadClient"
import * as serverResponsePojoIdentifier from "./../../commonxV3_001/ServerResponsePojoIdentifier"
import * as WamaRestClient from "./../backend/WamaRestClient";
import * as ErrorCodeBuilder from "./../../commonxV3_001/backend/ErrorCodeBuilder";


class WamaUi extends Component {
  constructor(props) {
    super(props);
    this.resetMainUiSate();
  }

  async resetMainUiSate() {
       // Create the state
       let today = new Date();
       var now = today.toLocaleString();

       this.state = {
        autoUpdateTimeStamp : now,
        inputEditVisible:true,
        progressBarVisible:false,
        resultVisible:false,
        loadingVisible: false,

        error:null,
        errorMessageVisible:false,

        acceptedFiles: [],


      };

  }


  /**
   * This method uploads the acceptedFiles via UIProxy and triggers then WAMA-Processing
   * In case of an error the state represents the error: {error is set, errorMessageVisible:true}
   * In case of success the ui switches to processing mode {progressBarVisible:true}
   * @param {*} acceptedFiles 
   */
  async doStartProgress(acceptedFiles) {
    this.setState({
      loadingVisible: true,
      acceptedFiles: acceptedFiles,
    });
    let data_FromServerFileUpload = await restFileUploadClient.submitOneFile(acceptedFiles);
    let indErrorFileUpload = serverResponsePojoIdentifier.isPojoErrorResult(data_FromServerFileUpload);
    if (indErrorFileUpload === true) {
//      let visibleError = ErrorCodeBuilder.displayableErrorObject(data_FromServerFileUpload.errorCode, data_FromServerFileUpload.errorText);
      this.setState({
        loadingVisible: false,
        error:data_FromServerFileUpload,
        errorMessageVisible:true
      });
      return;
    } else {
      let uploadID = data_FromServerFileUpload.uploadID;
      sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_CURRENT_WAMA_FILE_UPLOAD_ID, uploadID);
      sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_CURRENT_WAMA_WAITS_FOR_PROCESSING, PreQV3Constants.CONSTANTS.BOOLEAN_TRUE);

      let data_fromServerWamaTrigger = await WamaRestClient.triggerWama(uploadID);
      let indErrorWamaTrigger = serverResponsePojoIdentifier.isPojoErrorResult(data_fromServerWamaTrigger);

      if (indErrorWamaTrigger === true) {
//        let visibleError = ErrorCodeBuilder.displayableErrorObject(data_fromServerWamaTrigger.errorCode, data_fromServerWamaTrigger.errorText);
        this.setState({
          loadingVisible: false,
          error:data_fromServerWamaTrigger,
          errorMessageVisible:true
        });
        return;
      }

      this.setState({
        loadingVisible: false,
        inputEditVisible:false,
        progressBarVisible:true,
        error:null,
        errorMessageVisible:false
    });


    }
  }

  /**
   * This method sets the error - information
   * @param {*} error can be filled with an error object or is null (no error)
   */
  setError(error) {
    if (error === null) {
      this.setState({
        error:null,
        errorMessageVisible:false,
      });
    } else {
      this.setState({
        error:error,
        errorMessageVisible:true,
      });
    }
  }

  render() {

    const {inputEditVisible, progressBarVisible, resultVisible, acceptedFiles, loadingVisible, errorMessageVisible, error} = this.state;

    if (loadingVisible) {
      return(
        <div>
        	<WamaFileUploading 
                acceptedFiles = {acceptedFiles}
                errorMessageVisible = {errorMessageVisible}
                error = {error}
                setError = {this.setError.bind(this)}
        	/>
        </div>
      );
    }

    return (
      <div>
          {inputEditVisible
          ? <div>
              <WamaInput
                doStartProgress = {this.doStartProgress.bind(this)}
                errorMessageVisible = {errorMessageVisible}
                error = {error}
                setError = {this.setError.bind(this)}
              />
              <br/>
            </div>
          : <div></div>}

          {progressBarVisible
          ? <div>
              <WamaProcessing
                acceptedFiles = {acceptedFiles}
                resetMainUiSate = {this.resetMainUiSate.bind(this)}
                errorMessageVisible = {errorMessageVisible}
                error = {error}
                setError = {this.setError.bind(this)}
              />
              <br/>
            </div>
          : <div></div>  
          }

      </div>
    );
  }


}

export default WamaUi;
