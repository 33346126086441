import * as PreQV3Constants from "./../PreQV3Constants";

export const CODE = {
  UNDEFINED: "",
  JS_UI_NO_ERROR_CODE: "00000",
  JS_NO_ERROR_CODE: "00001",
  JS_DIRECTORY_NOT_AVAILABLE: "00002",
  JS_TENANT_ALREADY_EXISTS: "00003",
  JS_TENANT_NAME_NOT_ALLOWED: "00004",
  JS_TENANT_NOT_AVAILABLE: "00005",
  JS_TENANT_DIR_NOT_WRITABLE: "00006",
  JS_CASE_ALREADY_EXISTS: "00007",
  JS_CASEID_NOT_ALLOWED: "00008",
  JS_CASE_NOT_AVAILBLE: "00009",
  JS_DB_SCHEMATAS_INVALID: "00010",
  JS_RUNNING_PROCESS: "00011",
  JS_PROCESS_IS_NOT_AVAILABLE: "00012",
  JS_DIRECTORY_IS_EMPTY: "00013",
  JS_DIRECTORY_IS_NOT_EMPTY: "00014",
  JS_PROCESSID_UNDETERMINABL: "00015",
  JS_PROCESS_IS_NOT_DONE: "00016",
  JS_MISSING_CONTENT_TYPE_INFOMATION: "00017",
  JS_CONTENT_TYPE_SELECTION_FOR_CONVERTER_MISSING: "00018",
  JS_CONTENT_TYPE_SELECTION_NOT_MATCHING_MOST_YOUNGEST_CONTENT_TYPE_INFO: "00019",
  JS_COMBINATION_OF_CONTENT_TYPE_AND_FILE_LIST_NOT_ALLOWED: "00020",
  JS_INVALID_SELECTED_ANONYMIZERS: "00021",
  JS_GIVEN_ANONYMIZERS_FILELIST_NOT_EMPTY: "00022",
  JS_UNANONYMIZABLE_FILES_DETECTED: "00023",
  JS_NO_RESET_DUE_TO_RUNNING_PROCESSES: "00024",
  JS_NON_EXISTING_FILE_OR_DIRECTORY_DETECTED: "00025",
  JS_REQUIRED_PATH_PERMISSIONS_NOT_SET: "00026",
  JS_TENANT_IS_EMPTY_STRING: "00027",
  JS_TENANT_CONTAINS_INVALID_CHARACTERS: "00028",
  JS_CASEID_IS_EMPTY_STRING: "00029",
  JS_CASEID_CONTAINS_INVALID_CHARACTERS: "00030",
  JS_CHAIN_TO_PROCESS_INVALID_JSON: "00031",
  JS_CHAIN_NOT_CONTINUABLE_BASED_ON_THIS_SEQUENCE: "00032",
  JS_FOLDER_NAME_IS_NOT_VALID: "00033",
  JS_FILE_UPLOAD_FOLFER_NOT_FOUND: "00034",
  JS_FILE_UPLOAD_FOLFER_IS_EMPTY: "00035",
  JS_FILE_UPLOAD_UNZIP_FAILED: "00036",
  JS_FILE_UPLOAD_UNALLOWD_FILE: "00037",
  JS_FILE_DOWNLOAD_FOLFER_NOT_FOUND: "00038",
  JS_FILE_DOWNLOAD_FOLFER_IS_EMPTY: "00039",
  JS_WAMA_UNALLOWED_FILETYPE: "00040",
  JS_WAMA_UNALLOWED_FILESIZE: "00041",
  JS_USERMGMT_UNEXPECTED_URL: "00042",
  JS_USERMGMT_UNEXPECTED_COMMAND: "00043",
  JS_USERMGMT_UNKNOWN_IDENTIFICATION: "00044",
  JS_USERMGMT_FILE_NOT_AVAILABLE: "00045",
  JS_USER_UNKNOWN: "00046",
  JS_FILE_NOT_EXISTING: "00047",
  JS_MODULE_NOT_REACHIBLE: "00048",
  JS_SQL_DATABASE_ERROR: "00049",
  JS_EXTRACTOR_COULD_NOT_PROCESS_FILE: "00050",
  JS_EXTRACTOR_COULD_NOT_BROWSE_FILES_IN_PATH: "00051",
  JS_THREAD_WORKLOAD_DISPLAY_INCONSISTENT: "00052",
  JS_PROCESS_HANDLING_FSILED: "00053",
  JS_IO_EXCEPTION: "00054",
  JS_COMMAND_EXECUTION_ERROR: "00055",
  JS_PROCESS_SEQUENCE_IDENTIFICATION_FAILED: "00056",
  JS_FILE_SECURITY_EXCEPTION: "00057",
  JS_MODULE_CONFIG_ERRORPRONE: "00058",
  JS_ARCHIVE_HANDLING_FAILED: "00059",
  JS_EXCEL_GENERATION_FAILED: "00060",
  JS_INVALID_CSV: "00061",
  JS_WATSON_INVOCATION_FAILED: "00062",
  JS_PLUGIN_INVOCATION_FAILED: "00063",
  JS_FILE_HANDLING_GENERAL_ISSUE: "00064",
  JS_PROCESS_SEQUENCER_FAILED: "00065",
  JS_TRANSACION_KEY_ERROR: "00066",
  JS_QUALITY_GATE_FAILED: "00067",
  JS_FILE_CONVERTER_FAILED: "00068",
  JS_REPORT_GENERATION_FAILED: "00069",
  JS_MATCH_IMPORT_FAILED: "00070",
  JS_UNKNOWN_REVIEW_ID: "00071",
  JS_NO_MATCHES_AVALABLE: "00072",
  JS_REVIEW_RESULT_NONCSV_FILES_DETECTED: "00073",
  JS_REVIEW_RESULT_CSV_WRONG_LINES: "00074",
  JS_REVIEW_RESULT_CSV_WRONG_COLUMNS: "00075",
  JS_REVIEW_RESULT_PROCESSING_LINK_INCONSISTENT: "00076",
  JS_REVIEW_RESULT_INVALID_PRIMARY_KEY: "00077",
  JS_REVIEW_RESULT_ICONSISTENT_PROCESSING_LINKS: "00078",
  JS_REVIEW_RESULT_UNKNOWN_USER_DEFINED_ACTION: "00079",
  JS_REVIEW_RESULT_CSV_FILES_MISSING: "00080",
  JS_REVIEW_RESULT_CONTAINS_SUB_DIRS: "00081",
  JS_REVIEW_EXPORT_CREATION_OF_CSV_NOT_IN_TIME: "00082",
  JS_REVIEW_EXPORT_MISSING_CREATED_CSV_FILES: "00083",
  JS_COMMON_X_VERSIONS_NOT_CONSISTENT: "00084",
  JS_FILE_NOT_PROPER_IDENTIFIABLE_IN_CONTENTTYPES_FILES: "00085",
  JS_NO_MORE_FILES_LEFT_FOR_PROCESSING: "00086",
}


export function getTextForCode(code) {

  let i18nSessionVar = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED);
  if ((i18nSessionVar === null) || (i18nSessionVar === undefined)) {
    i18nSessionVar = "en";
  }

  if (i18nSessionVar === "de") {

    switch (code) {
      case "00000": return "FrontEnd: Keine Fehler Details";
      case "00001": return "Keine Fehler Details";
      case "00002": return "Verzeichnis existiert nicht";
      case "00003": return "Der Kunde (tenant) existiert bereits";
      case "00004": return "Dieser Kundenname ist nicht erlaubt";
      case "00005": return "Dieser Kunde ist nicht vorhanden";
      case "00006": return "Kein voller Zugriff auf das Kundenverzeichnis";
      case "00007": return "Der Fall existiert bereits";
      case "00008": return "Diese Identifikation eines Falles ist nicht erlaubt";
      case "00009": return "Der Fall ist nicht vorhanden";
      case "00010": return "Das Datenbank-Schema ist nicht valide";
      case "00011": return "Es ist bereits ein Prozess aktiv";
      case "00012": return "Dieser Proze� ist nicht verf�gbar";
      case "00013": return "Das Verzeichnis ist leer";
      case "00014": return "Das Verzeichnis ist nicht leer";
      case "00015": return "Die ProzessID konnte nicht identifiziert werden";
      case "00016": return "Der Prozess ist nicht abgeschlossen";
      case "00017": return "Fehlender Informationen: ContentTypes";
      case "00018": return "Auswahl des(der) ContentTypes fehlerhaft (erlaubt: '*' oder ein ContentType (mehrere ContentTypes als komma-separierte Liste)";
      case "00019": return "Auswahl des(der) ContentTypes fehlerhaft: Es sind nicht alle vorhanden in den zuletzt identifizierten ContentTypes";
      case "00020": return "Die Kombination ContentType(s) und angegebene FileListe ist nicht erlaubt";
      case "00021": return "Die Auswahl der Anonymizer ist nicht g�ltig";
      case "00022": return "Bei Auswahl von Anonymizern muss die Liste der Dateien leer sein";
      case "00023": return "Nicht anonymisierbare Dateien identifiziert";
      case "00024": return "Reset abgebrochen, da unberarbeitete Prozesse anstehen ";
      case "00025": return "Nicht existierende Dateien oder Verzeichnisse identifiziert";
      case "00026": return "Erforderliche Pfadzugriffsrechte sind nicht gesetzt";
      case "00027": return "Der Kunde darf nicht durch einen Leerstring benannt werden";
      case "00028": return "Der Kundenname beinhaltet unerlaubte Zeichen";
      case "00029": return "Der Fall darf nicht durch einen Leerstring benannt werden";
      case "00030": return "Die Fall-ID beinhaltet unerlaubte Zeichen";
      case "00031": return "Der Parameter identifiziert keine g�ltige Prozesskette";
      case "00032": return "Die Prozesskette kann in dieser Form nicht fortgesetzt werden";
      case "00033": return "Der Verzeichnisname ist nicht korrekt";
      case "00034": return "Das Upload Verzeichnis ist nicht vorhanden";
      case "00035": return "Das Upload Verzeichnis ist leer";
      case "00036": return "Das .zip-File im Upload-Verzeichnis konnte nicht entpackt werden";
      case "00037": return "Der Upload beinhaltet nicht erlaubte Dateitypen";
      case "00038": return "Das Verzeichnis zum Download der Dateien ist nicht vorhanden";
      case "00039": return "Das Verzeichnis zum Download der Dateien ist leer";
      case "00040": return "Nicht erlaubter Datei-Typ";
      case "00041": return "Zu gro�e Datei(en)";
      case "00042": return "Unerwartete Url";
      case "00043": return "Unbekanntes Kommando";
      case "00044": return "Unbekannte Identifikation";
      case "00045": return "Datei mit Benutzerliste fehlt";
      case "00046": return "Benutzer unbekannt";
      case "00047": return "Datei ist nicht vorhanden";
      case "00048": return "Module ist nicht erreichbar";
      case "00049": return "SQL/Datenbank-Fehler";
      case "00050": return "Der Extraktor konnte die Datei nicht prozessieren";
      case "00051": return "Der Extraktor konnte die Dateien nicht identifizieren";
      case "00052": return "Die Anzeige Workload/Thread kann inkonsistent sein";
      case "00053": return "Die Handhabe von Prozessen funktioniert nicht";
      case "00054": return "I/O Ausnahme (Exception)";
      case "00055": return "Kommando konnte nicht prozessiert werden";
      case "00056": return "Identifikation der Prozess Sequenz fehlerhaft";
      case "00057": return "Datei Sicherheit (Exception)";
      case "00058": return "Fehlerhafte Module-Konfiguration";
      case "00059": return "Archive Handhabung fehlgeschlagen";
      case "00060": return "Excel Generierung fehlgeschlagen";
      case "00061": return ".csv-Datei ist nicht valide";
      case "00062": return "Watson Aufruf fehlgeschlagen";
      case "00063": return "Plugin Aufruf fehlgeschlagen";
      case "00064": return "Problem bei der Handhabung von Dateien";
      case "00065": return "Die Verarbeitung einer Prozess-Kette ist fehlgeschlagen";
      case "00066": return "Transaktions Key Fehler";
      case "00067": return "Qualitatives Resultat nicht erreicht";
      case "00068": return "Datei Converter fehlgeschlagen";
      case "00069": return "Report Generierung fehlgeschlagen";
      case "00070": return "Import der Matches fehlgeschlagen";
      case "00071": return "Die Review ID ist nicht bekannt";
      case "00072": return "Keine Treffer vorhanden";
      case "00073": return "Im Review-Resultat wurden Dateien erkannt, die keine CSV-Dateien sind";
      case "00074": return "Review-Result: CSV-Dateien nicht konsistent (Zeilen)";
      case "00075": return "Review-Result-Datei(en) beinhaltet unerwartete Spalten";
      case "00076": return "Review-Result hat keine valide Verbindung zur Fallverarbeitung";
      case "00077": return "Review-Result hat nicht valide Primary Keys";
      case "00078": return "Review-Result hat inkonsistente Verbindungen zur Fallverarbeitung";
      case "00079": return "Review-Result beinhaltet unbekannte Aktionen";
      case "00080": return "Review-Result beinhaltet keine CSV-Dateien";
      case "00081": return "Review-Result beinhaltet nicht erlaubte Unterverzeichnisse";
      case "00082": return "Review-Export: CSV Erstellung erfolgte nicht in vorgegebener Zeit";
      case "00083": return "Review-Export: erstellte CSV Dateien fehlen";
      case "00084": return "CommonX: Version ist nicht konsistent bei allen ausgerollten Spring Boot Modulen";
      case "00085": return "Kein korrektes Ergebnis bei der Identifikation einer Datei in der Tabelle contenttypes_files";
      case "00086": return "Kein Dateien mehr zur Verarbeitung vorhanden (alle in Quarantaene)";
      default:
        return code;
    }
  }

  switch (code) {
    case "00000": return "Ui: No Error Details";
    case "00001": return "No Error Details";
    case "00002": return "Directory does not exist";
    case "00003": return "Tenant already exists";
    case "00004": return "Tenant name is not allowed";
    case "00005": return "Tenant is not available";
    case "00006": return "No full acccess to Tenant-Directory";
    case "00007": return "Case already exists";
    case "00008": return "This Case identification is not allowed";
    case "00009": return "Case is not available";
    case "00010": return "Database Schemata is invalid for this module";
    case "00011": return "There is currently a process running";
    case "00012": return "Process is not available";
    case "00013": return "Directory is empty";
    case "00014": return "Directory is not empty";
    case "00015": return "ProcessID could not be identified";
    case "00016": return "ProcessID is not done";
    case "00017": return "Missing Content Type Information";
    case "00018": return "Wrong contenttype selection (enter '*' or enter a ContentType (mulitpile: commata separated)";
    case "00019": return "ContentType(s) are missing in most youngest detected ContentFiles";
    case "00020": return "Combination of ContentType and FileList is not allowed";
    case "00021": return "Invalid selected Anonymizers";
    case "00022": return "With given Anonymizer(s) the file-list (originPathes) must be empty";
    case "00023": return "Unanonymizable Files detected";
    case "00024": return "Nothing resetted, because there are still undone asynchronous processes";
    case "00025": return "Non existing files/directories identified";
    case "00026": return "Required Path Permissions are not set correct";
    case "00027": return "Tenant is not allowed to be an empty string";
    case "00028": return "Tenant name is not allowed (invalid characters)";
    case "00029": return "Case is not allowed to be an empty string";
    case "00030": return "CaseID is not allowed (invalid characters)";
    case "00031": return "Parameter identifying chain to process is not valid";
    case "00032": return "Chain can not be continued this way";
    case "00033": return "Folder Name is not valid";
    case "00034": return "File Upload Folder not found";
    case "00035": return "File Upload Folder is empty";
    case "00036": return ".zip-File in Upload-Folder could not be unzipped";
    case "00037": return "File Upload contains unallowed file type";
    case "00038": return "Directory to Download files from is not available";
    case "00039": return "Directory to Download files from is empty";
    case "00040": return "Unallowed FileType";
    case "00041": return "Unallowed file-size";
    case "00042": return "Unexpected Url";
    case "00043": return "Unknown Command";
    case "00044": return "Unknown Identification";
    case "00045": return "File with user list is missing";
    case "00046": return "Unknown User";
    case "00047": return "File does not exist";
    case "00048": return "Module not reachible";
    case "00049": return "SQL/Database Error";
    case "00050": return "Extractor could not process file";
    case "00051": return "Extractor could not browse files in Path";
    case "00052": return "Display of Thread Workload might be inconsistent";
    case "00053": return "Handling of Processes does not work properly";
    case "00054": return "I/O Excpetion";
    case "00055": return "Command Execution failed";
    case "00056": return "Process Sequence Identification failed";
    case "00057": return "File Security Excpetion";
    case "00058": return "Configuration of Modules is errorprone";
    case "00059": return "Archive Handling failed";
    case "00060": return "Excel Generation failed";
    case "00061": return ".csv-File is not valid";
    case "00062": return "Watson Invocation failed";
    case "00063": return "Plugin Invocation failed";
    case "00064": return "Filehandliung Issue";
    case "00065": return "Process Sequencer failed";
    case "00066": return "TransactionKey error";
    case "00067": return "Quality Gate failed";
    case "00068": return "File converter failed";
    case "00069": return "Report generation failure";
    case "00070": return "Match import failure";
    case "00071": return "Review ID is unknwon";
    case "00072": return "No matches available";
    case "00073": return "Review-Result Files have been detected, which are not CSV";
    case "00074": return "Review-Result-File(s) not consistent (lines)";
    case "00075": return "Review-Result-File(s) with wrong columns detected";
    case "00076": return "Review-Result with wrong link to case-processing detected";
    case "00077": return "Review-Result with wrong primary key(s) detected";
    case "00078": return "Review-Result with inconsistent processing-links detected";
    case "00079": return "Review-Result with unknown action(s) detected";
    case "00080": return "Review-Result without CSV-Files detected";
    case "00081": return "Review-Result contains unallowed Sub-Directories";
    case "00082": return "Review-Export: creation of csv-files not in time";
    case "00083": return "Review-Export: missing created csv-files";
    case "00084": return "CommonX: Version not consistent over all deployed Spring Boot Modules";
    case "00085": return "Identification of File in Table contenttypes_files not correct";
    case "00086": return "No more files left for processing (due to Quarantine)";
  
    default:
      return "";
  }
}  
  
export function getHintForCode(code) {

  let i18nSessionVar = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED);
  if ((i18nSessionVar === null) || (i18nSessionVar === undefined)) {
    i18nSessionVar = "en";
  }

  if (i18nSessionVar === "de") {

    switch (code) {
      case "00000": return "";
      case "00001": return "";
      case "00002": return "";
      case "00003": return "";
      case "00004": return "";
      case "00005": return "";
      case "00006": return "";
      case "00007": return "";
      case "00008": return "";
      case "00009": return "";
      case "00010": return "";
      case "00011": return "";
      case "00012": return "";
      case "00013": return "";
      case "00014": return "";
      case "00015": return "";
      case "00016": return "";
      case "00017": return "";
      case "00018": return "";
      case "00019": return "";
      case "00020": return "";
      case "00021": return "";
      case "00022": return "";
      case "00023": return "";
      case "00024": return "";
      case "00025": return "";
      case "00026": return "";
      case "00027": return "";
      case "00028": return "";
      case "00029": return "";
      case "00030": return "";
      case "00031": return "";
      case "00032": return "";
      case "00033": return "";
      case "00034": return "";
      case "00035": return "";
      case "00036": return "";
      case "00037": return "";
      case "00038": return "";
      case "00039": return "";
      case "00040": return "";
      case "00041": return "";
      case "00042": return "";
      case "00043": return "";
      case "00044": return "";
      case "00045": return "";
      case "00046": return "";
      case "00047": return "";
      case "00048": return "Bitte besuchen Sie diese Seite nach dem Neustart ALLER PreQ-Module";
      case "00049": return "Bitte besuchen Sie diese Seite nach dem Neustart ALLER PreQ-Module";
      case "00050": return "";
      case "00051": return "";
      case "00052": return "�erpr�fen des PostgreSQL-Moduls";
      case "00053": return "";
      case "00054": return "Zu �berpr�fen: Dateizugriffe";
      case "00055": return "Zu �berpr�fen: Rechte zum Ausf�hren von Betriebssystem-Kommandos";
      case "00056": return "Neustart von PreQ and sicherstellen: Module sind gestartet";
      case "00057": return "Zu �berpr�fen: Dateizugriffsrechte";
      case "00058": return "Zu �cberpr�fen: Preq-Konfiguration (z.B. .env)";
      case "00059": return "";
      case "00060": return "";
      case "00061": return "";
      case "00062": return "";
      case "00063": return "";
      case "00064": return "";
      case "00065": return "";
      case "00066": return "Neustart des Browsers (PreQ-Client)";
      case "00067": return "";
      case "00068": return "";
      case "00069": return "";
      case "00070": return "";
      case "00071": return "";
      case "00072": return "";
      case "00073": return "";
      case "00074": return "";
      case "00075": return "";
      case "00076": return "";
      case "00077": return "";
      case "00078": return "";
      case "00079": return "";
      case "00080": return "";
      case "00081": return "";
      case "00082": return "";
      case "00083": return "";
      case "00084": return "Build & Deployment muss neu gestartet werden";
      case "00085": return "";
      case "00086": return "";
      default:
        return code;
    }
  }

  switch (code) {
    case "00000": return "";
    case "00001": return "";
    case "00002": return "";
    case "00003": return "";
    case "00004": return "";
    case "00005": return "";
    case "00006": return "";
    case "00007": return "";
    case "00008": return "";
    case "00009": return "";
    case "00010": return "";
    case "00011": return "";
    case "00012": return "";
    case "00013": return "";
    case "00014": return "";
    case "00015": return "";
    case "00016": return "";
    case "00017": return "";
    case "00018": return "";
    case "00019": return "";
    case "00020": return "";
    case "00021": return "";
    case "00022": return "";
    case "00023": return "";
    case "00024": return "";
    case "00025": return "";
    case "00026": return "";
    case "00027": return "";
    case "00028": return "";
    case "00029": return "";
    case "00030": return "";
    case "00031": return "";
    case "00032": return "";
    case "00033": return "";
    case "00034": return "";
    case "00035": return "";
    case "00036": return "";
    case "00037": return "";
    case "00038": return "";
    case "00039": return "";
    case "00040": return "";
    case "00041": return "";
    case "00042": return "";
    case "00043": return "";
    case "00044": return "";
    case "00045": return "";
    case "00046": return "";
    case "00047": return "";
    case "00048": return "Please revisit page, after ALL PreQ-Modules have been restarted";
    case "00049": return "Please revisit page, after ALL PreQ-Modules have been restarted";
    case "00050": return "";
    case "00051": return "";
    case "00052": return "Check PostgreSQL-Module";
    case "00053": return "";
    case "00054": return "Check: FileAccess";
    case "00055": return "Check: Operating System-Command Execution Privilegues";
    case "00056": return "Restart PreQ Modules and check: modules are started";
    case "00057": return "Check: File Access Rights";
    case "00058": return "Check: PreqConfig (e.g. .env)";
    case "00059": return "";
    case "00060": return "";
    case "00061": return "";
    case "00062": return "";
    case "00063": return "";
    case "00064": return "";
    case "00065": return "";
    case "00066": return "Restart Browser(Preq-Client)";
    case "00067": return "";
    case "00068": return "";
    case "00069": return "";
    case "00070": return "";
    case "00071": return "";
    case "00072": return "";
    case "00073": return "";
    case "00074": return "";
    case "00075": return "";
    case "00076": return "";
    case "00077": return "";
    case "00078": return "";
    case "00079": return "";
    case "00080": return "";
    case "00081": return "";
    case "00082": return "";
    case "00083": return "";
    case "00084": return "Rebuild and ReDeployment must be performed";
    case "00085": return "";
    case "00086": return "";
  
    default:
      return "";
  }
  
}

