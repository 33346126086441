import * as PreQV3Constants from "./../PreQV3Constants";

export const CODE = {
  UNDEFINED: "",
  JS_DE: "de",
  JS_EN: "en",
}


export function getTextForCode(code) {

  let i18nSessionVar = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED);
  if ((i18nSessionVar === null) || (i18nSessionVar === undefined)) {
    i18nSessionVar = "en";
  }

  if (i18nSessionVar === "de") {

    switch (code) {
      case "de": return "Deutsch";
      case "en": return "Englisch";
      default:
        return code;
    }
  }

  switch (code) {
    case "de": return "German";
    case "en": return "English";
  
    default:
      return "";
  }
}  
  
export function getHintForCode(code) {

  let i18nSessionVar = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED);
  if ((i18nSessionVar === null) || (i18nSessionVar === undefined)) {
    i18nSessionVar = "en";
  }

  if (i18nSessionVar === "de") {

    switch (code) {
      case "de": return "";
      case "en": return "";
      default:
        return code;
    }
  }

  switch (code) {
    case "de": return "";
    case "en": return "";
  
    default:
      return "";
  }
  
}

