import React, { Component } from "react";

import * as CT_ADD_ModuleNames from "./../../../commonxV3_001/codetable/CT_ADD_ModuleNames";

class WorkOnCaseUiDisplayRightResultSummary extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }


  calculateFileLink(moduleName, serviceUrl) {
    let result = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
    + "/RESTServiceFileDownLoad/" + moduleName + serviceUrl
    + this.props.row.processid;
    return result;
  }

  calculateFileLinkWiothOutProcessId(moduleName, serviceUrl) {
    let result = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
    + "/RESTServiceFileDownLoad/" + moduleName + serviceUrl
    return result;
  }

  render() {

    let row = this.props.row;    
    let displayLoglink = false;
    let sequenceindex = row.sequenceindex;

    let logLink = null;
    let logFileName = null;

    //Here those ProcessSteps, which delivers a result-excel enable their report download link

    if (sequenceindex === 95) {
      displayLoglink = true;
      logLink = this.calculateFileLink(CT_ADD_ModuleNames.CODE.JS_MODULE_ARCHIVES, "/preq/sbv3/service/archives/capture/reportfile/")
      logFileName = row.tenant + "_" + row.caseid + "_Capture.xls";
    }

    if (sequenceindex === 100) {
      displayLoglink = true;
      logLink = this.calculateFileLink(CT_ADD_ModuleNames.CODE.JS_MODULE_ARCHIVES, "/preq/sbv3/service/archives/extract/reportfile/")
      logFileName = row.tenant + "_" + row.caseid + "_Extract.xls";
    }


    if (sequenceindex === 200) {
      displayLoglink = true;
      logLink = this.calculateFileLink(CT_ADD_ModuleNames.CODE.JS_MODULE_CONTENT_TYPES, "/preq/sbv3/service/contenttypes/reportcreator/reportfile/")
      logFileName = row.tenant + "_" + row.caseid + "_ContentTypes.xls";
    }

    if (sequenceindex === 300) { 
      displayLoglink = true;
      logLink = this.calculateFileLink(CT_ADD_ModuleNames.CODE.JS_MODULE_CONVERTERS, "/preq/sbv3/service/converters/converter/reportfile/")
      logFileName = row.tenant + "_" + row.caseid + "_Converter.xls";
    }

    if (sequenceindex === 5000) { 
      displayLoglink = true;
      logLink = this.calculateFileLinkWiothOutProcessId(CT_ADD_ModuleNames.CODE.JS_MODULE_TENANT, "/preq/sbv3/service/tenantcase/matches/reportfile/" + row.tenant + "/" + row.caseid)
      logFileName = row.tenant + "_" + row.caseid + "_Matches.xls";
    }

    if (sequenceindex === 5100) { 
      displayLoglink = true;
      logLink = this.calculateFileLinkWiothOutProcessId(CT_ADD_ModuleNames.CODE.JS_MODULE_TENANT, "/preq/sbv3/service/tenantcase/matches/reportfile/" + row.tenant + "/" + row.caseid)
      logFileName = row.tenant + "_" + row.caseid + "_Matches.xls";
    }

    if (sequenceindex === 5600) { 
      displayLoglink = true;
      logLink = this.calculateFileLinkWiothOutProcessId(CT_ADD_ModuleNames.CODE.JS_MODULE_TENANT, "/preq/sbv3/service/tenantcase/pseudos/reportfile/" + row.tenant + "/" + row.caseid)
      logFileName = row.tenant + "_" + row.caseid + "_Pseudos.xls";
    }

    if (sequenceindex === 6200) { 
      displayLoglink = true;
      logLink = this.calculateFileLink(CT_ADD_ModuleNames.CODE.JS_MODULE_ANONYMIZERS, "/preq/sbv3/service/anonymizers/review/reportfile/")
      logFileName = row.tenant + "_" + row.caseid + "_ReviewResult.xls";
    }

    return (
      <div>
        { displayLoglink === true 
            ? <div>
              <h6>Summary</h6>
                  <a
                  href={logLink}
                  download ={logFileName}
                  rel="noopener"
                  aria-label='Result'
                  >
                  Result
                  </a>
                  &nbsp;(Excel)
              </div>
            : <div>&nbsp;</div>
        }
      </div>
    );
  }
}

export default WorkOnCaseUiDisplayRightResultSummary;
