import React, { Component } from "react";

import WorkOnCaseFileCommander from "./WorkOnCaseFileCommander";
import { IoCaretDown, IoCaretForward } from "react-icons/io5";
import * as PreQV3Constants from "./../../../../commonxV3_001/PreQV3Constants";
import * as CT_ProcessingPhase from "./../../../../commonxV3_001/codetable/CT_ProcessingPhase";


class WorkOnCaseFileCommanderRoot extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        fileUiIsOpen : false,
      };
  }

  render() {

    const {uiParams, completeUnTouchedPhases} = this.props;
    const {fileUiIsOpen} = this.state;
    
    let autoUpdate = this.getAutoUpdateValue();
    let indUntouchedPre = completeUnTouchedPhases.includes(CT_ProcessingPhase.CODE.JS_PRE)
    const display = ((indUntouchedPre === true) && (autoUpdate === false));
//    const display = true;

    if (display === false) {
      return (
        <div></div>
      );
    }

    if (fileUiIsOpen === true) {
      return (
        <div>
          <table width="100%">
                <tr>
                    <td style={{width:"2%", verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px solid #f0f0f0", backgroundColor:"#d4f0fb"}}>
                        <IoCaretDown size="22" onClick={this.toggleCaseDataInputIsOpen.bind(this)}/>
                    </td>
                    <td style={{width:"98%", verticalAlign:"top", paddingLeft:"10px", paddingTop:"7px", borderBottom:"2px solid #f0f0f0", fontWeight:"bold", backgroundColor:"#d4f0fb"}}>
                      Case Data Input
                      <br/>
                    </td> 
                </tr>
                <tr>
                    <td style={{width:"2%", verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px solid #f0f0f0"}}>
                          &nbsp;
                    </td>
                    <td style={{width:"98%", verticalAlign:"top", paddingLeft:"10px", paddingTop:"7px", borderBottom:"2px solid #f0f0f0", fontWeight:"bold"}}>
                    <WorkOnCaseFileCommander
                      uiParams = {uiParams}
                       />
                    </td>
                </tr>
          </table>          
        </div>
      );
    } else {
      return (
        <div>
          <table width="100%">
              <tr>
                  <td style={{width:"2%", verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px solid #f0f0f0", backgroundColor:"#d4f0fb"}}>
                        <IoCaretForward size="22" onClick={this.toggleCaseDataInputIsOpen.bind(this)}/>
                  </td>
                  <td style={{width:"98%", verticalAlign:"top", paddingLeft:"10px", paddingTop:"7px", borderBottom:"2px solid #f0f0f0", backgroundColor:"#d4f0fb"}}>
                      Case Data Input
                  </td>
              </tr>
          </table>
        </div>
      );
    }

  }

    /*
   * Open / Close Case Data Input (just toggle)
   */
    toggleCaseDataInputIsOpen() {
      //no tobble if aouto-update is active:
      let autoUpdate = this.getAutoUpdateValue();
      if (autoUpdate === true) {
        return;
      }
  
      let indCurrent = this.state.fileUiIsOpen;
      if (indCurrent === false) {
        this.setState({fileUiIsOpen: true});
      } else {
        this.setState({fileUiIsOpen: false});
      }
    }
  
 
    getAutoUpdateValue() {
      let value = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_CASE_AUTOUPDATE);
      if (value === "true") {
        return true;
      } else {
        return false;
      }
    }
   
}

export default WorkOnCaseFileCommanderRoot;
