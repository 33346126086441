export async function SERVER_URL() {
  let origin = window.location.origin;
  return origin;
}

/**
 * This method delivers the Service URL for a REST-Call
 */
export async function REST_CALL_URL() {
  let baseUrl = await SERVER_URL();
  let result = baseUrl + '/RESTServiceCall';
  return result;
}

/**
 * This method delivers the Service URL for a REST-TransactionKeyUpdate
 */
export async function REST_TRANSACTION_KEY_UPDATE_URL() {
  let baseUrl = await SERVER_URL();
  let result = baseUrl + '/RESTServiceTransactionKeyUpdate';
  return result;
}


/**
 * This method delivers the Service URL for a FileUploadCall
 */
export async function FILE_UPLOAD_URL() {
  let baseUrl = await SERVER_URL();
  let result = baseUrl + '/FileUploadCall';
  return result;
}

/**
 * This method deliver the Service Call for a REST-Call hiding a particular module
 * @param {*} module - e.g. 'UI', 'Archives', ...
 * @returns 
 */
export async function REST_CALL_UR_FOR_MODULE(module) {
  let baseUrl = await REST_CALL_URL();
  let result = baseUrl + '/' + module;
  return result;
}

/**
 * This method deliver the Service Call for a REST-Call hiding a particular endpoint of a module
 * @param {*} module  - e.g. 'Archives', ...
 * @param {*} endpoint - e.g. '/preq/sbv3/service/archives/capture/trigger'
 * @returns 
 */
export async function REST_CALL_UR_FOR_ENDPOINT(module, endpoint) {
  let baseUrl = await REST_CALL_URL();
  let result = baseUrl + '/' + module + endpoint;
  return result;
}
