import React, { Component } from "react";

import { Grid, Row, Column, Button, Loading, Accordion, AccordionItem, Modal, TextInput, TableContainer, TableToolbar, TableToolbarContent, TableBody, TableCell, ComposedModal, ModalFooter, ModalHeader} from "carbon-components-react";
import * as restClient from "./../../backend/RestClient";
import TenantCaseListUi from "../TenantCaseList/TenantCaseListUi";
import * as pojoIdentifier from "../../../commonxV3_001/ServerResponsePojoIdentifier";
import ServerSyncCallResultDisplayUi from "../../../commonxV3_001/ServerSyncCallResultDisplayUi";
import DevelopmentTag from "./../../../common//DevelopmentTag";
import { IoCaretDown, IoCaretForward } from "react-icons/io5";
import { IconContext } from "react-icons";
import * as EnvironmentService from "./../../../EnvironmentService";


class TenantCaseUi extends Component {
  constructor() {
    super();

    // Create the state
    this.state = {
      list: [],
      error: null,
      isLoading: true,
      showListUi : true,

      listOpen: [],

      modalShowUi : false,
      modalDialogError: null,
      modalForm: {
        tenant : null,
      },
    };
  }

  async componentDidMount() {
    this.showUpdatedList();
  }

  async showUpdatedList() {

    this.setState({ isLoading: true });

    //    let listFetchObj = null;
    let listFetchObj = await restClient.getTenantList();

    //sort list by name of tenant
    listFetchObj.sort((a, b) => (a.name > b.name) ? 1 : -1)

    //Update State
    this.setState({ isLoading: false, list: listFetchObj, error: null, showListUi : true, modalShowUi : false, modalDialogError: null,  modalForm: {...this.state.modalForm, tenant: null} });
  }

  handleAdd(event) {
    EnvironmentService.log('handleAdd')
    //Update State
    this.setState({ showListUi : false, modalShowUi : true });
  }

  /**
   * This method reacts on [Save] or [Cancel] the modal Dialog 
   * @param {*} event 
   */
  async handleModalClose(event) {
    if (event === null) {
      this.showUpdatedList();
    } else {
      //try to save and handle error
      let tenant = this.state.modalForm.tenant;
      let indSave = ( tenant!== null && tenant !== "" && tenant !== "undefined")

      if (indSave) {

        let data_FromServer = await restClient.createTenant(tenant);
        let isError = pojoIdentifier.isPojoErrorResult(data_FromServer);
        if (isError) {
          this.setState({ modalDialogError: data_FromServer });
        } else {
          this.showUpdatedList();
        }
 
      } else {
        this.setState({ modalDialogError: null });
      }
    }
  }

  /**
   * This method reacts on changes from Modal Dialog Fields
   * @param {*} event 
   */
  handleModalFormChange(event) {
    let fieldName = event.target.id;
    let fieldVal = event.target.value;
    EnvironmentService.log(fieldName + " : " + fieldVal);
    this.setState({modalForm: {...this.state.modalForm, [fieldName]: fieldVal}});
  }


  render() {

    EnvironmentService.log('TenantCaseUi.render()');
    EnvironmentService.log(this.state);

    const { showListUi, modalShowUi, list, isLoading, error, modalDialogError } = this.state;
    const { uiParams } =this.props;


    if (showListUi && isLoading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    let tenantLabel = "OrgUnit";
    if (uiParams.multiTenant === false) {
      tenantLabel = "OrgUnit";
    }

    let thisComponent = this;
    let thisProps = this.props;

    if (showListUi && list !== null) {
      let addButtonAppears = false;
      if (uiParams.multiTenant === true) {
        addButtonAppears = true;
      }
      if (this.state.list.length === 0) {
        addButtonAppears = true;
      }
      return (
        <div>
          <DevelopmentTag uiParams = {this.props.uiParams} devTagLabel = {this.constructor.name}/>
          <TableContainer>
            <TableToolbar>
              <TableToolbarContent>
                {
                  addButtonAppears 
                    ? <Button kind="primary" onClick={this.handleAdd.bind(this)}>add {tenantLabel}</Button>
                    : <p></p>
                }
                
              </TableToolbarContent>
            </TableToolbar>
          </TableContainer>
          <br/>


            {this.state.list.map((row) => (
              <div>
                {(this.indCategoryOpen(thisProps, this.state, row)) ? (
                    <table width = "100%" >
                      <tr  style={{backgroundColor:"#fafafa", height:"40px"}} >
                        <td style={{width:"2%", verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px solid #f0f0f0"}}>
                          <IoCaretDown size="22" onClick={this.toggleCategory.bind(this, thisComponent, row)}/>
                        </td>
                        <td style={{width:"98%", verticalAlign:"top", paddingLeft:"10px", paddingTop:"7px", borderBottom:"2px solid #f0f0f0", fontWeight:"bold"}}>
                        OrgUnit: {row.name}
                        <br/>
                        <TenantCaseListUi 
                                uiParams = {this.props.uiParams}
                                row={row}
                                uiTenantChange={this.props.uiTenantChange}
                                uiGetCurrentTenant={this.props.uiGetCurrentTenant}
                                uiCaseChange={this.props.uiCaseChange}
                                uiGetCurrentCase={this.props.uiGetCurrentCase}
                                uiShowUpdatedList={this.showUpdatedList.bind(this)}
                              />

                        </td>
                    </tr>
                    </table>
                  ) : (
                    <table width = "100%" >
                      <tr  style={{backgroundColor:"#fafafa", height:"40px"}} >
                        <td style={{width:"2%", verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px solid #f0f0f0"}}>
                          <IoCaretForward size="22" onClick={this.toggleCategory.bind(this, thisComponent,row)}/>
                        </td>
                        <td style={{width:"98%", verticalAlign:"top", paddingLeft:"10px", paddingTop:"7px", borderBottom:"2px solid #f0f0f0", fontWeight:"bold"}}>
                        OrgUnit: {row.name}
                        </td>
                      </tr>
                    </table>
                      )}
              </div>
            ))}        


        </div>
      );
    }

    if (modalShowUi) {
      return (
        <div>
          <Modal
          modalHeading="Create"
          open
          primaryButtonText="Save"
          secondaryButtonText="Cancel"
          onRequestSubmit={e => this.handleModalClose("")}
          onSecondarySubmit={e => this.handleModalClose(null)}
          >
          <TextInput id="tenant" labelText={tenantLabel} onChange={this.handleModalFormChange.bind(this)} />
          <ServerSyncCallResultDisplayUi 
            resultToDisplay = {modalDialogError}
          />
          </Modal> 
        </div>
      );
    }

    return (
      <div>
        <p>Error raised</p>
      </div>
    );


  }

  indCategoryOpen(props, state, row) {
    let currentRoom = props.uiGetCurrentTenant();
    let currentCase = props.uiGetCurrentCase();
    let listOpen = state.listOpen;
    let name = row.name;

    //is room open due to manual selection ?
    let nIndex = listOpen.indexOf(name);
    if (nIndex >= 0) { //available
      return true;
    }

     //is room open due to manual selection ?
    if (currentRoom === name) {
      return true;
    }
     //else
     return false;
  }

  toggleCategory(thisComponent, row) {
    let listOpen = thisComponent.state.listOpen;
    let name = row.name;
    let nIndex = listOpen.indexOf(name);
    if (nIndex < 0) { //not available
      listOpen.push(name);
    } else { //availabe
      listOpen.splice(nIndex, 1);
    }
    thisComponent.setState({ listOpen:listOpen}); 
  }

}

export default TenantCaseUi;
