import React, { Component } from "react";

import { ContentSwitcher, Switch} from "carbon-components-react";
import TenantCaseUi from "./TenantCase/TenantCaseUi";
import WorkOnCaseUi from "./WorkOnCase/WorkOnCaseUi";
import * as PreQV3Constants from "./../../commonxV3_001/PreQV3Constants";

class Ui extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        pageToDisplay: 0,
      };
  }

  render() {

    const RenderContent = () => {
      let page = this.state.pageToDisplay;
      if (page === 0) {
        return <div>
            <br/>
            <TenantCaseUi 
              uiTenantChange={this.props.uiTenantChange}
              uiGetCurrentTenant={this.props.uiGetCurrentTenant}
              uiCaseChange={this.props.uiCaseChange}
              uiGetCurrentCase={this.props.uiGetCurrentCase}
              uiParams = {this.props.uiParams}
        />
          </div>;
      }
      if (page === 1) {
        return <div>
            <br/>
            <WorkOnCaseUi
                uiGetCurrentTenant={this.props.uiGetCurrentTenant}
                uiGetCurrentCase={this.props.uiGetCurrentCase}
                uiParams = {this.props.uiParams}
            />
          </div>;
      }
      return <div></div>;
    }

    return (
      <div>
        <ContentSwitcher onChange={(obj) => {
          let { index, name, text } = obj;
          if (index === 1) {
            sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_CASE_READ_LIST_SEMAPHOR, "true");
            sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_CASE_AUTOUPDATE, "true");
          }
          this.setState({ pageToDisplay: index});
            }}>
          <Switch text="Maintain & Select" />
          <Switch text="Work on Case" />
        </ContentSwitcher>    
        {RenderContent()}
      </div>
    );

  }
}
export default Ui;
