import React, { Component } from "react";
import ReactJson from "react-json-view-with-toggle";
import * as CT_ADD_DisplayRightType from  "../../../commonxV3_001/codetable/CT_ADD_DisplayRightType";

import { ContentSwitcher, Switch} from "carbon-components-react";

class DevelopmentDisplayWorkOnCaseUi extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  render() {
    const {uiParams, devTagLabel, displayRightType, displayRightList, endPointDefaultParams, endPointUserParams, state} = this.props;



    let indShowEmpty = false;
    if (uiParams    === undefined) { indShowEmpty = true; }
    if (uiParams    === null)      { indShowEmpty = true; }
    if (devTagLabel === undefined) { indShowEmpty = true; }
    if (devTagLabel === null)      { indShowEmpty = true; }
    if (displayRightType === undefined) { indShowEmpty = true; }
    if (displayRightType === null)      { indShowEmpty = true; }
    if (displayRightList === undefined) { indShowEmpty = true; }
    if (displayRightList === null)      { indShowEmpty = true; }

    if (indShowEmpty) {
      return (
        <div></div>
      );
    }


    let indDisplayParams = false;
    if (displayRightType === CT_ADD_DisplayRightType.CODE.MANUALLY_PARAMETER_ONESTEP) {
      indDisplayParams = true;
    }
    if (displayRightType === CT_ADD_DisplayRightType.CODE.MANUALLY_PARAMETER_SEQUENCE) {
      indDisplayParams = true;
    }

    let visibleDevTagLable = devTagLabel + " INSIGHT";
    if (uiParams.showDevTags  === true) {

      if (indDisplayParams === true) { //params

        return (
          <div>
            <div className="preQ_SME_label">&nbsp;</div>
            <div className="preQ_SME_developer_tag">{visibleDevTagLable}</div>
  
            <table width="100%">
              <tr>
                <td with="100%">
                  <div className="preQ_SME_label">state</div>
                </td>
                <td with="25%">
                  <div className="preQ_SME_label">displayRight Type<br/>&nbsp;</div>
                </td>
                <td with="25%">
                  <div className="preQ_SME_label">xxx</div>
                </td>
                <td with="25%">
                  <div className="preQ_SME_label">xxx</div>
                </td>
              </tr>
              <tr>
              <td>
                  <div className="preQ_SME_label">
                    <ReactJson src={state} collapsed={true} enableClipboard={false}/><br/>
                  </div>
                </td>
                <td>
                  <div className="preQ_SME_label">
                    displayRightType: '{displayRightType}'<br/>&nbsp;<br/>
                  </div>
                </td>
                <td>
                  <div className="preQ_SME_label">
                  <br/><br/>
                  &nbsp;
                  </div>
                </td>
                <td>
                  <div className="preQ_SME_label">
                  <br/><br/>
                  &nbsp;
                  </div>
                </td>
              </tr>
            </table>
            <br />
        </div>
        );
      } else { //manual result or automatic result
        return (
          <div className="preQ_SME_label">
            <div className="preQ_SME_label">&nbsp;</div>
            <div className="preQ_SME_developer_tag">{visibleDevTagLable}</div>
            <div className="preQ_SME_label">

              <table width="100%">
                  <tr>
                    <td with="75%">
                      <div className="preQ_SME_label">displayRight Type & List<br/>&nbsp;</div>
                    </td>
                    <td with="25%">
                      <div className="preQ_SME_label">state</div>
                    </td>
                  </tr>
                  <tr>
                    <td with="75%">
                      <div className="preQ_SME_label">

                      displayRightType: '{displayRightType}'<br/>&nbsp;<br/><br/>
                      SequenceIndex<br/>   
                      {displayRightList[0]}<br/><br/>
                      Preqsys Process Item<br/>
                      <ReactJson src={displayRightList[1]} collapsed={true} enableClipboard={false}/><br/>
                      Chain Entry<br/>   
                      <ReactJson src={displayRightList[2]} collapsed={true} enableClipboard={false}/><br/>

                      </div>
                    </td>
                    <td  with="25%">
                      <div className="preQ_SME_label">
                        <ReactJson src={state} collapsed={true} enableClipboard={false}/><br/>
                      </div>
                    </td>
                  </tr>
              </table>

            </div>
          </div>
        );
      }

    } else {
      return (
        <div></div>
      );
    }

  }
}

export default DevelopmentDisplayWorkOnCaseUi;
