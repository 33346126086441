import React, { Component } from "react";

import { TableContainer, TableBody, TableRow, TableCell, TableToolbar, TableToolbarContent, Grid, Row, Column, Button, Accordion, AccordionItem, ContentSwitcher, Switch } from "carbon-components-react";
import I18nSelect from "./../common/I18nSelect";
import * as CT_UiModus from "./../../modules/commonxV3_001/codetable/CT_UiModus";


class TopStatusLineUi extends Component {
  constructor(props) {
    super(props);

    // Create the state
    this.state = {
    };
  }

  render() {

    const { uiGetCurrentTenant, uiGetCurrentCase, uiParams } = this.props;

    let indSMEModus = false;
    let indWAMAModus = false;
    if (uiParams.uiModus === CT_UiModus.CODE.JS_UI_MODUS_SME) {
      indSMEModus = true;
    }
    if (uiParams.uiModus === CT_UiModus.CODE.JS_UI_MODUS_WAMA) {
      indWAMAModus = true;
    }


    let currentTenant = uiGetCurrentTenant();
    if (currentTenant === "") {
      currentTenant = "?"
    }
    let currentCase = uiGetCurrentCase();
    if (currentCase === "") {
      currentCase = "?";
    }

    let tenantLabel = "OrgUnit:";
    if (uiParams.multiTenant === false) {
      tenantLabel = "OrgUnit:";
    }

    let statusLineCss = "statusLineNeutral"
    return (
      <div >
        <table width="100%">
          <tr>
            <td width="60%">
              <div className="preQ_SME_label">
                <br />&nbsp;&nbsp;&nbsp;Selected&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{tenantLabel}&nbsp;&nbsp;&nbsp;{currentTenant}&nbsp;&nbsp;&nbsp;Case:&nbsp;&nbsp;&nbsp;{currentCase}
                {indWAMAModus
                  ?
                  <>
                    <br/><br/>
                    <div className="preQ_WAMA_SME_statusLineSmeInWamaWarning">&nbsp;&nbsp;&nbsp;!!! Wama Modus !!!&nbsp;&nbsp;&nbsp;OrgUnit & Cases may disappear automatically without notification to this front end. </div>
                  </>
                  : <></>}

              </div>
            </td>
            <td width="40%">
              <I18nSelect />
            </td>
          </tr>
        </table>

      </div>
    );
  }
}

export default TopStatusLineUi;
