import React, { Component } from "react";

import { IoEnterOutline, IoPricetagOutline,  IoPartlySunnyOutline, IoCheckmarkDone, IoBandage, IoPulseOutline } from "react-icons/io5";
import { IconContext } from "react-icons";
import * as CT_ProcessStatus from  ".//../../../commonxV3_001/codetable/CT_ProcessStatus";
import * as CT_ADD_DisplayRightType from  "../../../commonxV3_001/codetable/CT_ADD_DisplayRightType";
import * as workOnCaseUtils from "./../../backend/WorkOnCaseUtils";
import * as EnvironmentService from "./../../../EnvironmentService";
import InvisibleIcon from "./InvisibleIcon";


class WorkOnCaseUiProcessStatusIcon extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {}

  render() {

    const { row, list, displayRightType, displayRightList} = this.props;
    let indUiStartable = row.indUiStartable;
    let indUiSelectedAsSequenced = row.indUiSelectedAsSequenced;

    let prevRow = workOnCaseUtils.getPreviousChainEntry(row, list);
    let indUiPrevSelectedAsSequenced = false;
    let prevRowBusinessIdentifier = null;
    if (prevRow !== null) {
      indUiPrevSelectedAsSequenced = prevRow.indUiSelectedAsSequenced;
      prevRowBusinessIdentifier = prevRow.businessIdentifier;
    }
    let indIsListedRightAsParameter = workOnCaseUtils.isEntryInDisplayRightParameterList(row, displayRightType, displayRightList);
//    workOnCaseUtils.isEntryInDisplayRightParameterList(row, )
    EnvironmentService.log('', 'Current Row: ' + row.businessIdentifier + ", Previous Identifier: " + prevRowBusinessIdentifier + " , prevSelectedAsSequenced: " + indUiPrevSelectedAsSequenced + " , indIsListedRightAsParameter: " + indIsListedRightAsParameter);

    let selectSwitch = this.props.row.status;
    // selectSwitch = CT_ProcessStatus.CODE.JS_PROCESS_CORRUPTED; //only for testing

    switch(selectSwitch) {
      case CT_ProcessStatus.CODE.JS_IN_PROCESS:
        return (
          <div>
            <IconContext.Provider value={{className:"preQ_SME_icon_process_status_JS_IN_PROCESS"}}>
              <IoPulseOutline size="18"/>
            </IconContext.Provider>
          </div>
        );

      case CT_ProcessStatus.CODE.JS_PROCESS_CORRUPTED:
        return (
          <div>
            <IconContext.Provider value={{className:"preQ_SME_icon_process_status_JS_PROCESS_CORRUPTED"}}>
              <IoBandage size="18"/>
            </IconContext.Provider>
          </div>
        );

      case CT_ProcessStatus.CODE.JS_PROCESS_DONE: 
        return (
          <div>
            <IconContext.Provider value={{className:"preQ_SME_icon_process_status_JS_PROCESS_DONE"}}>
              <IoCheckmarkDone size="18"/>
            </IconContext.Provider>
          </div>
        );

      case CT_ProcessStatus.CODE.JS_RAISING:
        return (
          <div>
            <IconContext.Provider value={{className:"preQ_SME_icon_process_status_JS_RAISING"}}>
              <IoPartlySunnyOutline size="18"/>
            </IconContext.Provider>
          </div>
        );

      case CT_ProcessStatus.CODE.JS_TO_BE_PROCESSED:
        return (
          <div>
            <IconContext.Provider value={{className:"preQ_SME_icon_process_status_JS_TO_BE_PROCESSED"}}>
              <IoPricetagOutline size="18"/>
            </IconContext.Provider>
          </div>
        );

      case CT_ProcessStatus.CODE.UNDEFINED:
        if (
                  ((indUiStartable === true) && (indUiSelectedAsSequenced === false)) 
              ||  ((indUiPrevSelectedAsSequenced === true) && (indIsListedRightAsParameter === false))
           ) 
        {
          return (
            <div onClick={this.handleTriggerProcess.bind(this, this.props.row)}>
              <IconContext.Provider value={{className:"preQ_SME_icon_process_status_UNDEFINED"}}>
                <IoEnterOutline size="18"/>
              </IconContext.Provider>
            </div>
          );
  
        } else {
          return (
            <div>
              <InvisibleIcon />
            </div>
          );
  
        }


      default:  
        return (
          <div>
            <IconContext.Provider value={{className:"preQ_SME_icon_process_status_DEFAULT"}}>
              <IoEnterOutline size="18"/>
            </IconContext.Provider>
          </div>
        );

    }

   

  }


  //*******************************/
  //* Icon Touch Handler
  //*******************************/
  handleTriggerProcess(row) {
    const { displayRightType, displayRightList, autoUpdate} = this.props;
    
    if (autoUpdate === true) {
      return;
    }
    if (displayRightType === CT_ADD_DisplayRightType.CODE.UNDEFINED) {
      row.selectedUiParameters = row.chainEntry.endPointProcessTriggerParameter;
      let ary = [];
      ary.push(row);
      this.props.handleDisplayRightSettings(CT_ADD_DisplayRightType.CODE.MANUALLY_PARAMETER_ONESTEP, ary, true);  
    }

    if (displayRightType === CT_ADD_DisplayRightType.CODE.MANUALLY_PARAMETER_SEQUENCE) {
      row.selectedUiParameters = row.chainEntry.endPointProcessTriggerParameter;
      let ary = displayRightList;
      ary.push(row);
      this.props.handleDisplayRightSettings(CT_ADD_DisplayRightType.CODE.MANUALLY_PARAMETER_SEQUENCE, ary, true);  
    }

  }

}

export default WorkOnCaseUiProcessStatusIcon;
