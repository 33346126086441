import * as PreQV3Constants from "./../PreQV3Constants";

export const CODE = {
  UNDEFINED: "",
  JS_UI_MODUS_SME: "UI_MODUS_SME",
  JS_UI_MODUS_WAMA: "UI_MODUS_WAMA",
}


export function getTextForCode(code) {

  let i18nSessionVar = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED);
  if ((i18nSessionVar === null) || (i18nSessionVar === undefined)) {
    i18nSessionVar = "en";
  }

  if (i18nSessionVar === "de") {

    switch (code) {
      case "UI_MODUS_SME": return "SME Benutzeroberfl�che";
      case "UI_MODUS_WAMA": return "WAMA Benutzeroberfl�che";
      default:
        return code;
    }
  }

  switch (code) {
    case "UI_MODUS_SME": return "SME User Interface";
    case "UI_MODUS_WAMA": return "WAMA User Interface";
  
    default:
      return "";
  }
}  
  
export function getHintForCode(code) {

  let i18nSessionVar = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_I18N_SELECTED);
  if ((i18nSessionVar === null) || (i18nSessionVar === undefined)) {
    i18nSessionVar = "en";
  }

  if (i18nSessionVar === "de") {

    switch (code) {
      case "UI_MODUS_SME": return "";
      case "UI_MODUS_WAMA": return "";
      default:
        return code;
    }
  }

  switch (code) {
    case "UI_MODUS_SME": return "";
    case "UI_MODUS_WAMA": return "";
  
    default:
      return "";
  }
  
}

