import React, { Component } from "react";

import { ContentSwitcher, Switch} from "carbon-components-react";

class Status200Details extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  render() {

    const { contentTypeInfo } = this.props;

    let indContentAvailable = true;
    if (contentTypeInfo === null)       { indContentAvailable = false;}
    if (contentTypeInfo === undefined)  { indContentAvailable = false;}
    if (indContentAvailable === false) {
      return (
        <div>
          &nbsp;
        </div>
      );
    }

    let list = contentTypeInfo.list;
    let sql1 = contentTypeInfo.queryToListContentTypes;
    let sql2 = contentTypeInfo.queryToGetAmountPerContentType;
    return (
        <div>
          <table width="100%">
            <tr>
              <td colspan="1" className="backGroundColor_process_PROCESS_ITEM"><h6>ContentTypes</h6></td>
              <td colspan="1" className="backGroundColor_process_PROCESS_ITEM"><h6>Files</h6></td>
            </tr>

            {list.map((entry) => (
            <tr>
              <td width="40%">{entry.contentType}</td>
              <td width="60%">{entry.amountOfFiles}</td>
            </tr>
          ))}


          </table>
          <br/>&nbsp;         

          <table width="100%">
            <tr>
              <td colspan="1" className="backGroundColor_process_PROCESS_ITEM"><h6>SQL-Queries</h6></td>
              <td colspan="1" className="backGroundColor_process_PROCESS_ITEM">&nbsp;</td>
            </tr>

            <tr>
              <td width="20%">list of content-types<br/>&nbsp;</td>
              <td width="80%">{sql1}</td>
            </tr>          
            
            <tr>
              <td>amount for a content-type<br/>example</td>
              <td>{sql2}</td>
            </tr>          

          </table>
          <br/>&nbsp;         
        </div>

//          <h6>SQL-Queries:</h6>
//          SQL1 = {sql1}<br/>
//          SQL2 = {sql2}<br/>
//          &nbsp;
    );
  }
}

export default Status200Details;
