import React, { Component } from "react";
import { Tabs, Tab, Theme, Loading } from '@carbon/react';

import * as PreQV3Constants from "./../../modules/commonxV3_001/PreQV3Constants"
import * as CT_UiModus from "./../../modules/commonxV3_001/codetable/CT_UiModus";
import * as environmentService from "./../../modules/EnvironmentService";
import PreQExceptionHandler from "./PreQExceptionHandler";

import LoginForm from './../../modules/login/LoginForm';

// /?info /?admin
import VersionsAndSettingsDisplayContent from "./../../modules/commonxV3_001/app/VersionsAndSettingsDisplayContent";
import AdminUi from "../../modules/adminV3_002/app/AdminUi";

//SME:
import TenantUi from "../../modules/tenantV3_001/app/Ui"; 
import TopStatusLineUi from "./../../modules/common/TopStatusLineUi";

//WAMA
import WamaUi from "./../../modules/wamaUiV3_001/app/WamaUi"; 







class LandingPage extends Component {

  constructor(props) {
    super(props);

    let loggedInUser = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_LOGGED_IN_USER_NAME);
    let defaultShowContentUi = false;
    let defaultShowLogInUi = true;
    if (loggedInUser !== null) {
      defaultShowContentUi = true;
      defaultShowLogInUi = false;
    }

    environmentService.log('', 'CONSTRUCTOR');
    this.state = {
      tenant: "", //initially the tenant is not selected
      case: "", //initially the case is not selected
      uiParams: null,

      showContentUi: defaultShowContentUi,
      showLogInUi: defaultShowLogInUi,
      modalForm: {
        user: null,
        password: null,
        loginFailed: false,
      },


    };
  }

  /**
 * This method reacts on changes from Modal Dialog Fields
 * @param {*} event 
 */
  handleLogInFormChange(event) {
    let fieldName = event.target.id;
    let fieldVal = event.target.value;
    environmentService.log('', fieldName + " : " + fieldVal);
    this.setState({ modalForm: { ...this.state.modalForm, [fieldName]: fieldVal } });
  }


  async handleLogInEnter(event) {
    let success = await environmentService.login(this.state.modalForm.user, this.state.modalForm.password);
    if (success !== null) {
      const obj = JSON.parse(success);
      sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_LOGGED_IN_USER_NAME, this.state.modalForm.user);
      sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_PREQ_KEY, obj.preQKey);
      PreQV3Constants.setTransactionKey(obj.transactionKey); //set transaction-key and transaction-key-timestamp
      this.setState({ modalForm: { ...this.state.modalForm, user: null, password: null } });
      this.setState({ showContentUi: true, modalShowUi: false });
      this.props.updateUi();
    } else {
      this.setState({ modalForm: { ...this.state.modalForm, loginFailed: true } });
    }
  }

  async componentDidMount() {
    this.updateUiParams();
  }

  async updateUiParams() {
    let uiParams = await environmentService.getUiParams();
    let uiParamsAsString = JSON.stringify(uiParams);
    sessionStorage.setItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_UI_PARAMETERS, uiParamsAsString);
    this.setState({ uiParams: uiParams });
  }

  handleTenantSelected(selectedTenant) {
    this.setState({ tenant: selectedTenant });
  }
  handleCasedSelected(selectedCased) {
    this.setState({ case: selectedCased });
  }

  getSelectedTenant() {
    let result = this.state.tenant;
    return result;
  }

  getSelectedCase() {
    let result = this.state.case;
    return result;
  }


  render() {


    let windowLocationPath = window.location.pathname;
    environmentService.log('', windowLocationPath);


    const urlParams = window.location.search;


    if (urlParams === '?info') {
      return (
        <div className="App">
          <Theme theme="g90">
            <div>
              <br />
              <br />
              <table width="100%">
                <tr>
                  <td width="10%">
                  </td>
                  <td width="80%">
                    <VersionsAndSettingsDisplayContent />
                  </td>
                  <td width="10%">
                  </td>
                </tr>
              </table>
            </div>
          </Theme>
        </div>
      );
    }

    let showAdminUi = (urlParams === '?admin')

    const { uiParams, showContentUi, showLogInUi: modalShowUi, modalForm: modalForm } = this.state;
    if (uiParams === null) {
      return (
        <div className="App">
          <Loading />
        </div>
      );
    }

    let tenantLabel = "OrgUnits & Cases";
    if (uiParams.multiTenant === false) {
      tenantLabel = "Cases";
    }

    let indSMEModus = false;
    let indWAMAModus = false;
    if (uiParams.uiModus === CT_UiModus.CODE.JS_UI_MODUS_SME) {
      indSMEModus = true;
    }
    if (uiParams.uiModus === CT_UiModus.CODE.JS_UI_MODUS_WAMA) {
      indWAMAModus = true;
    }

    //this is a dirty work-around to have an INSTABLE SME-view on WAMA-Cases==>
    //to have this work finally: 
    // a) all SME-SubComponents MUST access uiParams ONLY via session
    environmentService.log('', urlParams);
    let enforcedSMEUi = false
    if (urlParams === "?smeui=true") {
      //and b) this modification has to be placed into uiParams (wtihin session)
      enforcedSMEUi = true;
    }
    //<== this is a dirty work-around


    if ((showAdminUi) && showContentUi) {
      return (
        <div className="App">
          <Theme theme="g90">
            <div>
                <br />
                <br />
                <table width="100%">
                  <tr>
                    <td width="10%">
                    </td>
                    <td width="80%">
                    <AdminUi
                      uiParams={this.state.uiParams}
                    />
                    </td>
                    <td width="10%">
                    </td>
                  </tr>
                </table>
              </div>
          </Theme>
        </div>
      );
    }


    if  ((showAdminUi === false) && showContentUi && (indSMEModus || enforcedSMEUi)) {
      return (
        <Theme theme="white">
          <div>
            <PreQExceptionHandler
              style="sme"
            />
            <TopStatusLineUi
              uiGetCurrentTenant={this.getSelectedTenant.bind(this)}
              uiGetCurrentCase={this.getSelectedCase.bind(this)}
              uiParams={this.state.uiParams}
            />
            <br />
            <TenantUi
              uiTenantChange={this.handleTenantSelected.bind(this)}
              uiGetCurrentTenant={this.getSelectedTenant.bind(this)}
              uiCaseChange={this.handleCasedSelected.bind(this)}
              uiGetCurrentCase={this.getSelectedCase.bind(this)}
              uiParams={this.state.uiParams}
            />

          </div>
        </Theme>
      );
    }

    if ((showAdminUi === false) && showContentUi && indWAMAModus) {
      return (
        <>
          <Theme theme="g90">
            <PreQExceptionHandler 
              style="wama"
            />
            <WamaUi />
          </Theme>
        </>
      );
    }

    if (modalShowUi) {
      return (
        <>
          <Theme theme="g100">
          <PreQExceptionHandler />
          <LoginForm
              handleLogInFormChange={this.handleLogInFormChange.bind(this)}
              handleLogInEnter={this.handleLogInEnter.bind(this)}
              indSMEModus={indSMEModus}
              modalForm={modalForm}
            />
          </Theme>
        </>

      );
    }

  }
}


export default LandingPage;
