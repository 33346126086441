import React, { Component } from "react";

import { ContentSwitcher, Switch} from "carbon-components-react";
import { IoCaretDown, IoCaretForward } from "react-icons/io5";
import * as PreQV3Constants from "./../../../../commonxV3_001/PreQV3Constants";
import WorkOnCaseDeployToFinalFolderSubmission from "./WorkOnCaseDeployToFinalFolderSubmission";


class WorkOnCaseDeployToFinalFolder extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        fileUiIsOpen : false,
      };
  }

  render() {

    let autoUpdate = this.getAutoUpdateValue();
    const{uiParams, completelyDone} = this.props;
    const {fileUiIsOpen} = this.state;
    
    let display = ((completelyDone === true) && (autoUpdate === false))

    if (display === false) {
      return (
        <div></div>
      );
    }


    if (fileUiIsOpen === true) {
      return (
        <div>
          <table width="100%">
                <tr>
                    <td style={{width:"2%", verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px solid #f0f0f0", backgroundColor:"#d4f0fb"}}>
                        <IoCaretDown size="22" onClick={this.toggleCaseDataInputIsOpen.bind(this)}/>
                    </td>
                    <td style={{width:"98%", verticalAlign:"top", paddingLeft:"10px", paddingTop:"7px", borderBottom:"2px solid #f0f0f0", fontWeight:"bold", backgroundColor:"#d4f0fb"}}>
                      Case Data Output
                      <br/>
                    </td> 
                </tr>
                <tr>
                    <td style={{width:"2%", verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px solid #f0f0f0"}}>
                          &nbsp;
                    </td>
                    <td style={{width:"98%", verticalAlign:"top", paddingLeft:"10px", paddingTop:"7px", borderBottom:"2px solid #f0f0f0", fontWeight:"bold"}}>
                        <WorkOnCaseDeployToFinalFolderSubmission 
                          uiParams = {this.props.uiParams}
                        />
                    </td>
                </tr>
          </table>          
        </div>
      );
    } else {
      return (
        <div>
          <table width="100%">
              <tr>
                  <td style={{width:"2%", verticalAlign:"top", paddingLeft:"5px", paddingTop:"3px", borderBottom:"2px solid #f0f0f0", backgroundColor:"#d4f0fb"}}>
                        <IoCaretForward size="22" onClick={this.toggleCaseDataInputIsOpen.bind(this)}/>
                  </td>
                  <td style={{width:"98%", verticalAlign:"top", paddingLeft:"10px", paddingTop:"7px", borderBottom:"2px solid #f0f0f0", backgroundColor:"#d4f0fb"}}>
                  Case Data Output
                  </td>
              </tr>
          </table>
        </div>
      );
    }    
  }

      /*
   * Open / Close Case Data Input (just toggle)
   */
      toggleCaseDataInputIsOpen() {
        //no tobble if aouto-update is active:
        let autoUpdate = this.getAutoUpdateValue();
        if (autoUpdate === true) {
          return;
        }
    
        let indCurrent = this.state.fileUiIsOpen;
        if (indCurrent === false) {
          this.setState({fileUiIsOpen: true});
        } else {
          this.setState({fileUiIsOpen: false});
        }
      }
    
   
      getAutoUpdateValue() {
        let value = sessionStorage.getItem(PreQV3Constants.CONSTANTS.SESSION_STORAGE_KEY_CASE_AUTOUPDATE);
        if (value === "true") {
          return true;
        } else {
          return false;
        }
      }
  
}

export default WorkOnCaseDeployToFinalFolder;
