import React, { Component } from "react";

import {Modal} from "carbon-components-react";
import * as CT_ADD_AsyncPhase from "./../codetable/CT_ADD_AsyncPhase"; 
import * as CT_ProcessStatus from "./../codetable/CT_ProcessStatus"; 
import * as CT_ErrorCodes from "./../codetable/CT_ErrorCodes"; 
import * as asyncProcessHandler from "../../adminV3_001/backend/RestClient";
import * as pojoIdentifier from "../../commonxV3_001/ServerResponsePojoIdentifier";
import * as environmentService from "./../../EnvironmentService";


class AsyncProcessDialogs extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        asyncProcessDialogModalPhase: null,
        errorPojoFromServer: null,
        asyncProcessID : null,
      };
  }

  async componentDidMount() {
    const intervalId = setInterval(() => {
      this.updateDialog();
    }, 2000);  
  }

  async updateDialog() {
    const { callBackHandleSuccess, callBackHandleFailure } = this.props;
    if (this.state.asyncProcessDialogModalPhase !== null) {
      if (this.state.asyncProcessDialogModalPhase === CT_ADD_AsyncPhase.CODE.JS_PHASE2_RUN) {

        environmentService.log('', "updateDialog() processing: " + this.state.asyncProcessID);
        const { asyncProcessDialogsModal } = this.props;
        let callTenant = asyncProcessDialogsModal.tenant;
        let callCaseid = asyncProcessDialogsModal.caseid;
        let callAsyncAction = asyncProcessDialogsModal.code; //code: see Codetable CT_ADD_AsyncAction
        let callProcessID = this.state.asyncProcessID;

        if (callProcessID !== null) {
          let data_FromServer = await asyncProcessHandler.getStatusOfParticularPocess(callTenant, callCaseid, callAsyncAction, callProcessID);
          let currentStatus = data_FromServer.status.status;

          //sucess ??
          if (CT_ProcessStatus.CODE.JS_PROCESS_DONE === currentStatus) {

            this.setState(
              {
                asyncProcessDialogModalPhase: CT_ADD_AsyncPhase.CODE.JS_PHASE3_RESULT,
                errorPojoFromServer: null,
                asyncProcessID : null,
              }
            )
            if (callBackHandleSuccess !== undefined) {
              callBackHandleSuccess(data_FromServer);
            }

          }

          //corrupted ??
          if (CT_ProcessStatus.CODE.JS_PROCESS_CORRUPTED === currentStatus) {

            this.setState(
              {
                asyncProcessDialogModalPhase: CT_ADD_AsyncPhase.CODE.JS_PHASE3_RESULT,
                errorPojoFromServer: data_FromServer,
                asyncProcessID : null,
              }
            )
            if (callBackHandleSuccess !== undefined) {
              callBackHandleFailure(data_FromServer);
            }

            
          }

        }
      }
    }
  }


  render() {

    const { asyncProcessDialogsModal } = this.props;
    const { errorPojoFromServer } = this.state;

    let phase = this.state.asyncProcessDialogModalPhase;
    if (phase === null) {
      phase = CT_ADD_AsyncPhase.CODE.JS_PHASE1_CONFIRM;
    }

    let modalHeading = asyncProcessDialogsModal.name;

    if (phase === CT_ADD_AsyncPhase.CODE.JS_PHASE1_CONFIRM) {
      return (
        <div>
          <Modal
          modalHeading={modalHeading} 
          open
          primaryButtonText="Yes"
          secondaryButtonText="No"
          onRequestSubmit={e => this.processAsyncPhase1Result(true)}
          onSecondarySubmit={e => this.processAsyncPhase1Result(false)}
          >
          <p>Continue ?</p>  
          </Modal> 
        </div>
      );
    }

    if (phase === CT_ADD_AsyncPhase.CODE.JS_PHASE2_RUN) {
      return (
        <div>
          <Modal
          modalHeading={"Running " + modalHeading}
          open
          passiveModal="true"
          >
            <p className="preQ_SME_backGroundColor_process_RUNNING">
              Running process: {this.state.asyncProcessID}
            </p>  
          </Modal> 
        </div>
      );
    }

    if ((phase === CT_ADD_AsyncPhase.CODE.JS_PHASE3_RESULT) && (this.state.errorPojoFromServer !== null)) {

      let visibleErrorCode = "";
      let visibleErrorText = "";
      try {
        let preqXception = errorPojoFromServer.preQExceptions[0];
        visibleErrorCode = preqXception.errorCode;  
        visibleErrorText = preqXception.errorText;
      } catch (error) {
        visibleErrorCode = CT_ErrorCodes.CODE.JS_NO_ERROR_CODE;
        visibleErrorText = CT_ErrorCodes.getTextForCode(CT_ErrorCodes.CODE.JS_NO_ERROR_CODE);
      }
      return (
        <div>
          <Modal
          modalHeading={"ERROR during " + modalHeading}
          open
          primaryButtonDisabled = "true"
          primaryButtonText=""
          secondaryButtonText="OK"
          onSecondarySubmit={e => this.closeAsyncModal()}
          >
          <p className="preQ_SME_backGroundColor_process_CORRUPTED">
            <table>
              <tr>
                <td>{visibleErrorText}</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>(ErrorCode:&nbsp;&nbsp;&nbsp;{visibleErrorCode})</td>
              </tr>
            </table>
          </p>  
          </Modal> 
        </div>
      );
    }    


    if ((phase === CT_ADD_AsyncPhase.CODE.JS_PHASE3_RESULT) && (this.state.errorPojoFromServer === null)) {
      return (
        <div>
          <Modal
          modalHeading={modalHeading}
          open
          primaryButtonDisabled = "true"
          primaryButtonText=""
          secondaryButtonText="OK"
          onSecondarySubmit={e => this.closeAsyncModal()}
          >
          <p className="preQ_SME_backGroundColor_process_DONE">
            ... done
          </p>  
          </Modal> 
        </div>
      );
    }    

  }



  async processAsyncPhase1Result(indContinue) { // true or false
    environmentService.log('', indContinue);
    if (indContinue === true) {
      this.setState(
        {
          asyncProcessDialogModalPhase: CT_ADD_AsyncPhase.CODE.JS_PHASE2_RUN,
        }
      );

      const { asyncProcessDialogsModal } = this.props;
      let callTenant = asyncProcessDialogsModal.tenant;
      let callCaseid = asyncProcessDialogsModal.caseid;
      let callAsyncAction = asyncProcessDialogsModal.code; //code: see Codetable CT_ADD_AsyncAction
      let callParameter = asyncProcessDialogsModal.parameter;

      let data_FromServer = await asyncProcessHandler.triggerParticularProcess(callTenant, callCaseid, callAsyncAction, callParameter)
      //try to trigger and get result
      // if resust is error, then go directly to Phase 3: Show Display with result
      // if result is ok (triggered with process id), then go to phase 2 run with processid to wait for   

      let isError = pojoIdentifier.isPojoErrorResult(data_FromServer);
      if (isError) {
        //call failed ... show result in Phase 3
        this.setState(
          {
            asyncProcessDialogModalPhase: CT_ADD_AsyncPhase.CODE.JS_PHASE3_RESULT,
            errorPojoFromServer: data_FromServer,
          }
        );
    
      } else {

        //code to wait 1 second(s) 
        await new Promise(resolve => setTimeout(resolve, 1000));

        let processId = data_FromServer.processID;

        this.setState(
          {
            asyncProcessDialogModalPhase: CT_ADD_AsyncPhase.CODE.JS_PHASE2_RUN,
            asyncProcessID: processId,
          }
        );

        //wait until done

        
        environmentService.log('', data_FromServer);
        environmentService.log('', '')
      }


    } else {
      this.closeAsyncModal();
    }
  }



  async closeAsyncModal() {
    this.setState(
      {
        asyncProcessDialogModalPhase: null,
        errorPojoFromServer: null,
        asyncProcessID : null,
      }
    );
    this.props.functionCloseAsyncModal();
  }  

}



export default AsyncProcessDialogs;
