import React, { Component } from "react";
import DevelopmentTag from "./../../../common//DevelopmentTag";

class WorkOnCaseUiDisplayRightResultStatisticsUi extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  render() {
    let row = this.props.row;
    return (
      <div>
        <DevelopmentTag uiParams = {this.props.uiParams} devTagLabel = {this.constructor.name}/>
        <table width="100%">
          <tr>
            <td colspan="2" className="backGroundColor_process_PROCESS_ITEM">Statistics</td>
          </tr>
          <tr>
            <td width="30%">added at</td>
            <td width="70%">{row.added_txt}</td>
          </tr>
          <tr>
            <td>started at</td>
            <td>{row.started_txt}</td>
          </tr>
          <tr>
            <td>ended at</td>
            <td>{row.ended_txt}</td>
          </tr>
          <tr>
            <td>Runtime</td>
            <td>{(row.runtime_int === 0) ? "" : row.runtime_int + " ms"} </td>
          </tr>
        </table>         
      </div>
    );
  }
}

export default WorkOnCaseUiDisplayRightResultStatisticsUi;
