import React, { Component } from "react";

import { ContentSwitcher, Switch} from "carbon-components-react";

class Parameter5100FileInfo extends Component {
  constructor(props) {
    super(props);

       // Create the state
       this.state = {
        // pageToDisplay: 0,
      };
  }

  render() {

    const { fileInfoTree } = this.props;
    let showList = true;
    if (fileInfoTree === null)       {showList = false}
    if (fileInfoTree === undefined)   {showList = false}

    if (showList === false) {
      return (
        <div>
          <p>
          Please upload StoredIQ-Result before Start
          </p>
        </div>
      );
    }

    let candidates = fileInfoTree.candidates;
    let invalid = fileInfoTree.invalid;
    let ignored = fileInfoTree.ignored;

    return (
      <div>
          <h6>Valid:</h6>
          <table width="100%">
            {candidates.map((file) => (
              <tr>
                <td width="100%">&nbsp;&nbsp;-&nbsp;&nbsp;{file}</td>
              </tr>
            ))}
          </table>
          <h6>Invalid (by structure):</h6>
          <table width="100%">
            {invalid.map((file) => (
              <tr>
                <td width="100%">&nbsp;&nbsp;-&nbsp;&nbsp;{file}</td>
              </tr>
            ))}
          </table>
          <h6>Ignored (by fileName):</h6>
          <table width="100%">
            {ignored.map((file) => (
              <tr>
                <td width="100%">&nbsp;&nbsp;-&nbsp;&nbsp;{file}</td>
              </tr>
            ))}
          </table>
      </div>
    );

  }
}

export default Parameter5100FileInfo;
