import * as environmentService from "./../../EnvironmentService";
import * as CT_ADD_ModuleNames from "./../../commonxV3_001/codetable/CT_ADD_ModuleNames";
import * as PreQV3Constants from "./../../commonxV3_001/PreQV3Constants";
import * as Facade from "./../../Facade";

const HttpCodes = {
  OK : 200,
  BAD_REQUEST : 400,
  INTERNAL_SERVER_ERROR : 500,

}


export async function checkInFileUploadTo_toIbm(uploadedid) {
  let module = CT_ADD_ModuleNames.CODE.JS_MODULE_TENANT;
  let endPoint = '/preq~sbv3~service~tenantinput~checkin~upload~' + uploadedid; 
  let checkInUrl = await Facade.REST_CALL_UR_FOR_ENDPOINT(module, endPoint);

  let response_FromServer = await fetch(checkInUrl, {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain',
      'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
      'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
    },
      body : null,
   })

  let httpStatus = HttpCodes.OK;
  if (!response_FromServer.ok) {
    httpStatus = response_FromServer.status;
  }

  if (httpStatus === HttpCodes.OK) {
      let data_FromServer = await response_FromServer.json();
      environmentService.log("data_FromServer", data_FromServer);
      return data_FromServer;
  }  

  if (httpStatus === HttpCodes.BAD_REQUEST) {
      let data_FromServer = await response_FromServer.json();
      environmentService.log("data_FromServer", data_FromServer);
      return data_FromServer;
  }

  if (httpStatus === HttpCodes.INTERNAL_SERVER_ERROR) {
      let data_FromServer = await response_FromServer.json();
      environmentService.log("data_FromServer", data_FromServer);
      return data_FromServer;
  }

  let errorJson = PreQV3Constants.CONSTANTS.ERROR_UNKNOWN;
  let error = JSON.parse(errorJson); 
  return error;

}

/**
 * This method reads list of tenant from SpringBoot v3 REST Service
 * @returns 
 */
export async function getTenantList() {
  let tenantBaseUrl = await environmentService.getTenantBaseUrl();
  let sUrl = tenantBaseUrl + "/preq/sbv3/service/tenant";
  let headerVarUser = PreQV3Constants.CONSTANTS.HEADER_USER;
  let response_FromServer = await fetch(sUrl, {
    method: 'GET',
    headers: {
      'Accept': 'application/json, text/plain',
      'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
      'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
    },
   })

  if (!response_FromServer.ok) {
    return null;
  }

  let data_FromServer = await response_FromServer.json();
  environmentService.log("data_FromServer", data_FromServer);

  let tenantList = data_FromServer.tenantList;
  environmentService.log("result", tenantList);
  
  return tenantList;
}

/**
 * This method creates a tenant
 * @param {*} tenant 
 * @returns response from server as is
 */
export async function createTenant(tenant) {

  const bodyData = null;
  let tenantBaseUrl = await environmentService.getTenantBaseUrl();
  let sUrl = tenantBaseUrl + "/preq~sbv3~service~tenant~" + tenant; //~ will be replaced by '/' in UIProxy
  let response_FromServer = await fetch(sUrl, {
    credentials: 'same-origin',  //same-origin, omit, include
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain',
      'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
      'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
    },
    body : bodyData
  });

  let httpStatus = HttpCodes.OK;
  if (!response_FromServer.ok) {
    httpStatus = response_FromServer.status;
  }

  if (httpStatus === HttpCodes.OK) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }

  if (httpStatus === HttpCodes.BAD_REQUEST) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }

  if (httpStatus === HttpCodes.INTERNAL_SERVER_ERROR) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }

  let errorJson = PreQV3Constants.CONSTANTS.ERROR_UNKNOWN;
  let error = JSON.parse(errorJson); 
  return error;
}

/**
 * This method reads list of cases as f(tenant) from SpringBoot v3 REST Service
 * @returns 
 */
 export async function getTenantCaseList(tenant) {
  let tenantBaseUrl = await environmentService.getTenantBaseUrl();
  let sUrl = tenantBaseUrl + "/preq/sbv3/service/tenantcase/" + tenant;
  let response_FromServer = await fetch(sUrl,
    {
      method: 'GET',
      headers: {
        'Accept': 'application/json, text/plain',
        'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
        'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
      },
     }
  );

  if (!response_FromServer.ok) {
    return null;
  }

  let data_FromServer = await response_FromServer.json();
  environmentService.log("data_FromServer", data_FromServer);

  let tenantCaseList = data_FromServer.caseList;
  environmentService.log("result", tenantCaseList);
  
  return tenantCaseList;
}

/**
 * This method creates a case
 * @param {*} tenant 
 * @param {*} caseid 
 */
export async function createTenantCase(tenant, caseid) {

  const bodyData = null;
  let tenantBaseUrl = await environmentService.getTenantBaseUrl();
  let sUrl = tenantBaseUrl + "/preq~sbv3~service~tenantcase~" + tenant + "~" + caseid;
  let response_FromServer = await fetch(sUrl, {
    credentials: 'same-origin',  //same-origin, omit, include
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain',
      'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
      'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
    },
    body : bodyData
  });


  let httpStatus = HttpCodes.OK;
  if (!response_FromServer.ok) {
    httpStatus = response_FromServer.status;
  }

  if (httpStatus === HttpCodes.OK) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }
  if (httpStatus === HttpCodes.BAD_REQUEST) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }
  if (httpStatus === HttpCodes.INTERNAL_SERVER_ERROR) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }

  let errorJson = PreQV3Constants.CONSTANTS.ERROR_UNKNOWN;
  let error = JSON.parse(errorJson); 
  return error;
}


///preq/sbv3/service/tenant/sequence/case/status/{tenant}/{caseid}/{params}
export async function getProcessChainStatus(tenant, caseid) {

  let tenantBaseUrl = await environmentService.getTenantBaseUrl();
  let sUrl = tenantBaseUrl + "/preq/sbv3/service/tenant/sequence/case/status/" + tenant + "/" + caseid;
  let response_FromServer = await fetch(sUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json, text/plain',
        'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
        'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
      },
    }
    );

  let httpStatus = HttpCodes.OK;
  if (!response_FromServer.ok) {
    httpStatus = response_FromServer.status;
  }

  if (httpStatus === HttpCodes.OK) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    data_FromServer.chain.pop(); //remove 'End' from result
    data_FromServer.chain.shift(); //remove 'Start' from result
//    let runningProcess = data_FromServer.runningProcess;
//    data_FromServer.chain.push(runningProcess);
    return data_FromServer;
  }
  if (httpStatus === HttpCodes.BAD_REQUEST) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }
  if (httpStatus === HttpCodes.INTERNAL_SERVER_ERROR) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }

  let errorJson = PreQV3Constants.CONSTANTS.ERROR_UNKNOWN;
  let error = JSON.parse(errorJson); 
  return error;
}


/**
 * This method performs the final removal of tenant artifacts in database
 * removing data from database-tables preqsys_process,preqsys_process_findings
 * @param {*} tenant 
 */
 export async function deleteTenantFinally(tenant) {

  const bodyData = null;
  let tenantBaseUrl = await environmentService.getTenantBaseUrl();
  let sUrl = tenantBaseUrl + "/preq~sbv3~service~tenant~tenantdelete~final~" + tenant;
  let response_FromServer = await fetch(sUrl, {
    credentials: 'same-origin',  //same-origin, omit, include
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain',
      'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
      'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
    },
    body : bodyData
  });


  let httpStatus = HttpCodes.OK;
  if (!response_FromServer.ok) {
    httpStatus = response_FromServer.status;
  }

  if (httpStatus === HttpCodes.OK) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }
  if (httpStatus === HttpCodes.BAD_REQUEST) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }
  if (httpStatus === HttpCodes.INTERNAL_SERVER_ERROR) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }

  let errorJson = PreQV3Constants.CONSTANTS.ERROR_UNKNOWN;
  let error = JSON.parse(errorJson); 
  return error;
}


export async function getFileListTenantToIbm(tenant, caseid) {

  let tenantBaseUrl = await environmentService.getTenantBaseUrl();
  let sUrl = tenantBaseUrl + "/preq/sbv3/service/tenantinput/" + tenant;
  let response_FromServer = await fetch(sUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json, text/plain',
          'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
          'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
        },
      }
    );

  let httpStatus = HttpCodes.OK;
  if (!response_FromServer.ok) {
    httpStatus = response_FromServer.status;
  }

  if (httpStatus === HttpCodes.OK) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }
  if (httpStatus === HttpCodes.BAD_REQUEST) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }
  if (httpStatus === HttpCodes.INTERNAL_SERVER_ERROR) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }

  let errorJson = PreQV3Constants.CONSTANTS.ERROR_UNKNOWN;
  let error = JSON.parse(errorJson); 
  return error;
}


export async function getFileListTenantCaseToPreQ(tenant, caseid) {

  let tenantBaseUrl = await environmentService.getTenantBaseUrl();
  let sUrl = tenantBaseUrl + "/preq/sbv3/service/tenantinput/" + tenant + "/" + caseid;
  let response_FromServer = await fetch(sUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json, text/plain',
        'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
        'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
      },
    }
    );

  let httpStatus = HttpCodes.OK;
  if (!response_FromServer.ok) {
    httpStatus = response_FromServer.status;
  }

  if (httpStatus === HttpCodes.OK) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }
  if (httpStatus === HttpCodes.BAD_REQUEST) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }
  if (httpStatus === HttpCodes.INTERNAL_SERVER_ERROR) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }

  let errorJson = PreQV3Constants.CONSTANTS.ERROR_UNKNOWN;
  let error = JSON.parse(errorJson); 
  return error;
}


export async function getFileListTenantCaseFromPreQ(tenant, caseid) {

  let tenantBaseUrl = await environmentService.getTenantBaseUrl();
  let sUrl = tenantBaseUrl + "/preq/sbv3/service/caseoutput/" + tenant + "/" + caseid;
  let response_FromServer = await fetch(sUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json, text/plain',
          'PreQ-Header-User' : PreQV3Constants.getLoggedInUser(),
          'PreQ-Header-TransactionKey' : PreQV3Constants.getTransactionKey()
        },
      }
    );

  let httpStatus = HttpCodes.OK;
  if (!response_FromServer.ok) {
    httpStatus = response_FromServer.status;
  }

  if (httpStatus === HttpCodes.OK) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }
  if (httpStatus === HttpCodes.BAD_REQUEST) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }
  if (httpStatus === HttpCodes.INTERNAL_SERVER_ERROR) {
    let data_FromServer = await response_FromServer.json();
    environmentService.log("data_FromServer", data_FromServer);
    return data_FromServer;
  }

  let errorJson = PreQV3Constants.CONSTANTS.ERROR_UNKNOWN;
  let error = JSON.parse(errorJson); 
  return error;
}

