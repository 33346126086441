import React, { Component } from "react";

import { Loading } from "carbon-components-react";
import * as adminUtils from  "../../adminV3_001/backend/AdminUtils";
import * as restStatusRetrievalClient from "../../commonxV3_001/backend/RestStatusRetrievalClient";
import CommonXProcessFindingsListUi from "../../commonxV3_001/app/CommonXProcessFindingsListUi";
import * as CT_ProcessStatus from "./../../commonxV3_001/codetable/CT_ProcessStatus";
import DevelopmentTag from "./../../common/DevelopmentTag";
import * as PreQV3Constants from "./../../commonxV3_001/PreQV3Constants";



class Status6100 extends Component {
  constructor(props) {
    super(props);

       // Create the state
      this.state = {
        urlStatusRetrieval: null,
        fetchedObject : null,
        autoUpdateTimeStamp : "",

      };
  }

  async componentDidMount() {
    const intervalId = setInterval(() => {
        this.updateStatusObject();
    }, 1000);  

  }

  async updateStatusObject() {

    let indAutoUpdate = PreQV3Constants.getAutoUpdateValue();
    let indLoadFromProcess = (this.props.row.status === CT_ProcessStatus.CODE.JS_IN_PROCESS) && indAutoUpdate;
    if ((this.state.fetchedObject === null) || (indLoadFromProcess === true)) {
      this.setState({ isLoading: true });

      let row = this.props.row;
      let moduleUrls = this.props.moduleUrls;
      let chainEntry = this.props.chainEntry;
      let moduleName = chainEntry.moduleName;
  
      let moduleBaseUrl =adminUtils.getModuleBaseUrlByIdentifier(moduleUrls, moduleName);
      let urlStatusRetrieval = moduleBaseUrl + chainEntry.endPointProcessStatus + '/' + row.processid; 
  
      let fetchedObject = await restStatusRetrievalClient.getProcessStatus(urlStatusRetrieval); //REACT INTERNAL CALL
      
      let today = new Date();
      var now = today.toLocaleString();
  
      this.setState({ urlStatusRetrieval: urlStatusRetrieval, fetchedObject:fetchedObject, autoUpdateTimeStamp:now });
        
    } 
  }

  render() {

    const { fetchedObject } = this.state;

      if (fetchedObject === null) {
        return (
          <div>
            <Loading />
          </div>
        );
      }

    let processFindings = this.state.fetchedObject.process_specifics.processFindings;
    return (
      <div>
        <DevelopmentTag uiParams = {this.props.uiParams} devTagLabel = {this.constructor.name}/>
        < CommonXProcessFindingsListUi
          processFindings = {processFindings}
          autoUpdateTimeStamp = {this.state.autoUpdateTimeStamp}
         />
      </div>
    );
  }
}

export default Status6100;
